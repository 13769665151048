import React from 'react';

const Marker = () => {
  return (
    <div className="map_marker">
      <div className="oval" data-for="map" data-tip />
    </div>
  );
};

export default Marker;
