import {
  GET_DEPARTMENTS_SUCCESS,
  GET_MUNICIPALITIES_SUCCESS,
  GET_ZONES_SUCCESS,
  SET_INITIAL_REGION_VALUE_SUCCESS,
  CHECK_MY_ADDRESS_IN_RESTAURANT,
} from 'core/modules/region/actions';
import { initialState } from 'core/modules/initialState';

export default function region(state = initialState.region, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_REGION_VALUE_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case GET_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        departments: payload.departments,
      };
    }
    case GET_MUNICIPALITIES_SUCCESS: {
      return {
        ...state,
        municipalities: payload.municipalities,
        zones: payload.zones,
      };
    }
    case GET_ZONES_SUCCESS: {
      return {
        ...state,
        zones: payload.zones,
      };
    }
    case CHECK_MY_ADDRESS_IN_RESTAURANT: {
      return {
        ...state,
        isRestaurant: payload.isRestaurant,
      };
    }
    default: {
      return state;
    }
  }
}
