import { connect } from 'react-redux';
import { restaurantActionCreators } from 'core/modules/restaurant/actions';

function mapStateToProps({ restaurant }) {
  return {
    restaurant,
  };
}

const mapDispatchToProps = restaurantActionCreators;

export function connectRestaurant(configMapStateToProps = mapStateToProps) {
  return connect(configMapStateToProps, mapDispatchToProps);
}
