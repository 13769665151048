import { createAction } from 'redux-actions';
import { createPromiseAction } from 'core/modules/utils';

/**
 * Action Types
 */
export const GET_RESTAURANT_LIST_REQUEST = '@restaurant/GET_RESTAURANT_LIST_REQUEST';
export const GET_RESTAURANT_LIST_SUCCESS = '@restaurant/GET_RESTAURANT_LIST_SUCCESS';
export const GET_RESTAURANT_LIST_FAILURE = '@restaurant/GET_RESTAURANT_LIST_FAILURE';
export const GET_RESTAURANT_REQUEST = '@restaurant/GET_RESTAURANT_REQUEST';
export const GET_RESTAURANT_TIMESLOTS_REQUEST = '@restaurant/GET_RESTAURANT_TIMESLOTS_REQUEST';
export const GET_DELIVERY_AREAS_REQUEST = '@restaurant/GET_DELIVERY_AREAS_REQUEST';

/**
 * Action Creators
 */
export const restaurantActionCreators = {
  getRestaurantList: createPromiseAction(GET_RESTAURANT_LIST_REQUEST),
  getRestaurantListSuccess: createAction(GET_RESTAURANT_LIST_SUCCESS),
  getRestaurantListFailure: createAction(GET_RESTAURANT_LIST_FAILURE),
  getRestaurant: createPromiseAction(GET_RESTAURANT_REQUEST),
  getRestaurantTimeSlots: createPromiseAction(GET_RESTAURANT_TIMESLOTS_REQUEST),
  getDeliveryAreas: createPromiseAction(GET_DELIVERY_AREAS_REQUEST),
};
