import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { getCurrency } from 'utilities/common';
import OrderDetailModal from 'components/Main/Order/OrderDetailModal';

const esLocale = require('moment/locale/es');

class LastOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.timeInterval = null;

    this.state = {
      isOpenModal: false,
    };
  }

  render() {
    const { lastOrderInfo } = this.props;
    return (
      <div className="last_order_layout">
        <p className="order_date">
          Último pedido realizado el{' '}
          {moment(lastOrderInfo.createdAt)
            .locale('es', esLocale)
            .tz('America/Guatemala')
            .format('DD MMMM YYYY')}
          , con entrega a las{' '}
          {moment(lastOrderInfo.dueTo)
            .tz('America/Guatemala')
            .format('DD MMMM HH:mm')}{' '}
          horas
        </p>
        <div className="order_detail_wrapper" onClick={() => this.setState({ isOpenModal: true })}>
          <div className="user_info">
            <p className="code">{lastOrderInfo.order || lastOrderInfo.id}</p>
            <p className="name">
              {lastOrderInfo.customer ? `${lastOrderInfo.customer.firstName} ${lastOrderInfo.customer.lastName}` : ''}
            </p>
            <p className="phone">{lastOrderInfo.customer ? lastOrderInfo.customer.phone : '-'}</p>
          </div>
          <div className="order_info">
            <p className="address1">
              {`${lastOrderInfo.deliveryAddress.address || ''}, ${lastOrderInfo.deliveryAddress.description || ''}`}
            </p>
            {lastOrderInfo.restaurant &&
              lastOrderInfo.restaurant.zones &&
              lastOrderInfo.restaurant.zones.map((z, index) => (
                <p className="address2" key={index}>
                  {z.description || ''}
                </p>
              ))}
            <p className="product">{lastOrderInfo.restaurant ? lastOrderInfo.restaurant.description : ''}</p>
          </div>
        </div>
        <div className="order_summary">
          <div className="bought">
            <p className="article">
              {lastOrderInfo.contents ? lastOrderInfo.contents.length : 0} <FormattedMessage id="articles" />
            </p>
            <div className="device">{lastOrderInfo.channel || ''}</div>
          </div>
          <div className="total">
            <p className="amount">
              {getCurrency().symbol}
              {parseFloat(lastOrderInfo.paymentAmount / 100).toFixed(2) || ''}
            </p>
            <p className="note">{lastOrderInfo.paymentMethod.type === 'C' ? 'CC' : lastOrderInfo.paymentMethod.type === 'VL' ?  'VL' : 'COD'}</p>
          </div>
        </div>
        <OrderDetailModal
          isOpenModal={this.state.isOpenModal}
          onCloseModal={() => this.setState({ isOpenModal: false })}
          orderDetail={lastOrderInfo || {}}
        />
      </div>
    );
  }
}

LastOrder.propTypes = {
  lastOrderInfo: PropTypes.object,
};

LastOrder.defaultProps = {
  lastOrderInfo: {},
};

export default LastOrder;
