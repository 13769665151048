import { connect } from 'react-redux';
import { settingsActionCreators } from 'core/modules/settings/actions';

function mapStateToProps({ settings }) {
  return {
    settings,
  };
}

const mapDispatchToProps = settingsActionCreators;

export function connectSettings(configMapStateToProps = mapStateToProps) {
  return connect(configMapStateToProps, mapDispatchToProps);
}
