import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import enMessages from 'lang/en';
import esMessages from 'lang/es';
import { store } from 'core';
import RoutesContainer from 'containers/RoutesContainer/RoutesContainer';
import PageNotFound from 'components/PageNotFound/PageNotFound';
import Login from 'containers/Auth/Login';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'assets/styles/App.scss';

addLocaleData([...en, ...es]);
const initialLang = process.env.REACT_APP_LANG || 'en';

const messages = {
  en: enMessages,
  es: esMessages,
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: initialLang,
    };
  }

  render() {
    const { lang } = this.state;
    const message = messages[lang];
    return (
      <IntlProvider locale={lang} messages={message}>
        <Provider store={store}>
          <Router>
            <div className="App">
              <ToastContainer
                autoClose={0}
                hideProgressBar
                newestOnTop
                position={toast.POSITION.TOP_CENTER}
                transition={Slide}
              />
              <div className="content">
                <Switch>
                  <Route component={Login} path="/login" />
                  <PrivateRoute component={RoutesContainer} path="/" />
                  <Route component={PageNotFound} exact path="/404" />
                </Switch>
              </div>
            </div>
          </Router>
        </Provider>
      </IntlProvider>
    );
  }
}

export default hot(App);
