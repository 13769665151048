import { LOGOUT_SUCCESS } from 'core/modules/auth/actions';
import { initialState } from 'core/modules/initialState';

export default function resetReducer(state, action) {
  switch (action.type) {
    case LOGOUT_SUCCESS: {
      return {
        auth: initialState.auth,
        catalog: {
          tudor: false,
          catalogId: null,
          superCataloges: null,
          cataloges: null,
          products: null,
          prices: null,
          sizes: null,
          productGroups: null,
          optionGroups: null,
          options: null,
          recipes: null,
          cart: state.catalog.cart,
        },
        region: initialState.region,
        address: initialState.address,
        order: initialState.order,
        customer: initialState.customer,
        restaurant: initialState.restaurant,
      };
    }
    default:
      return state;
  }
}
