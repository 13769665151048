import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

function ModalDlg({ children, className, title, visible, top, handleCancel, isCenter, ...props }) {
  const classes = [className, 'overlay'];
  return (
    <div className="basic-modal">
      <Modal
        {...props}
        centered={isCenter}
        closable={false}
        footer={null}
        mask
        maskClosable
        onCancel={handleCancel}
        style={{ top: !isCenter ? top : undefined }}
        title={null}
        visible={visible}
        wrapClassName={classes}
      >
        {children}
      </Modal>
    </div>
  );
}

ModalDlg.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  visible: PropTypes.bool,
  top: PropTypes.number,
  isCenter: PropTypes.bool,
  handleCancel: PropTypes.func,
};

ModalDlg.defaultProps = {
  children: null,
  className: '',
  title: '',
  visible: false,
  top: 140,
  isCenter: false,
  handleCancel: () => {},
};

export default ModalDlg;
