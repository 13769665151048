/* eslint-disable no-unused-vars */
import { all, call, fork, put, take } from 'redux-saga/effects';
import { authActionCreators } from 'core/modules/auth/actions';
import {
  GET_DELIVERY_AREAS_REQUEST,
  GET_RESTAURANT_LIST_REQUEST,
  GET_RESTAURANT_REQUEST,
  GET_RESTAURANT_TIMESLOTS_REQUEST,
  restaurantActionCreators,
} from 'core/modules/restaurant/actions';
import { restService } from 'utilities';

export function* asyncGetRestaurantListRequest({ payload, resolve, reject }) {
  const { offset, limit, or, filter, sort } = payload;
  try {
    const response = yield call(restService, {
      api: `/restaurants?zones=true${offset ? `&offset=${offset}` : ''}${limit ? `&limit=${limit}` : ''}${
        or ? `&or=${or}` : ''
      }${filter || ''}${sort ? `&sort=${sort} desc` : '&sort=createdAt desc'}`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      yield put(
        restaurantActionCreators.getRestaurantListSuccess({
          list: response.data,
        }),
      );
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetRestaurantRequest({ payload, resolve, reject }) {
  const { id } = payload;
  try {
    const response = yield call(restService, {
      api: `/restaurants/${id}`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      resolve(response.data);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetRestaurantTimeSlotsRequest({ payload, resolve, reject }) {
  const { restaurant, lat, long } = payload;
  try {
    const response = yield call(restService, {
      api: `/restaurants/timeSlots?long=${long}&lat=${lat}${restaurant ? `&restaurant=${restaurant}` : ''}`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      resolve(response.data);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncGetDeliveryAreasRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/restaurants/deliveryAreas`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      resolve(response.data.rows);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}
export function* watchRestaurantListRequest() {
  while (true) {
    const action = yield take(GET_RESTAURANT_LIST_REQUEST);
    yield* asyncGetRestaurantListRequest(action);
  }
}
export function* watchRestaurantRequest() {
  while (true) {
    const action = yield take(GET_RESTAURANT_REQUEST);
    yield* asyncGetRestaurantRequest(action);
  }
}
export function* watchRestaurantTimeSlotsRequest() {
  while (true) {
    const action = yield take(GET_RESTAURANT_TIMESLOTS_REQUEST);
    yield* asyncGetRestaurantTimeSlotsRequest(action);
  }
}
export function* watchDeliveryAreasRequest() {
  while (true) {
    const action = yield take(GET_DELIVERY_AREAS_REQUEST);
    yield* asyncGetDeliveryAreasRequest(action);
  }
}
export default function*() {
  yield all([
    fork(watchRestaurantListRequest),
    fork(watchRestaurantRequest),
    fork(watchRestaurantTimeSlotsRequest),
    fork(watchDeliveryAreasRequest),
  ]);
}
