import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { Button, Input, Select, Switch } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connectOrder, orderActionCreators } from 'core';
import { ArrowForward } from '@material-ui/icons';
import ModalDlg from 'components/Basic/ModalDlg';
import cardImg from 'assets/img/card.svg';
import { getCurrency } from 'utilities/common';
import { promisify } from 'utilities';
import toast from 'components/Basic/Toast';

const { Option } = Select;

function SingleClaimModal({ intl, history, orderDetail, isOpenModal, onCloseModal, onUpdatedOrder, updateOrder }) {
  const orderStates = {
    RV: 'Received',
    IP: 'In Production',
    AS: 'Assigned',
    OW: 'On the Way',
    AD: 'At the Door',
    CL: 'Claimed',
    RS: 'Resolving',
    DD: 'Delivered',
    CD: 'Canceled',
    ERR: 'Error',
  };
  const reasons = [
    { label: intl.formatMessage({ id: 'Wrong' }), value: 'W' },
    { label: intl.formatMessage({ id: 'Incomplete' }), value: 'I' },
    { label: intl.formatMessage({ id: 'Cancelled' }), value: 'C' },
    { label: intl.formatMessage({ id: 'Bad presentation' }), value: 'B' },
    { label: intl.formatMessage({ id: 'Poor quality' }), value: 'Q' },
    { label: intl.formatMessage({ id: 'Service' }), value: 'S' },
    { label: intl.formatMessage({ id: 'Delay' }), value: 'D' },
    { label: intl.formatMessage({ id: 'Product' }), value: 'P' },
    { label: intl.formatMessage({ id: 'Cleaning' }), value: 'D' },
    { label: intl.formatMessage({ id: 'Others' }), value: 'O' },
  ];
  const [isReshipment, setIsReshipment] = useState(false);
  const [claimReason, setClaimReason] = useState('W');
  const [claimText, setClaimText] = useState('');

  const OrderDetail = () => {
    return (
      <div className="order_details">
        <div className="group_name">
          <FormattedMessage id="Order details" /> <div className="divider" />
        </div>
        <div className="order_number_elapsed">
          <div>
            <p className="title">
              <FormattedMessage id="Order number" />
            </p>
            <p className="description">#{orderDetail.order || ''}</p>
          </div>
          <div>
            <p className="title">
              <FormattedMessage id="Time elapsed" />
            </p>
            <p className="description">En espera</p>
          </div>
        </div>
        <div className="delivery_address">
          <p className="title">
            <FormattedMessage id="Delivery address" />
          </p>
          <p className="description">{orderDetail.deliveryAddress.name || '-'}</p>
          <p className="sub_description">{orderDetail.deliveryAddress.address || '-'}</p>
        </div>
        <div className="delivery_time">
          <p className="title">
            <FormattedMessage id="Delivery day and time" />
          </p>
          <p className="description">
            {orderDetail.dueTo
              ? `${moment(orderDetail.dueTo)
                  .tz('America/Guatemala')
                  .format('LLLL')} horas`
              : ''}
          </p>
        </div>
        <div className="order_number_elapsed">
          <div>
            <p className="title">
              <FormattedMessage id="Who to ask for?" />
            </p>
            <p className="description">{`${orderDetail.customer.firstName} ${orderDetail.customer.lastName}`}</p>
          </div>
          {orderDetail.agent && (
            <div>
              <p className="title">Agente</p>
              <p className="description">{`${orderDetail.agent.firstName} ${orderDetail.agent.lastName}`}</p>
            </div>
          )}
        </div>
      </div>
    );
  };
  const OrderItems = () => {
    return (
      <div className="order_items">
        <div className="group_name">
          <FormattedMessage id="Order elements" /> <div className="divider" />
        </div>
        <div className="item_list">
          {orderDetail.contents &&
            orderDetail.contents.map((order, index) => (
              <div className="item" key={index}>
                <p className="name">{order.item.name}</p>
                <p className="amount">
                  {getCurrency().symbol}
                  {parseFloat(order.price / 100).toFixed(2)} x {order.quantity} = {getCurrency().symbol}
                  {parseFloat((order.price * order.quantity) / 100).toFixed(2)}
                </p>
              </div>
            ))}
        </div>
        <div className="order_total">
          <p className="title">
            <FormattedMessage id="Order total" />:
          </p>
          <p className="amount">
            {getCurrency().symbol}
            {parseFloat(orderDetail.paymentAmount / 100).toFixed(2) || ''}
          </p>
        </div>
      </div>
    );
  };

  const handleReason = v => {
    setClaimReason(v);
  };
  const handleChangeSwitch = checked => {
    setIsReshipment(checked);
  };

  const handleSendClaim = () => {
    promisify(updateOrder, {
      order: orderDetail.order,
      customerAddress: orderDetail.deliveryAddress.address,
      newClaimCategory: claimReason,
      newClaimText: claimText,
    })
      .then(() => {
        onUpdatedOrder();
        history.push('/ots/claim/confirm');
      })
      .catch(error => {
        console.log(error);
        toast.error({
          title: intl.formatMessage({ id: 'The claim order is failure' }),
        });
      });
  };
  return (
    <ModalDlg
      className="order_detail_modal"
      handleCancel={() => onCloseModal()}
      top={30}
      visible={isOpenModal}
      width={826}
    >
      {orderDetail && (
        <div className="order_detail_wrapper">
          <div className="detail_content_wrapper">
            <div className="detail_content">
              <div className="detail_header">
                <p>
                  <FormattedMessage id="Order" /> #{orderDetail.order || ''}
                </p>
                <Button className={`${orderDetail.state}`}>
                  <FormattedMessage id={orderStates[orderDetail.state]} />
                </Button>
              </div>
              <OrderDetail />
              <OrderItems />
            </div>
            <div className="detail_footer">
              <p className="pay_way_title">
                <FormattedMessage id="Way to pay" />
              </p>
              <div className="card_info">
                <img alt="card" src={cardImg} />
                <div>
                  <p>
                    <FormattedMessage id="Paid by credit or debit card" />
                  </p>
                  <p>
                    <FormattedMessage id="ending in" /> 4544
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="claim_reason_wrapper">
            <div className="reason_dropdown">
              <p className="p-medium">
                <FormattedMessage id="Reason for the claim" />
              </p>
              <Select className="reason_list" defaultValue="W" onChange={handleReason}>
                {reasons.map(r => (
                  <Option key={r.value} value={r.value}>
                    {r.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="reason_description_wrapper">
              <p className="p-medium">
                <FormattedMessage id="Claim detail" />
              </p>
              <Input.TextArea
                className="reason_description"
                onChange={e => setClaimText(e.target.value)}
                placeholder={intl.formatMessage({ id: 'Enter the description and detail of the claim' })}
                rows={14}
                value={claimText}
              />
            </div>
            <div className="reshipment_order_wrapper">
              <p className="p-medium">
                <FormattedMessage id="Do you require order re-shipment?" />
              </p>
              <Switch checked={isReshipment} onChange={handleChangeSwitch} />
            </div>
            <div className="send_claim_btn_wrapper">
              <Button onClick={handleSendClaim}>
                <FormattedMessage id="Submit claim" />
                <ArrowForward />
              </Button>
            </div>
          </div>
        </div>
      )}
    </ModalDlg>
  );
}

SingleClaimModal.propTypes = {
  history: PropTypes.object,
  isOpenModal: PropTypes.bool,
  orderDetail: PropTypes.object,
  intl: intlShape.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onUpdatedOrder: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
};

SingleClaimModal.defaultProps = {
  history: {},
  isOpenModal: false,
  orderDetail: null,
};

const mapDispatchToProps = dispatch => {
  const { updateOrder } = orderActionCreators;

  return bindActionCreators(
    {
      updateOrder,
    },
    dispatch,
  );
};

export default compose(injectIntl, withRouter, connectOrder(undefined, mapDispatchToProps))(SingleClaimModal);
