import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { Icon, Spin } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-phone-input-2/dist/style.css';
import { ArrowBack } from '@material-ui/icons';
import {
  addressActionCreators,
  catalogActionCreators,
  connectAddress,
  connectCatalog,
  connectOrder,
  orderActionCreators,
} from 'core';
import OrdersTable from 'components/Main/Claim/OrdersTable';
import { promisify } from 'utilities';

const antIcon = <Icon spin style={{ fontSize: 24 }} type="loading" />;

class ExistedOrders extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orderList: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const { customerAddressId } = this.props;
    this.setState({ isLoading: true });
    promisify(this.props.getMyOrder, {
      offset: 0,
      limit: 5,
      filter: `&filter=customerAddress eq ${customerAddressId}&filter=or.state eq RV`, // &filter=or.state eq IP
    })
      .then(res => {
        this.setState({ isLoading: false, orderList: res });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleSelectOrder = order => {
    promisify(this.props.setInitialOrder, { dueTo: order.dueTo });
    promisify(this.props.setInitialAddress, {
      selectedAddress: order.deliveryAddress,
    });

    this.props.history.push({ pathname: '/ots/order' });
  };

  handleBack = () => {
    this.props.onBack();
  };

  render() {
    const { orderList } = this.state;
    return (
      <Spin indicator={antIcon} spinning={this.state.isLoading}>
        <div className="existed_orders_layout scrollbar">
          <div className="main_content scrollbar">
            <div className="right_panel">
              <div className="existing_order_header">
                <div className="back_btn" onClick={this.handleBack}>
                  <ArrowBack />
                </div>
                <p className="p-medium">
                  <FormattedMessage id="Orders processing the same customer with the option to unify another order" />
                </p>
              </div>
              <OrdersTable onSelectOrder={this.handleSelectOrder} orderData={orderList} />
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

ExistedOrders.propTypes = {
  history: PropTypes.object,
  customerAddressId: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  getMyOrder: PropTypes.func.isRequired,
  setInitialOrder: PropTypes.func.isRequired,
  setInitialAddress: PropTypes.func.isRequired,
};

ExistedOrders.defaultProps = {
  history: {},
};

const mapStateToProps = ({ customer }) => ({
  customerList: customer.list,
  addressList: customer.addressList,
});

const mapDispatchToProps = dispatch => {
  const { setInitialAddress } = addressActionCreators;
  const { setInitialOrder, getMyOrder } = orderActionCreators;
  const { setInitialCatalog } = catalogActionCreators;

  return bindActionCreators(
    {
      setInitialOrder,
      getMyOrder,
      setInitialAddress,
      setInitialCatalog,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  withRouter,
  connectOrder(mapStateToProps, mapDispatchToProps),
  connectAddress(undefined, mapDispatchToProps),
  connectCatalog(undefined, mapDispatchToProps),
)(ExistedOrders);
