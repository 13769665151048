/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import Img from 'react-image';
import { withRouter } from 'react-router-dom';
import { Checkbox, Form, Radio, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ArrowBack, ArrowDropDown, ArrowForward } from '@material-ui/icons';
import NumberPicker from 'components/Basic/NumberPicker';
import { catalogActionCreators, connectCatalog } from 'core';
import { addPriceToProducts, getCurrency, getDefaultSize, getGroups, getPrice, getProductInfo } from 'utilities/common';
import { promisify } from 'utilities';
import toast from 'components/Basic/Toast';

import mcmenu from 'assets/img/mcmenu.svg';
import individual from 'assets/img/individual.svg';
import placeHolderImg from 'assets/img/placeholder.svg';

const { Option } = Select;

class SingleProductDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menuType: 'mcmenu',
      selectedProduct: {},
      currentSizes: [],
      groups: [],
      orderData: {
        size: {},
        selectedGroups: {},
        totalCount: 1,
        customized: [],
        aggregate: [],
      },
      amount: 0,
      selectedSize: null,
      productRecipes: [],
      isEditable: true,
    };
  }

  componentDidMount() {
    this.setMenuType();
    this.getInitialValues(null);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          menuType: '',
          selectedProduct: {},
          currentSizes: [],
          groups: [],
          orderData: {
            size: {},
            selectedGroups: {},
            totalCount: 1,
            customized: [],
            aggregate: [],
          },
          amount: 0,
        },
        () => {
          this.setMenuType();
        },
      );
    }
    if (prevState.orderData !== this.state.orderData) {
      this.calculateAmount();
    }
  }

  setMenuType = () => {
    const { isEditOrder, products, cart, id, cartIndex } = this.props;
    const { selectedSize } = this.state;
    if (isEditOrder) {
      const savedCart = cart[cartIndex];
      this.setState({ menuType: !savedCart.size.size ? 'individual' : 'mcmenu' }, () => {
        this.getInitialValues(selectedSize ? selectedSize.size : null);
      });
    } else {
      const selectedProduct = products.filter(product => `${product.id}` === `${id}`);
      if (selectedProduct.length !== 0) {
        if (selectedProduct[0].type === '1') {
          this.setState({ menuType: 'individual' }, () => {
            this.getInitialValues(null);
          });
        }

        if (selectedProduct[0].type === '2' || selectedProduct[0].type === '3') {
          this.setState({ menuType: 'mcmenu' }, () => {
            this.getInitialValues(selectedSize ? selectedSize.size : null);
          });
        }
      }
    }
  };

  getInitialValues = (selectedSize = null) => {
    const { products, sizes, prices, productGroups, optionGroups, options, recipes } = this.props;
    const { menuType, orderData } = this.state;
    const { id } = this.props;
    const selectedProduct = products.filter(product => `${product.id}` === `${id}`);
    const stocks = this.props.stocks.map(({ productCode }) => productCode);
    if (selectedProduct.length !== 0) {
      const currentSizes = sizes
        .filter(
          ({ classCode, item, productCode, state }) =>
            state === 'A' &&
            classCode === selectedProduct[0].classCode &&
            item === selectedProduct[0].item &&
            !stocks.includes(productCode),
        )
        .map(data => ({
          ...data,
          price: getPrice(prices, data.productCode),
        }));
      this.setState({ selectedProduct: selectedProduct[0] });
      if (selectedProduct[0].type === '2' || selectedProduct[0].type === '3') {
        this.setState({ currentSizes });
      }
      const groups = getGroups(
        selectedProduct[0],
        productGroups,
        optionGroups,
        options,
        recipes,
        currentSizes,
        menuType,
        selectedSize,
        stocks,
      );

      this.setState({ groups });
      const productRecipes = recipes.filter(
        ({ ingredient, productCode, state }) =>
          state === 'A' && productCode === selectedProduct[0].productCode && !stocks.includes(ingredient),
      );
      this.setState({ productRecipes });
      const selectedGroups = {};

      groups.map(gItem => {
        let option = null;
        if (gItem.options && gItem.options.length !== 0) {
          if (Object.keys(orderData.selectedGroups).length === 0) {
            option = gItem.options.find(o => o.defaultValue === 'S') || gItem.options[0];
          } else {
            option =
              (orderData.selectedGroups[gItem.id] &&
                orderData.selectedGroups[gItem.id].option &&
                (gItem.options.find(
                  ({ productCode }) => productCode === orderData.selectedGroups[gItem.id].option.productCode,
                ) ||
                  gItem.options.find(
                    ({ ordering }) => ordering === orderData.selectedGroups[gItem.id].option.ordering,
                  ))) ||
              gItem.options.find(o => o.defaultValue === 'S') ||
              gItem.options[0];
          }
        }
        if (gItem.quantity === 1 && gItem.group.showRecipe !== 'S' && gItem.options && gItem.options.length !== 0) {
          selectedGroups[gItem.id] = { option };
        }

        if (gItem.quantity > 1) {
          selectedGroups[gItem.id] = {};
          if (gItem.options && gItem.options.length !== 0) {
            for (let i = 0; i < gItem.quantity; i += 1) {
              const { recipes: _recipes, ...data } = option;
              selectedGroups[gItem.id] = {
                ...selectedGroups[gItem.id],
                [`option${i}`]: {
                  ...data,
                  recipes: _recipes
                    ? _recipes.map(recipe => ({ ...recipe, quantity: recipe.type === 'I' ? 1 : 0 }))
                    : [],
                },
              };
            }
          }
        }
        return true;
      });
      this.setState(
        {
          orderData: {
            size: {},
            selectedGroups: {},
            totalCount: 1,
            customized: orderData.customized || [],
            aggregate: orderData.aggregate || [],
          },
        },
        () => {
          this.setInitialValues(selectedGroups, currentSizes, productRecipes);
        },
      );
    }
  };

  setInitialValues = (selectedGroups, currentSizes, productRecipes) => {
    const { orderData, selectedSize, menuType, isEditable } = this.state;
    const { isEditOrder, cart, sizes, productGroups, optionGroups, options, recipes, cartIndex } = this.props;
    const stocks = this.props.stocks.map(({ productCode }) => productCode);
    const defaultSize = getDefaultSize(currentSizes);

    if (isEditOrder && isEditable) {
      const savedCart = cart[cartIndex];
      const groups = getGroups(
        savedCart.item,
        productGroups,
        optionGroups,
        options,
        recipes,
        sizes,
        menuType,
        menuType === 'mcmenu' ? (selectedSize || savedCart.size || defaultSize).size : null,
        stocks,
      );
      this.setState({
        groups,
        orderData: {
          ...savedCart,
          size: menuType === 'mcmenu' ? selectedSize || savedCart.size || defaultSize : {},
        },
        selectedSize: menuType === 'mcmenu' ? selectedSize || savedCart.size || defaultSize : null,
      });
    } else {
      this.setState({
        orderData: {
          ...orderData,
          selectedGroups,
          size: menuType === 'mcmenu' ? selectedSize || defaultSize : {},
          customized:
            productRecipes.filter(recipe => {
              const savedRecipe = (orderData.customized || []).find(c => c.id === recipe.id && recipe.type === 'I');
              if (savedRecipe) {
                recipe.quantity = savedRecipe.quantity;
                return true;
              }
              if (recipe.type === 'I') {
                recipe.quantity = 1;
                return true;
              }
              return false;
            }) || [],
          aggregate:
            productRecipes.filter(recipe => {
              const savedRecipe = (orderData.aggregate || []).find(c => c.id === recipe.id && recipe.type === 'A');
              if (savedRecipe) {
                recipe.quantity = savedRecipe.quantity;
                return true;
              }
              if (recipe.type === 'A') {
                recipe.quantity = 0;
                return true;
              }
              return false;
            }) || [],
        },
      });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { orderData, selectedProduct: item, amount } = this.state;
    const { cart, isEditOrder, cartIndex, cataloges, products, prices, sizes } = this.props;

    let giftProducts = [];
    let isExistGift = false;
    const giftCataloge = cataloges.find(s => s.name === 'Gift Card');
    if (giftCataloge) {
      giftProducts = products.filter(p => p.classCode === giftCataloge.id);
    }

    const updatedProducts = addPriceToProducts(giftProducts, prices, sizes);

    if (
      updatedProducts.findIndex(g => g.defaultCode === item.productCode) !== -1 ||
      updatedProducts.findIndex(g => g.defaultCode === (orderData.size && orderData.size.productCode)) !== -1
    ) {
      for (let i = 0; i < cart.length; i += 1) {
        if (
          updatedProducts.findIndex(g => g.defaultCode === cart[i].item.productCode) !== -1 ||
          updatedProducts.findIndex(g => g.defaultCode === (cart[i].size && cart[i].size.productCode)) !== -1
        ) {
          isExistGift = true;
          break;
        }
      }

      if (isExistGift) {
        toast.error({ title: 'El producto de regalo ya está agregado!' });
        return;
      }
    }

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (isEditOrder) {
          cart[cartIndex] = {
            ...orderData,
            item,
            cookieNote: values.instruction || '',
            amount,
          };
          promisify(this.props.setInitialCatalog, {
            cart: [...cart],
          });
        } else {
          promisify(this.props.setInitialCatalog, {
            cart: [
              ...cart,
              {
                ...orderData,
                item,
                cookieNote: values.instruction || '',
                amount,
              },
            ],
          });
        }
        this.props.onUpdateProduct();
      }
    });
  };

  handleTotalCount = count => {
    const { orderData } = this.state;
    this.setState({
      orderData: {
        ...orderData,
        totalCount: count,
      },
    });
  };

  handleSize = e => {
    const { currentSizes, orderData } = this.state;
    this.setState(
      {
        isEditable: false,
        orderData: {
          ...orderData,
          size: { ...currentSizes.filter(size => `${size.size}` === `${e.target.value}`)[0] },
        },
        selectedSize: { ...currentSizes.filter(size => `${size.size}` === `${e.target.value}`)[0] },
      },
      () => {
        this.getInitialValues(e.target.value);
      },
    );
  };

  handleGroup = (option, groupId) => {
    const { orderData } = this.state;

    this.setState({
      orderData: {
        ...orderData,
        selectedGroups: {
          ...orderData.selectedGroups,
          [groupId]: { option },
        },
      },
    });
  };

  handleChangeOption = (data, groupIndex, i, groupId) => {
    const { orderData, productRecipes } = this.state;
    this.setState({
      orderData: {
        ...orderData,
        selectedGroups: {
          ...orderData.selectedGroups,
          [groupId]: {
            ...orderData.selectedGroups[groupId],
            [`option${i}`]: {
              ...data[0],
              recipes: data[0].recipes
                ? data[0].recipes.map(recipe => ({ ...recipe, quantity: recipe.type === 'I' ? 1 : 0 }))
                : [],
            },
          },
        },
        customized:
          productRecipes.filter(recipe => {
            if (recipe.type === 'I') {
              recipe.quantity = 1;
              return true;
            }
            return false;
          }) || [],
        aggregate:
          productRecipes.filter(recipe => {
            if (recipe.type === 'A') {
              recipe.quantity = 0;
              return true;
            }
            return false;
          }) || [],
      },
    });
    this.calculateAmount();
  };

  handleIngredient = (values, type, groupId = 0, index = 0) => {
    const orderData = JSON.parse(JSON.stringify(this.state.orderData));
    if (type === 'customize') {
      for (let i = 0; i < (orderData.customized || []).length; i += 1) {
        if (values.includes((orderData.customized || [])[i].id)) {
          (orderData.customized || [])[i].quantity = 1;
        } else {
          (orderData.customized || [])[i].quantity = 0;
        }
      }
    } else if (type === 'aggregate') {
      for (let i = 0; i < (orderData.aggregate || []).length; i += 1) {
        if (values.includes((orderData.aggregate || [])[i].id)) {
          (orderData.aggregate || [])[i].quantity = 1;
        } else {
          (orderData.aggregate || [])[i].quantity = 0;
        }
      }
    } else if (type === 'option_customize') {
      const optionCustomized = orderData.selectedGroups[groupId][`option${index}`].recipes
        ? orderData.selectedGroups[groupId][`option${index}`].recipes.filter(recipe => recipe.type === 'I')
        : [];
      for (let i = 0; i < optionCustomized.length; i += 1) {
        if (values.includes(optionCustomized[i].id)) {
          optionCustomized[i].quantity = 1;
        } else {
          optionCustomized[i].quantity = 0;
        }
      }
    } else if (type === 'option_aggregate') {
      const optionAggregate = orderData.selectedGroups[groupId][`option${index}`].recipes
        ? orderData.selectedGroups[groupId][`option${index}`].recipes.filter(recipe => recipe.type === 'A')
        : [];
      for (let i = 0; i < optionAggregate.length; i += 1) {
        if (values.includes(optionAggregate[i].id)) {
          optionAggregate[i].quantity = 1;
        } else {
          optionAggregate[i].quantity = 0;
        }
      }
    }
    this.setState({ orderData: JSON.parse(JSON.stringify(orderData)) }, () => {
      this.calculateAmount();
    });
  };

  handleCount = (value, type, groupId = 0, index = 0, recipeId) => {
    const orderData = JSON.parse(JSON.stringify(this.state.orderData));
    if (type === 'customize') {
      (orderData.customized || []).filter(recipe => recipe.id === recipeId)[0].quantity = value;
    } else if (type === 'aggregate') {
      (orderData.aggregate || []).filter(recipe => recipe.id === recipeId)[0].quantity = value;
    } else if (type === 'option_customize') {
      const optionCustomized = orderData.selectedGroups[groupId][`option${index}`].recipes
        ? orderData.selectedGroups[groupId][`option${index}`].recipes.filter(
            recipe => recipe.type === 'I' && recipe.id === recipeId,
          )
        : [];
      optionCustomized[0].quantity = value;
    } else if (type === 'option_aggregate') {
      const optionAggregate = orderData.selectedGroups[groupId][`option${index}`].recipes
        ? orderData.selectedGroups[groupId][`option${index}`].recipes.filter(
            recipe => recipe.type === 'A' && recipe.id === recipeId,
          )
        : [];
      optionAggregate[0].quantity = value;
    }

    if (groupId) {
      this.setState({
        orderData: {
          ...orderData,
          selectedGroups: {
            ...orderData.selectedGroups,
            [groupId]: {
              ...orderData.selectedGroups[groupId],
              [`option${index}`]: {
                ...orderData.selectedGroups[groupId][`option${index}`],
                recipes: [...orderData.selectedGroups[groupId][`option${index}`].recipes],
              },
            },
          },
        },
      });
    } else if (type === 'aggregate') {
      this.setState({
        orderData: {
          ...orderData,
          aggregate: [...(orderData.aggregate || [])],
        },
      });
    } else if (type === 'customize') {
      this.setState({
        orderData: {
          ...orderData,
          customized: [...(orderData.customized || [])],
        },
      });
    }
  };

  calculateAmount = () => {
    const { selectedProduct: item, orderData, menuType } = this.state;
    const { prices } = this.props;
    const { size } = orderData;
    const options = orderData.selectedGroups;
    const { customized } = orderData;
    const { aggregate } = orderData;
    let amount = 0;
    if (menuType === 'mcmenu') {
      amount = size.price || getPrice(prices, item.productCode);
    } else {
      amount = getPrice(prices, item.productCode);
    }
    Object.keys(options).forEach(key => {
      if (!options[key]) return;
      Object.keys(options[key]).forEach(oKey => {
        let optionAmount = getPrice(prices, options[key][oKey].productCode);
        if (options[key][oKey].recipes) {
          const optionCustomized = options[key][oKey].recipes
            ? options[key][oKey].recipes.filter(recipe => recipe.type === 'I')
            : [];
          const optionAggregate = options[key][oKey].recipes
            ? options[key][oKey].recipes.filter(recipe => recipe.type === 'A')
            : [];
          optionCustomized.forEach(data => {
            optionAmount +=
              getPrice(prices, data.ingredient) * (data.quantity && data.quantity !== 0 ? data.quantity - 1 : 0);
          });
          optionAggregate.forEach(data => {
            optionAmount += getPrice(prices, data.ingredient) * (data.quantity ? data.quantity : 0);
          });
        }
        amount += optionAmount;
      });
    });
    (customized || []).forEach(data => {
      amount += getPrice(prices, data.ingredient) * (data.quantity && data.quantity !== 0 ? data.quantity - 1 : 0);
    });
    (aggregate || []).forEach(data => {
      amount += getPrice(prices, data.ingredient) * (data.quantity ? data.quantity : 0);
    });
    this.setState({ amount });
  };

  getProductInfo = data => {
    const { prices, sizes } = this.props;
    return getProductInfo(data, prices, sizes);
  };

  render() {
    const { prices } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { selectedProduct, currentSizes, groups, orderData, amount, selectedSize, menuType } = this.state;
    let defaultPrice = 0;
    if (currentSizes && currentSizes.length !== 0 && orderData.size.size) {
      const defaultSize = getDefaultSize(currentSizes);
      defaultPrice = currentSizes.filter(s => s.size === defaultSize.size)[0].price;
    }

    return (
      <div className="single_detail_layout">
        <div className="product_detail">
          <div className="back_btn_wrapper">
            <div className="back_btn" onClick={this.props.onBack}>
              <ArrowBack />
            </div>
          </div>
          <Img
            alt="food"
            src={
              selectedProduct.productCode !== undefined
                ? `${process.env.REACT_APP_S3}/${
                    selectedProduct.productCode > 0
                      ? selectedProduct.productCode
                      : this.getProductInfo(selectedProduct).productCode
                  }.jpg`
                : placeHolderImg
            }
          />
          <p className="title product_title" dangerouslySetInnerHTML={{ __html: selectedProduct.name || '' }} />
          <p className="description">
            {selectedProduct.description || ''}
            {selectedProduct.description2 || ''}
          </p>
          <div className="prefer_menu">
            {(selectedProduct.type === '2' || selectedProduct.type === '3') && (
              <div
                className={menuType === 'mcmenu' ? 'active' : ''}
                onClick={() => {
                  this.setState({ isEditable: false, menuType: 'mcmenu' }, () => {
                    this.getInitialValues(selectedSize ? selectedSize.size : null);
                  });
                }}
              >
                <Img alt="mcmenu" src={mcmenu} />
                <p className="title">
                  <FormattedMessage id="McMenu" />
                </p>
              </div>
            )}
            {(selectedProduct.type === '1' || selectedProduct.type === '3') && (
              <div
                className={menuType === 'individual' ? 'active' : ''}
                onClick={() => {
                  this.setState({ isEditable: false, menuType: 'individual' }, () => {
                    this.getInitialValues(null);
                  });
                }}
              >
                <Img alt="individual" src={individual} />
                <p className="title">
                  <FormattedMessage id="Individual" />
                </p>
              </div>
            )}
          </div>
        </div>
        <Form className="single_product_form" onSubmit={this.handleSubmit}>
          {this.state.menuType === 'mcmenu' && currentSizes && currentSizes.length !== 0 && (
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Size" />
              </p>
              <p className="description">
                <FormattedMessage id="required" />
              </p>
              {getFieldDecorator('productSize', {
                rules: [],
                initialValue: orderData.size.size,
              })(
                <Radio.Group onChange={this.handleSize}>
                  {currentSizes.map(size => (
                    <Radio key={Math.random()} value={size.size}>
                      <span dangerouslySetInnerHTML={{ __html: size.name || '' }} />{' '}
                      {size.price - defaultPrice !== 0 && (
                        <span className="q_label">
                          {`(${size.price - defaultPrice > 0 ? '+' : ''}${getCurrency().symbol}${parseFloat(
                            (size.price - defaultPrice) / 100,
                          ).toFixed(2)})`}
                        </span>
                      )}
                    </Radio>
                  ))}
                </Radio.Group>,
              )}
            </Form.Item>
          )}
          {groups &&
            groups.map((gItem, index) => {
              if (gItem.quantity === 1 && gItem.group.showRecipe !== 'S' && orderData.selectedGroups[gItem.id]) {
                return (
                  <Form.Item key={Math.random()}>
                    <p className="title">{gItem.group.name}</p>
                    <p className="description">
                      <FormattedMessage id="required" />
                    </p>
                    {getFieldDecorator(`option${index}`, {
                      rules: [],
                      initialValue: orderData.selectedGroups[gItem.id].option.id,
                    })(
                      <Radio.Group
                        onChange={value =>
                          this.handleGroup(gItem.options.filter(o => o.id === value.target.value)[0], gItem.id)
                        }
                      >
                        {gItem.options.map(option => (
                          <Radio key={Math.random()} value={option.id}>
                            <span dangerouslySetInnerHTML={{ __html: option.name }} />
                            {getPrice(prices, option.productCode) !== 0 && (
                              <span className="q_label">
                                (+{parseFloat(getPrice(prices, option.productCode) / 100).toFixed(2)})
                              </span>
                            )}
                          </Radio>
                        ))}
                      </Radio.Group>,
                    )}
                  </Form.Item>
                );
              }

              if (gItem.quantity > 1 && orderData.selectedGroups[gItem.id]) {
                const multiOptions = [<p className="group_name">{gItem.group.name}</p>];
                for (let i = 0; i < gItem.quantity; i += 1) {
                  if (!orderData.selectedGroups[gItem.id][`option${i}`]) {
                    return null;
                  }
                  const optionCustomized = orderData.selectedGroups[gItem.id][`option${i}`].recipes
                    ? orderData.selectedGroups[gItem.id][`option${i}`].recipes.filter(recipe => recipe.type === 'I')
                    : [];
                  const optionAggregate = orderData.selectedGroups[gItem.id][`option${i}`].recipes
                    ? orderData.selectedGroups[gItem.id][`option${i}`].recipes.filter(recipe => recipe.type === 'A')
                    : [];
                  multiOptions.push(
                    <div className="multi_options" key={Math.random()}>
                      <div className="option_multi_select">
                        <Form.Item>
                          {getFieldDecorator(`option${index}${i}`, {
                            initialValue: orderData.selectedGroups[gItem.id][`option${i}`].id,
                          })(
                            <Select
                              onSelect={value =>
                                this.handleChangeOption(
                                  gItem.options.filter(option => option.id === value),
                                  index,
                                  i,
                                  gItem.id,
                                )
                              }
                              suffixIcon={<ArrowDropDown />}
                            >
                              {gItem.options.map(option => (
                                <Option key={Math.random()} value={option.id}>
                                  <span dangerouslySetInnerHTML={{ __html: option.name }} />
                                  {getPrice(prices, option.productCode) !== 0 && (
                                    <span className="q_label">
                                      (+{parseFloat(getPrice(prices, option.productCode) / 100).toFixed(2)})
                                    </span>
                                  )}
                                </Option>
                              ))}
                            </Select>,
                          )}
                        </Form.Item>
                      </div>
                      {gItem.group.showRecipe === 'S' && (
                        <div className="option_recipes">
                          {optionCustomized && optionCustomized.length !== 0 && (
                            <Form.Item>
                              <p className="title">
                                <FormattedMessage id="Personalize" />
                              </p>
                              <p className="description">
                                <FormattedMessage id="Select up to 5 options" />
                              </p>
                              {getFieldDecorator(`ingredient${index}${i}`, {
                                initialValue: [...optionCustomized.map(c => c.quantity && c.quantity > 0 && c.id)],
                              })(
                                <Checkbox.Group
                                  onChange={value => this.handleIngredient(value, 'option_customize', gItem.id, i)}
                                >
                                  {optionCustomized.map(customize => (
                                    <div className="recipe_wrapper" key={Math.random()}>
                                      <Checkbox value={customize.id}>
                                        {customize.description}
                                        {customize.quantity > 1 && getPrice(prices, customize.ingredient) !== 0 && (
                                          <span className="q_label">
                                            (+{parseFloat(getPrice(prices, customize.ingredient) / 100).toFixed(2)})
                                          </span>
                                        )}
                                      </Checkbox>
                                      {customize.requestQuantity === 'S' && (
                                        <NumberPicker
                                          maxvalue={2}
                                          onChange={value =>
                                            this.handleCount(value, 'option_customize', gItem.id, i, customize.id)
                                          }
                                          selectedNumber={customize.quantity || 1}
                                          size="small"
                                        />
                                      )}
                                    </div>
                                  ))}
                                </Checkbox.Group>,
                              )}
                            </Form.Item>
                          )}
                          {optionAggregate && optionAggregate.length !== 0 && (
                            <Form.Item>
                              <p className="title">
                                <FormattedMessage id="Aggregates" />
                              </p>
                              <p className="description">
                                <FormattedMessage id="Optional" />
                              </p>
                              {getFieldDecorator(`extra_ingredient${index}${i}`, {
                                initialValue: [...optionAggregate.map(a => a.quantity && a.quantity > 0 && a.id)],
                              })(
                                <Checkbox.Group
                                  onChange={value => this.handleIngredient(value, 'option_aggregate', gItem.id, i)}
                                >
                                  {optionAggregate.map(agg => (
                                    <div className="recipe_wrapper" key={Math.random()}>
                                      <Checkbox value={agg.id}>
                                        {agg.description}
                                        {getPrice(prices, agg.ingredient) !== 0 && (
                                          <span className="q_label">
                                            (+{parseFloat(getPrice(prices, agg.ingredient) / 100).toFixed(2)})
                                          </span>
                                        )}
                                      </Checkbox>
                                      {agg.requestQuantity === 'S' && (
                                        <NumberPicker
                                          maxvalue={5}
                                          onChange={value =>
                                            this.handleCount(value, 'option_aggregate', gItem.id, i, agg.id)
                                          }
                                          selectedNumber={agg.quantity || 1}
                                          size="small"
                                        />
                                      )}
                                    </div>
                                  ))}
                                </Checkbox.Group>,
                              )}
                            </Form.Item>
                          )}
                        </div>
                      )}
                    </div>,
                  );
                }
                return multiOptions;
              }
              return null;
            })}
          {(orderData.customized || []) && (orderData.customized || []).length !== 0 && (
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Personalize" />
              </p>
              <p className="description">
                <FormattedMessage id="Select up to 5 options" />
              </p>
              {getFieldDecorator('ingredient', {
                initialValue: [...(orderData.customized || []).map(c => c.quantity && c.quantity > 0 && c.id)],
              })(
                <Checkbox.Group onChange={value => this.handleIngredient(value, 'customize')}>
                  {(orderData.customized || []).map(customize => (
                    <div className="recipe_wrapper" key={Math.random()}>
                      <Checkbox value={customize.id}>
                        {customize.description}
                        {customize.quantity > 1 && getPrice(prices, customize.ingredient) !== 0 && (
                          <span className="q_label">
                            (+{parseFloat(getPrice(prices, customize.ingredient) / 100).toFixed(2)})
                          </span>
                        )}
                      </Checkbox>
                      {customize.requestQuantity === 'S' && (
                        <NumberPicker
                          maxvalue={2}
                          onChange={value => this.handleCount(value, 'customize', 0, 0, customize.id)}
                          selectedNumber={customize.quantity || 1}
                          size="small"
                        />
                      )}
                    </div>
                  ))}
                </Checkbox.Group>,
              )}
            </Form.Item>
          )}
          {(orderData.aggregate || []) && (orderData.aggregate || []).length !== 0 && (
            <Form.Item>
              <p className="title">
                <FormattedMessage id="Aggregates" />
              </p>
              <p className="description">
                <FormattedMessage id="Optional" />
              </p>
              {getFieldDecorator('extra_ingredient', {
                initialValue: [...(orderData.aggregate || []).map(a => a.quantity && a.quantity > 0 && a.id)],
              })(
                <Checkbox.Group onChange={value => this.handleIngredient(value, 'aggregate')}>
                  {(orderData.aggregate || []).map(agg => (
                    <div className="recipe_wrapper" key={Math.random()}>
                      <Checkbox value={agg.id}>
                        {agg.description}
                        {getPrice(prices, agg.ingredient) !== 0 && (
                          <span className="q_label">
                            (+{parseFloat(getPrice(prices, agg.ingredient) / 100).toFixed(2)})
                          </span>
                        )}
                      </Checkbox>
                      {agg.requestQuantity === 'S' && (
                        <NumberPicker
                          maxvalue={5}
                          onChange={value => this.handleCount(value, 'aggregate', 0, 0, agg.id)}
                          selectedNumber={agg.quantity || 1}
                          size="small"
                        />
                      )}
                    </div>
                  ))}
                </Checkbox.Group>,
              )}
            </Form.Item>
          )}
          {/* <Form.Item>
            <p className="title">
              <FormattedMessage id="Special instructions" />
            </p>
            <p className="description">
              <FormattedMessage id="Do you have additional comments for our kitchen?" />
            </p>
            {getFieldDecorator('instruction', {
              initialValue: orderData.instruction || ''
            })(<Input.TextArea />)}
          </Form.Item> */}
          <Form.Item>
            <p className="title">
              <FormattedMessage id="How many do you want like this?" />
            </p>
            <p className="description">
              <FormattedMessage id="Select how many you want with this same selection of options" />
            </p>
            <NumberPicker onChange={this.handleTotalCount} selectedNumber={orderData.totalCount} />
          </Form.Item>
          <div className="btn-wrapper">
            <button type="submit">
              <div>
                <p>
                  <FormattedMessage id="Add" /> {orderData.totalCount} <FormattedMessage id="to my bag" />
                </p>
                {amount ? (
                  <p>
                    <FormattedMessage id="by" /> {getCurrency().symbol}
                    {parseFloat((amount * orderData.totalCount) / 100).toFixed(2)}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <ArrowForward />
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

SingleProductDetail.propTypes = {
  isEditOrder: PropTypes.bool,
  form: PropTypes.object,
  id: PropTypes.number.isRequired,
  cartIndex: PropTypes.number,
  onBack: PropTypes.func.isRequired,
  products: PropTypes.array,
  prices: PropTypes.array,
  sizes: PropTypes.array,
  cataloges: PropTypes.array,
  productGroups: PropTypes.array,
  optionGroups: PropTypes.array,
  options: PropTypes.array,
  recipes: PropTypes.array,
  cart: PropTypes.array,
  stocks: PropTypes.array,
  setInitialCatalog: PropTypes.func.isRequired,
  onUpdateProduct: PropTypes.func.isRequired,
};

SingleProductDetail.defaultProps = {
  isEditOrder: false,
  form: {},
  cartIndex: 0,
  products: [],
  prices: [],
  sizes: [],
  cataloges: [],
  productGroups: [],
  optionGroups: [],
  options: [],
  recipes: [],
  cart: [],
  stocks: [],
};

const mapStateToProps = ({ catalog }) => ({
  products: catalog.products,
  prices: catalog.prices,
  sizes: catalog.sizes,
  cataloges: catalog.cataloges,
  productGroups: catalog.productGroups,
  optionGroups: catalog.optionGroups,
  options: catalog.options,
  recipes: catalog.recipes,
  cart: catalog.cart,
  stocks: catalog.stocks,
});

const mapDispatchToProps = dispatch => {
  const { setInitialCatalog } = catalogActionCreators;

  return bindActionCreators(
    {
      setInitialCatalog,
    },
    dispatch,
  );
};

export default compose(
  withRouter,
  connectCatalog(mapStateToProps, mapDispatchToProps),
  Form.create({ name: 'single_product_form' }),
)(SingleProductDetail);
