import { GET_RESTAURANT_LIST_SUCCESS } from 'core/modules/restaurant/actions';
import { initialState } from 'core/modules/initialState';

export default function restaurant(state = initialState.restaurant, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case GET_RESTAURANT_LIST_SUCCESS: {
      return {
        ...state,
        list: payload.list,
      };
    }
    default: {
      return state;
    }
  }
}
