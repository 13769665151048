/* eslint-disable no-unused-vars */
import { all, call, fork, put, take } from 'redux-saga/effects';
import { authActionCreators } from 'core/modules/auth/actions';
import {
  CANCEL_MY_ORDER_REQUEST,
  CREATE_MY_ORDER_REQUEST,
  GET_CHECK_MY_ORDER_REQUEST,
  GET_MY_ORDER_REQUEST,
  GET_SPECIAL_ORDERS_REQUEST,
  orderActionCreators,
  SET_INITIAL_ORDER_VALUE,
  UPDATE_ORDER_REQUEST,
} from 'core/modules/order/actions';

import { restService } from 'utilities';

export function* asyncSetInitialValueRequest({ payload, resolve, reject }) {
  try {
    yield put(orderActionCreators.setInitialOrderSuccess({ ...payload }));
    resolve(true);
  } catch (e) {
    reject(e);
    resolve(false);
  }
}

export function* asyncGetMyOrderRequest({ payload, resolve, reject }) {
  const { offset, limit, or, filter, sort } = payload;
  try {
    const response = yield call(restService, {
      api: `/orders?details=true&offset=${offset}&limit=${limit}${or ? `&or=${or}` : ''}${filter || ''}${
        sort ? `&sort=${sort} desc` : '&sort=createdAt desc'
      }`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(orderActionCreators.getMyOrderSuccess({ list: response.data.rows }));
      resolve(response.data.rows);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncGetCheckMyOrderRequest({ payload, resolve, reject }) {
  const { params } = payload;
  try {
    const response = yield call(restService, {
      api: '/orders/checkout',
      method: 'POST',
      params,
    });
    if (response.status === 200) {
      resolve(response);
    } else if (response.status === 406) {
      reject(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject(response);
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncHandleMyOrderRequest({ payload, resolve, reject }) {
  const { params } = payload;
  try {
    const response = yield call(restService, {
      api: '/orders',
      method: 'POST',
      params,
    });
    if (response.status === 201) {
      resolve(response);
    } else if (response.status === 406) {
      reject(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncCancelMyOrderRequest({ payload, resolve, reject }) {
  const { id } = payload;

  try {
    const response = yield call(restService, {
      api: `/orders/${id}/cancel`,
      method: 'PUT',
      params: {},
    });
    if (response.status === 201) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetSpecialOrdersRequest({ payload, resolve, reject }) {
  const { filter } = payload;
  try {
    const response = yield call(restService, {
      api: `/orders/oncePerCustomers?${filter}`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(
        orderActionCreators.getSpecialOrdersSuccess({
          specialOrders: response.data.rows,
        }),
      );
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncUpdateOrderRequest({ payload, resolve, reject }) {
  const {
    order,
    parentOrder,
    groupOrder,
    customerAddress,
    confirmedAddress,
    newState,
    newAmbassador,
    newClaimCategory,
    newClaimText,
    long,
    lat,
  } = payload;

  try {
    const response = yield call(restService, {
      api: `/orders/update`,
      method: 'PUT',
      params: {
        order,
        customerAddress,
        newState: 'CL',
        newClaimCategory,
        newClaimText,
      },
    });
    if (response.status === 202) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* watchSetInitialValueRequest() {
  while (true) {
    const action = yield take(SET_INITIAL_ORDER_VALUE);
    yield* asyncSetInitialValueRequest(action);
  }
}
export function* watchGetMyOrderRequest() {
  while (true) {
    const action = yield take(GET_MY_ORDER_REQUEST);
    yield* asyncGetMyOrderRequest(action);
  }
}
export function* watchCheckMyOrderRequest() {
  while (true) {
    const action = yield take(GET_CHECK_MY_ORDER_REQUEST);
    yield* asyncGetCheckMyOrderRequest(action);
  }
}
export function* watchCreateMyOrderRequest() {
  while (true) {
    const action = yield take(CREATE_MY_ORDER_REQUEST);
    yield* asyncHandleMyOrderRequest(action);
  }
}

export function* watchCancelMyOrderRequest() {
  while (true) {
    const action = yield take(CANCEL_MY_ORDER_REQUEST);
    yield* asyncCancelMyOrderRequest(action);
  }
}

export function* watchGetSpecialOrdersRequest() {
  while (true) {
    const action = yield take(GET_SPECIAL_ORDERS_REQUEST);
    yield* asyncGetSpecialOrdersRequest(action);
  }
}

export function* watchUpdateOrderRequest() {
  while (true) {
    const action = yield take(UPDATE_ORDER_REQUEST);
    yield* asyncUpdateOrderRequest(action);
  }
}

export default function*() {
  yield all([
    fork(watchSetInitialValueRequest),
    fork(watchGetMyOrderRequest),
    fork(watchCheckMyOrderRequest),
    fork(watchCreateMyOrderRequest),
    fork(watchCancelMyOrderRequest),
    fork(watchGetSpecialOrdersRequest),
    fork(watchUpdateOrderRequest),
  ]);
}
