export const HOUR_FINISH_BREAKFAST = 11;
export const MINUTES_FINISH_BREAKFAST = 15;

export const constant = {
  FLAGS: ['GB', 'CN', 'JP', 'ES'],
};

export const COLOR_SCHEMA = [
  '#2a52be',
  '#de6fa1',
  '#dcc488',
  '#9fe9b7',
  '#00e699',
  '#ffb67c',
  '#feff7e',
  '#41165e',
  '#a035ea',
  '#35ead4',
  '#958c66',
  '#ba9bff',
  '#fffcba',
  '#f8ecdf',
  '#cdc8da',
  '#409caf',
  '#277abe',
  '#9db5e8',
  '#1e90ff',
  '#9de8d0',
  '#95f2ff',
  '#b7ffb1',
  '#FF3E96',
  '#9B30FF',
  '#AB82FF',
  '#00868B',
  '#00FFFF',
  '#00CD66',
  '#FFFF00',
  '#8B8B00',
  '#EEC900',
  '#CD9B1D',
  '#EE7600',
  '#EE4000',
];
