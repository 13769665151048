import { createAction } from 'redux-actions';
import { createPromiseAction } from 'core/modules/utils';

/**
 * Action Types
 */
export const SET_INITIAL_CATALOG_VALUE = '@catalog/SET_INITIAL_CATALOG_VALUE';
export const SET_INITIAL_CATALOG_VALUE_SUCCESS = '@catalog/SET_INITIAL_CATALOG_VALUE_SUCCESS';
export const GET_SUPER_CATALOGS_REQUEST = '@catalog/GET_SUPER_CATALOGS_REQUEST';
export const GET_SUPER_CATALOGS_SUCCESS = '@catalog/GET_SUPER_CATALOGS_SUCCESS';
export const GET_SCHEDULE_SUPER_CATALOGS_REQUEST = '@catalog/GET_SCHEDULE_SUPER_CATALOGS_REQUEST';
export const GET_CATALOGS_REQUEST = '@catalog/GET_CATALOGS_REQUEST';
export const GET_CATALOGS_SUCCESS = '@catalog/GET_CATALOGS_SUCCESS';
export const GET_ITEMS_REQUEST = '@catalog/GET_ITEMS_REQUEST';
export const GET_ITEMS_SUCCESS = '@catalog/GET_ITEMS_SUCCESS';
export const GET_SINGLE_ITEM_REQUEST = '@catalog/GET_SINGLE_ITEM_REQUEST';
export const GET_PRICES_REQUEST = '@catalog/GET_PRICES_REQUEST';
export const GET_PRICES_SUCCESS = '@catalog/GET_PRICES_SUCCESS';
export const GET_SIZES_REQUEST = '@catalog/GET_SIZES_REQUEST';
export const GET_SIZES_SUCCESS = '@catalog/GET_SIZES_SUCCESS';
export const GET_ITEMS_GROUPS_REQUEST = '@catalog/GET_ITEMS_GROUPS_REQUEST';
export const GET_ITEMS_GROUPS_SUCCESS = '@catalog/GET_ITEMS_GROUPS_SUCCESS';
export const GET_OPTIONS_GROUPS_REQUEST = '@catalog/GET_OPTIONS_GROUPS_REQUEST';
export const GET_OPTIONS_GROUPS_SUCCESS = '@catalog/GET_OPTIONS_GROUPS_SUCCESS';
export const GET_OPTIONS_REQUEST = '@catalog/GET_OPTIONS_REQUEST';
export const GET_OPTIONS_SUCCESS = '@catalog/GET_OPTIONS_SUCCESS';
export const GET_RECIPES_REQUEST = '@catalog/GET_RECIPES_REQUEST';
export const GET_RECIPES_SUCCESS = '@catalog/GET_RECIPES_SUCCESS';
export const GET_DAY_PART_SUPER_CATALOGS_REQUEST = '@catalog/GET_DAY_PART_SUPER_CATALOGS_REQUEST';
/**
 * Action Creators
 */
export const catalogActionCreators = {
  setInitialCatalog: createPromiseAction(SET_INITIAL_CATALOG_VALUE),
  setInitialCatalogSuccess: createAction(SET_INITIAL_CATALOG_VALUE_SUCCESS),
  getSuperCatalogs: createPromiseAction(GET_SUPER_CATALOGS_REQUEST),
  getSuperCatalogsSuccess: createAction(GET_SUPER_CATALOGS_SUCCESS),
  getScheduleSuperCatalogs: createPromiseAction(GET_SCHEDULE_SUPER_CATALOGS_REQUEST),
  getCatalogs: createPromiseAction(GET_CATALOGS_REQUEST),
  getCatalogsSuccess: createAction(GET_CATALOGS_SUCCESS),
  getItems: createPromiseAction(GET_ITEMS_REQUEST),
  getItemsSuccess: createAction(GET_ITEMS_SUCCESS),
  getSingleItem: createPromiseAction(GET_SINGLE_ITEM_REQUEST),
  getPrices: createPromiseAction(GET_PRICES_REQUEST),
  getPricesSuccess: createAction(GET_PRICES_SUCCESS),
  getSizes: createPromiseAction(GET_SIZES_REQUEST),
  getSizesSuccess: createAction(GET_SIZES_SUCCESS),
  getItemGroups: createPromiseAction(GET_ITEMS_GROUPS_REQUEST),
  getItemGroupsSuccess: createAction(GET_ITEMS_GROUPS_SUCCESS),
  getOptionGroups: createPromiseAction(GET_OPTIONS_GROUPS_REQUEST),
  getOptionGroupsSuccess: createAction(GET_OPTIONS_GROUPS_SUCCESS),
  getOptions: createPromiseAction(GET_OPTIONS_REQUEST),
  getOptionsSuccess: createAction(GET_OPTIONS_SUCCESS),
  getRecipes: createPromiseAction(GET_RECIPES_REQUEST),
  getRecipesSuccess: createAction(GET_RECIPES_SUCCESS),
  getDayPartSuperCatalogs: createPromiseAction(GET_DAY_PART_SUPER_CATALOGS_REQUEST),
};
