/* eslint-disable no-unused-vars */
import { all, call, fork, put, take } from 'redux-saga/effects';
import { authActionCreators } from 'core/modules/auth/actions';
import {
  catalogActionCreators,
  GET_CATALOGS_REQUEST,
  GET_DAY_PART_SUPER_CATALOGS_REQUEST,
  GET_ITEMS_GROUPS_REQUEST,
  GET_ITEMS_REQUEST,
  GET_OPTIONS_GROUPS_REQUEST,
  GET_OPTIONS_REQUEST,
  GET_PRICES_REQUEST,
  GET_RECIPES_REQUEST,
  GET_SCHEDULE_SUPER_CATALOGS_REQUEST,
  GET_SINGLE_ITEM_REQUEST,
  GET_SIZES_REQUEST,
  GET_SUPER_CATALOGS_REQUEST,
  SET_INITIAL_CATALOG_VALUE,
} from 'core/modules/catalog/actions';
import { restService } from 'utilities';

export function* asyncSetInitialValueRequest({ payload, resolve, reject }) {
  try {
    yield put(catalogActionCreators.setInitialCatalogSuccess({ ...payload }));
    resolve(true);
  } catch (e) {
    reject(e);
    resolve(false);
  }
}

export function* asyncGetSuperCatalogsRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/catalog/superClasses?filter=state eq A&filter=channel eq CC&sort=ordering asc`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(
        catalogActionCreators.getSuperCatalogsSuccess({
          superCataloges: response.data.superClasses.rows.filter(c => !c.name.includes('Canje Puntos')),
          stocks: response.data.stocks.rows,
        }),
      );
      resolve(response);
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncGetScheduleSuperCatalogsRequest({ payload, resolve, reject }) {
  const { dueTo, restaurant } = payload;
  try {
    const response = yield call(restService, {
      api: `/catalog/superClasses/dueTo?dueTo=${dueTo}&restaurant=${restaurant}&channel=OTS&filter=state eq A&filter=channel eq CC`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(
        catalogActionCreators.getSuperCatalogsSuccess({
          stocks: response.data.stocks.rows,
        }),
      );
      resolve(response.data.superClasses.rows);
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncGetCatalogsRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/catalog/classes?filter=state eq A&filter=channel eq CC&sort=ordering asc`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(
        catalogActionCreators.getCatalogsSuccess({
          cataloges: response.data.rows,
        }),
      );
      resolve(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetItemsRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/catalog/items?filter=state eq A&filter=channel eq CC&sort=ordering asc`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(
        catalogActionCreators.getItemsSuccess({
          products: response.data.rows,
        }),
      );
      resolve(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetSingleItemRequest({ payload, resolve, reject }) {
  const { id } = payload;

  try {
    const response = yield call(restService, {
      api: `/catalog/items/${id}`,
      method: 'GET',
    });
    if (response.status === 200) {
      resolve(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetPricesRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/catalog/prices?filter=state eq A`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(
        catalogActionCreators.getPricesSuccess({
          prices: response.data.rows,
        }),
      );
      resolve(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetSizesRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/catalog/sizes?filter=state eq A&sort=ordering asc`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(
        catalogActionCreators.getSizesSuccess({
          sizes: response.data.rows,
        }),
      );
      resolve(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetItemGroupsRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/catalog/itemGroups?filter=state eq A`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(
        catalogActionCreators.getItemGroupsSuccess({
          productGroups: response.data.rows,
        }),
      );
      resolve(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetOptionGroupsRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/catalog/optionGroups?filter=state eq A&sort=ordering asc`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(
        catalogActionCreators.getOptionGroupsSuccess({
          optionGroups: response.data.rows,
        }),
      );
      resolve(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetOptionsRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/catalog/options?filter=state eq A&sort=ordering asc`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(
        catalogActionCreators.getOptionsSuccess({
          options: response.data.rows,
        }),
      );
      resolve(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetRecipesRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/catalog/recipes?filter=state eq A`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(
        catalogActionCreators.getRecipesSuccess({
          recipes: response.data.rows,
        }),
      );
      resolve(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetDayPartSuperCatalogsRequest({ payload, resolve, reject }) {
  const { daypart } = payload;
  try {
    const response = yield call(restService, {
      api: `/catalog/superClasses/daypart?filter=state eq A&filter=channel eq CC&daypart=${daypart}`,
      method: 'GET',
    });
    if (response.status === 200) {
      resolve(response.data);
    }
  } catch (e) {
    reject(e);
  }
}

export function* watchSetInitialValueRequest() {
  while (true) {
    const action = yield take(SET_INITIAL_CATALOG_VALUE);
    yield* asyncSetInitialValueRequest(action);
  }
}
export function* watchGetSuperCatalogsRequest() {
  while (true) {
    const action = yield take(GET_SUPER_CATALOGS_REQUEST);
    yield* asyncGetSuperCatalogsRequest(action);
  }
}
export function* watchGetScheduleSuperCatalogsRequest() {
  while (true) {
    const action = yield take(GET_SCHEDULE_SUPER_CATALOGS_REQUEST);
    yield* asyncGetScheduleSuperCatalogsRequest(action);
  }
}
export function* watchGetCatalogsRequest() {
  while (true) {
    const action = yield take(GET_CATALOGS_REQUEST);
    yield* asyncGetCatalogsRequest(action);
  }
}
export function* watchGetItemsRequest() {
  while (true) {
    const action = yield take(GET_ITEMS_REQUEST);
    yield* asyncGetItemsRequest(action);
  }
}
export function* watchGetSingleItemRequest() {
  while (true) {
    const action = yield take(GET_SINGLE_ITEM_REQUEST);
    yield* asyncGetSingleItemRequest(action);
  }
}
export function* watchGetPricesRequest() {
  while (true) {
    const action = yield take(GET_PRICES_REQUEST);
    yield* asyncGetPricesRequest(action);
  }
}
export function* watchGetSizesRequest() {
  while (true) {
    const action = yield take(GET_SIZES_REQUEST);
    yield* asyncGetSizesRequest(action);
  }
}
export function* watchGetItemGroupsRequest() {
  while (true) {
    const action = yield take(GET_ITEMS_GROUPS_REQUEST);
    yield* asyncGetItemGroupsRequest(action);
  }
}
export function* watchGetOptionGroupsRequest() {
  while (true) {
    const action = yield take(GET_OPTIONS_GROUPS_REQUEST);
    yield* asyncGetOptionGroupsRequest(action);
  }
}
export function* watchGetOptionsRequest() {
  while (true) {
    const action = yield take(GET_OPTIONS_REQUEST);
    yield* asyncGetOptionsRequest(action);
  }
}
export function* watchGetRecipesRequest() {
  while (true) {
    const action = yield take(GET_RECIPES_REQUEST);
    yield* asyncGetRecipesRequest(action);
  }
}
export function* watchGetDayPartSuperCatalogsRequest() {
  while (true) {
    const action = yield take(GET_DAY_PART_SUPER_CATALOGS_REQUEST);
    yield* asyncGetDayPartSuperCatalogsRequest(action);
  }
}

export default function*() {
  yield all([
    fork(watchSetInitialValueRequest),
    fork(watchGetSuperCatalogsRequest),
    fork(watchGetScheduleSuperCatalogsRequest),
    fork(watchGetCatalogsRequest),
    fork(watchGetItemsRequest),
    fork(watchGetSingleItemRequest),
    fork(watchGetPricesRequest),
    fork(watchGetSizesRequest),
    fork(watchGetItemGroupsRequest),
    fork(watchGetOptionGroupsRequest),
    fork(watchGetOptionsRequest),
    fork(watchGetRecipesRequest),
    fork(watchGetDayPartSuperCatalogsRequest),
  ]);
}
