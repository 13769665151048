import {
  GET_CATALOGS_SUCCESS,
  GET_ITEMS_GROUPS_SUCCESS,
  GET_ITEMS_SUCCESS,
  GET_OPTIONS_GROUPS_SUCCESS,
  GET_OPTIONS_SUCCESS,
  GET_PRICES_SUCCESS,
  GET_RECIPES_SUCCESS,
  GET_SIZES_SUCCESS,
  GET_SUPER_CATALOGS_SUCCESS,
  SET_INITIAL_CATALOG_VALUE_SUCCESS,
} from 'core/modules/catalog/actions';
import { initialState } from 'core/modules/initialState';

export default function catalog(state = initialState.catalog, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_CATALOG_VALUE_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case GET_SUPER_CATALOGS_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case GET_CATALOGS_SUCCESS: {
      return {
        ...state,
        cataloges: payload.cataloges,
      };
    }
    case GET_ITEMS_SUCCESS: {
      return {
        ...state,
        products: payload.products,
      };
    }
    case GET_PRICES_SUCCESS: {
      return {
        ...state,
        prices: payload.prices,
      };
    }
    case GET_SIZES_SUCCESS: {
      return {
        ...state,
        sizes: payload.sizes,
      };
    }
    case GET_ITEMS_GROUPS_SUCCESS: {
      return {
        ...state,
        productGroups: payload.productGroups,
      };
    }
    case GET_OPTIONS_GROUPS_SUCCESS: {
      return {
        ...state,
        optionGroups: payload.optionGroups,
      };
    }
    case GET_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: payload.options,
      };
    }
    case GET_RECIPES_SUCCESS: {
      return {
        ...state,
        recipes: payload.recipes,
      };
    }
    default: {
      return state;
    }
  }
}
