import { createAction } from 'redux-actions';
import { createPromiseAction } from 'core/modules/utils';

/**
 * Action Types
 */
export const SET_INITIAL_ADDRESS_VALUE = '@address/SET_INITIAL_ADDRESS_VALUE';
export const SET_INITIAL_ADDRESS_VALUE_SUCCESS = '@address/SET_INITIAL_ADDRESS_VALUE_SUCCESS';
export const GET_MY_ADDRESS_REQUEST = '@address/GET_MY_ADDRESS_REQUEST';
export const GET_MY_ADDRESS_SUCCESS = '@address/GET_MY_ADDRESS_SUCCESS';
export const GET_MY_ADDRESS_FAILURE = '@address/GET_MY_ADDRESS_FAILURE';
export const CREATE_MY_ADDRESS_REQUEST = '@address/CREATE_MY_ADDRESS_REQUEST';
export const CREATE_MY_ADDRESS_SUCCESS = '@address/CREATE_MY_ADDRESS_SUCCESS';
export const CREATE_MY_ADDRESS_FAILURE = '@address/CREATE_MY_ADDRESS_FAILURE';
export const UPDATE_MY_ADDRESS_REQUEST = '@address/UPDATE_MY_ADDRESS_REQUEST';
export const UPDATE_MY_ADDRESS_SUCCESS = '@address/UPDATE_MY_ADDRESS_SUCCESS';
export const UPDATE_MY_ADDRESS_FAILURE = '@address/UPDATE_MY_ADDRESS_FAILURE';
export const DELETE_MY_ADDRESS_REQUEST = '@address/DELETE_MY_ADDRESS_REQUEST';
export const DELETE_MY_ADDRESS_SUCCESS = '@address/DELETE_MY_ADDRESS_SUCCESS';
export const DELETE_MY_ADDRESS_FAILURE = '@address/DELETE_MY_ADDRESS_FAILURE';
/**
 * Action Creators
 */
export const addressActionCreators = {
  setInitialAddress: createPromiseAction(SET_INITIAL_ADDRESS_VALUE),
  setInitialAddressSuccess: createAction(SET_INITIAL_ADDRESS_VALUE_SUCCESS),
  getMyAddress: createPromiseAction(GET_MY_ADDRESS_REQUEST),
  getMyAddressSuccess: createAction(GET_MY_ADDRESS_SUCCESS),
  getMyAddressFailure: createAction(GET_MY_ADDRESS_FAILURE),
  createMyAddress: createPromiseAction(CREATE_MY_ADDRESS_REQUEST),
  createMyAddressSuccess: createAction(CREATE_MY_ADDRESS_SUCCESS),
  createMyAddressFailure: createAction(CREATE_MY_ADDRESS_FAILURE),
  updateMyAddress: createPromiseAction(UPDATE_MY_ADDRESS_REQUEST),
  updateMyAddressSuccess: createAction(UPDATE_MY_ADDRESS_SUCCESS),
  updateMyAddressFailure: createAction(UPDATE_MY_ADDRESS_FAILURE),
  deleteMyAddress: createPromiseAction(DELETE_MY_ADDRESS_REQUEST),
  deleteMyAddressSuccess: createAction(DELETE_MY_ADDRESS_SUCCESS),
  deleteMyAddressFailure: createAction(DELETE_MY_ADDRESS_FAILURE),
};
