import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connectAuth } from 'core';

class PrivateRoute extends React.PureComponent {
  render() {
    const { agent, ...props } = this.props;
    if (agent) {
      return <Route {...props} />;
    }
    return <Redirect to="/login" />;
  }
}

PrivateRoute.propTypes = {
  agent: PropTypes.object,
};

PrivateRoute.defaultProps = {
  agent: null,
};

const mapStateToProps = ({ auth }) => ({
  agent: auth.agent,
});
export default connectAuth(mapStateToProps, {})(PrivateRoute);
