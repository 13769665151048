import { all, call, fork, put, take } from 'redux-saga/effects';
import { restService } from 'utilities';
import { GET_VERSION_BACKEND_REQUEST, versionActionCreators } from './actions';

export function* asyncGetVersionRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/version`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      yield put(
        versionActionCreators.getVersionSuccess({
          version: response.data.version,
        }),
      );
      resolve(response.data);
    } else {
      yield put(
        versionActionCreators.getVersionReject({
          version: 'You need to login to see the current version',
        }),
      );
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}

export function* watchGetVersionRequest() {
  while (true) {
    const action = yield take(GET_VERSION_BACKEND_REQUEST);
    yield* asyncGetVersionRequest(action);
  }
}

export default function*() {
  yield all([fork(watchGetVersionRequest)]);
}
