import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Progress, Spin, Switch } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import NumberPicker from 'components/Basic/NumberPicker';
import { catalogActionCreators, connectAddress, connectCatalog, connectOrder, orderActionCreators } from 'core';
import { promisify } from 'utilities';
import { ArrowForward, ClearAllOutlined, CloseOutlined } from '@material-ui/icons';
import { getCurrency, getMappedRecipes, getPrice, getProductInfo } from 'utilities/common';
import emptyImg from 'assets/img/empty.svg';
import placeHolderImg from 'assets/img/placeholder.svg';
import BusytimeModal from 'components/Main/Order/BusytimeModal';
import toast from 'components/Basic/Toast';
import ProductCart from '../../Basic/ProductCart';

const antIcon = <Icon spin style={{ fontSize: 24 }} type="loading" />;

class Cart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contents: [],
      isLoading: false,
      isBusytimeModal: false,
      isLimitPrice: false,
    };
  }

  componentDidMount() {
    this.getContents();
  }

  getContents = () => {
    const { cart, prices } = this.props;
    const contents = cart.map(product => {
      const { aggregate, cookieNote, customized, item, selectedGroups, totalCount: quantity, size } = product;

      const options = [];
      Object.keys(selectedGroups).forEach(key => {
        if (!selectedGroups[key]) return;
        options.push(
          ...Object.keys(selectedGroups[key]).map(optionKey => {
            const optionCustomized = selectedGroups[key][optionKey].recipes
              ? selectedGroups[key][optionKey].recipes.filter(recipe => recipe.type === 'I')
              : [];
            const optionAggregate = selectedGroups[key][optionKey].recipes
              ? selectedGroups[key][optionKey].recipes.filter(recipe => recipe.type === 'A')
              : [];
            return {
              option: selectedGroups[key][optionKey].id,
              ingredients: getMappedRecipes(optionCustomized, prices),
              aggregates: getMappedRecipes(optionAggregate, prices),
              price: getPrice(prices, selectedGroups[key][optionKey].productCode),
              optionDetail: { ...selectedGroups[key][optionKey] },
            };
          }),
        );
      });
      return {
        item: item.id,
        size: size.id,
        quantity,
        agent: 0,
        cookingNote: cookieNote || undefined,
        ingredients: getMappedRecipes(customized, prices),
        aggregates: getMappedRecipes(aggregate, prices),
        options,
      };
    });
    this.setState({ contents });
    return contents;
  };

  handleTotalCount = (value, index) => {
    const { cart } = this.props;
    cart[index].totalCount = value;
    promisify(this.props.setInitialCatalog, { cart: [...cart] });
  };

  removeOrder = index => {
    const { cart } = this.props;
    cart.splice(index, 1);
    promisify(this.props.setInitialCatalog, { cart: [...cart] });
  };

  getProductInfo = data => {
    const { prices, sizes } = this.props;
    return getProductInfo(data, prices, sizes);
  };

  handleShowCheckout = () => {
    const { cart, cataloges, dueTo, selectedAddress } = this.props;
    this.setState({ isLoading: true });
    promisify(this.props.getScheduleSuperCatalogs, {
      dueTo,
      restaurant: selectedAddress.restaurant,
    })
      .then(res => {
        const unavailableProductNames = [];
        cart.forEach(({ item }) => {
          const selectedCatalogId = cataloges.find(c => `${c.id}` === `${item.classCode}`).superClassCode;
          if (!res.find(c => c.id === selectedCatalogId)) {
            unavailableProductNames.push(item.name);
          }
        });
        if (unavailableProductNames.length > 0) {
          this.setState({ isLoading: false });
          toast.error({
            title:
              this.props.intl.formatMessage({ id: 'One or more products are not available' }) +
              unavailableProductNames.join(', '),
          });
        } else {
          this.checkOrder();
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  checkOrder = () => {
    // eslint-disable-next-line no-unused-vars
    const { cart, dueTo, selectedAddress, cataloges, products, prices, sizes } = this.props;

    let totalAmount = 0;
    if (cart && cart.length !== 0) {
      cart.forEach(c => {
        totalAmount += (c.amount * c.totalCount) / 100;
      });
    }
    const contents = this.getContents();

    const params = {
      channel: 'OTS',
      customer: selectedAddress.customer,
      restaurant: selectedAddress.restaurant,
      deliveryAddress: selectedAddress,
      paymentAmount: Math.round(totalAmount * 100),
      purchaseAmount: Math.round(totalAmount * 100),
      type: 'S',
      dueTo: dueTo || undefined,
      contents: contents.map(c => {
        return {
          ...c,
          options: c.options.map(o => {
            return {
              option: o.option,
              aggregates: o.aggregates,
              ingredients: o.ingredients,
              price: o.price,
            };
          }),
        };
      }),
      ignoreMinAmount: this.state.isLimitPrice,
    };
    promisify(this.props.checkMyOrder, { params })
      .then(() => {
        this.setState({ isLoading: false });
        this.props.onContinue();
      })
      .catch(error => {
        this.setState({ isLoading: false });
        if (error) {
          if (error.status === 406) {
            if (error.data.type === 'MinimumPurchaseError') {
              toast.error({
                title: this.props.intl.formatMessage(
                  {
                    id: `${error.data.type} {Currency}{MinimumPurchase}`,
                  },
                  {
                    Currency: getCurrency().symbol,
                    MinimumPurchase: error.data.details[0].minimumPurchase / 100,
                  },
                ),
              });
            } else if (error.data.type === 'BusyServiceError') {
              this.setState({ isBusytimeModal: true });
            } else if (error.data.type === 'MaximumPurchaseError') {
              toast.error({
                title: this.props.intl.formatMessage(
                  {
                    id: `${error.data.type} {Currency}{MaximumPurchase}`,
                  },
                  {
                    Currency: getCurrency().symbol,
                    MaximumPurchase: error.data.details[0].maximumPurchase / 100,
                  },
                ),
              });
            } else {
              if (error.data.type === 'HasUnavailableProductError') {
                const { data } = error.data.details[0];
                if (data && data.length) {
                  const unavailableProductNames = data.map(({ description, name }) => name || description);
                  toast.error({
                    title:
                      this.props.intl.formatMessage({ id: 'One or more products are not available' }) +
                      unavailableProductNames.join(', '),
                  });
                  return;
                }
              }
              toast.error({
                title: this.props.intl.formatMessage({ id: `${error.data.type}` }),
              });
            }
          } else {
            toast.error({
              title: this.props.intl.formatMessage({ id: `${error.data.type}` }),
            });
          }
        }
      });
  };

  handleChangeLimitPrice = value => {
    this.setState({ isLimitPrice: value });
  };

  render() {
    const { cart, selectedAddress } = this.props;
    const { contents, isBusytimeModal, isLimitPrice } = this.state;

    let totalAmount = 0;
    let totalItems = 0;
    if (cart && cart.length !== 0) {
      cart.forEach(c => {
        totalAmount += (c.amount * c.totalCount) / 100;
        totalItems += c.totalCount;
      });
    }

    return (
      <Spin indicator={antIcon} spinning={this.state.isLoading}>
        <div className="cart_layout">
          {(!cart || cart.length === 0) && (
            <div className="empty_wrapper">
              <img alt="cart empty" src={emptyImg} />
              <p>
                <FormattedMessage id="Your bag is still empty, start select some products" />
              </p>
            </div>
          )}
          {cart && cart.length !== 0 && (
            <div className="cart_content_wrapper">
              <div className="order_progress_wrapper">
                <Progress percent={40} showInfo={false} />
              </div>
              <p className="cart_title">
                <FormattedMessage id="Order details" />
              </p>
              <div className="product_list scrollbar">
                {cart.map((product, index) => {
                  return (
                    <ProductCart
                      contents={contents}
                      getProductInfo={this.getProductInfo}
                      handleTotalCount={this.handleTotalCount}
                      index={index}
                      onSingleDetail={this.props.onSingleDetail}
                      product={product}
                      removeOrder={this.removeOrder}
                    />
                  );
                })}
              </div>
              <div className="cart_price_limit_wrapper">
                <Switch checked={isLimitPrice} onChange={this.handleChangeLimitPrice} />
                <FormattedMessage id="Minimum Amount" />
              </div>
              <div className="total_wrapper">
                <div>
                  <p className="p-large">
                    <FormattedMessage id="Total" />: {parseFloat(totalAmount).toFixed(2)}
                  </p>
                  <p className="p-medium">Por {totalItems} elementos</p>
                </div>
                <Button onClick={this.handleShowCheckout}>
                  <FormattedMessage id="Continue" />
                  <ArrowForward />
                </Button>
              </div>
            </div>
          )}
        </div>
        <BusytimeModal
          deliveryAddress={selectedAddress}
          isOpenModal={isBusytimeModal}
          onCancel={() => this.setState({ isBusytimeModal: false })}
          onOk={() => this.setState({ isBusytimeModal: false })}
          subtitle="Por favor, elige una nueva fecha y hora de entrega para el pedido, debido a que la hora previamente seleccionada ya no se encuentra disponible."
          title="Oops... la hora de entrega se ha modificado"
        />
      </Spin>
    );
  }
}

Cart.propTypes = {
  intl: intlShape.isRequired,
  cart: PropTypes.array,
  products: PropTypes.array,
  prices: PropTypes.array,
  sizes: PropTypes.array,
  cataloges: PropTypes.array,
  dueTo: PropTypes.string,
  selectedAddress: PropTypes.object,
  onSingleDetail: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  setInitialCatalog: PropTypes.func.isRequired,
  getScheduleSuperCatalogs: PropTypes.func.isRequired,
  checkMyOrder: PropTypes.func.isRequired,
};

Cart.defaultProps = {
  cart: [],
  products: [],
  sizes: [],
  prices: [],
  cataloges: [],
  dueTo: '',
  selectedAddress: {},
};

const mapStateToProps = ({ catalog, order, address }) => ({
  cart: catalog.cart,
  products: catalog.products,
  prices: catalog.prices,
  sizes: catalog.sizes,
  cataloges: catalog.cataloges,
  orderType: order.orderType,
  dueTo: order.dueTo,
  selectedAddress: address.selectedAddress,
});

const mapDispatchToProps = dispatch => {
  const { setInitialCatalog, getScheduleSuperCatalogs } = catalogActionCreators;
  const { checkMyOrder } = orderActionCreators;

  return bindActionCreators(
    {
      setInitialCatalog,
      getScheduleSuperCatalogs,
      checkMyOrder,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  withRouter,
  connectCatalog(mapStateToProps, mapDispatchToProps),
  connectOrder(mapStateToProps, mapDispatchToProps),
  connectAddress(mapStateToProps, undefined),
)(Cart);
