import { connect } from 'react-redux';
import { versionActionCreators } from 'core/modules/version/actions';

function mapStateToProps({ version }) {
    return {
        version,
    };
}

const mapDispatchToProps = versionActionCreators;

export function connectVersion(configMapStateToProps = mapStateToProps) {
    return connect(configMapStateToProps, mapDispatchToProps);
}
