import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ArrowForward } from '@material-ui/icons';
import {
  addressActionCreators,
  connectAddress,
  connectCustomer,
  connectOrder,
  connectRestaurant,
  restaurantActionCreators,
} from 'core';
import confirmImg from 'assets/img/confirm.svg';
import { promisify } from 'utilities';

class OrderConfirm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      restaurants: [],
    };
  }

  componentDidMount() {
    promisify(this.props.getRestaurantList, {})
      .then(res => {
        this.setState({ restaurants: res.data.rows });
      })
      .catch(e => {
        console.log('e', e);
      });
  }

  handleFinishOrder = () => {
    promisify(this.props.setInitialAddress, { selectedAddress: {} });
    this.props.history.push('/ots/order/new');
  };

  render() {
    const { restaurants } = this.state;
    const { customerList, selectedAddress, dueTo, order } = this.props;
    let customerInfo = {};
    if (customerList && customerList.length !== 0) {
      // eslint-disable-next-line prefer-destructuring
      customerInfo = customerList.filter(c => c.id === selectedAddress.customer)[0];
    }
    console.log(order);
    const now = moment().tz('America/Guatemala');
    const today = moment(now, 'DD').format('DD');
    const schedule = moment(dueTo)
      .tz('America/Guatemala')
      .format('DD');
    let restaurantInfo = {};
    if (selectedAddress && selectedAddress.restaurant) {
      restaurantInfo = restaurants.find(r => r.id === selectedAddress.restaurant) || {};
    }
    return (
      <div className="order_confirm_layout">
        <div className="order_confirm_wrapper">
          <img alt="confirm order" src={confirmImg} />
          <h3>
            Gracias {`${customerInfo.firstName || ''} ${customerInfo.lastName || ''}`}, tu pedido ha sido procesado con
            éxito
          </h3>
          <p className="p-medium">
            Será entregado “{today === schedule ? 'hoy' : schedule} ” a las{' '}
            {moment(dueTo)
              .tz('America/Guatemala')
              .format('HH:mm')}{' '}
            horas en “{`${selectedAddress.address || ''} ${selectedAddress.address || ''}`}”
          </p>
          <p className="p-medium">Referncia {order.id}</p>
          {order.paymentDetail && order.paymentDetail.authorizationCode && <p className="p-medium">No. Authorizacion {order.paymentDetail.authorizationCode}</p>}
          {restaurantInfo.description && dueTo && (
            <p className="p-medium store_service_time">
              {restaurantInfo.timeService === '00' ? (
                <span>
                  Lo sentimos, no tenemos servicio a domicilio para el restaurant seleccionado por el momento.
                </span>
              ) : (
                <>
                  Tiempo estimado de entrega en este momento del restaurante:{' '}
                  <span>{restaurantInfo.description || ''}</span>, es de{' '}
                  <span>{restaurantInfo.timeService} minutos</span>
                </>
              )}
            </p>
          )}
          <Button className="btn_finish" onClick={this.handleFinishOrder}>
            <FormattedMessage id="Finish order" />
            <ArrowForward />
          </Button>
          <Tooltip
            id="multi_order_tooltip"
            placement="bottom"
            title="Colocar nuevo pedido, al mismo cliente, misma dirección y mismo tiempo de entrega"
          >
            <NavLink to="/ots/order">
              <Button className="btn_multi_order">
                <FormattedMessage id="Multi-order" />
                <ArrowForward />
              </Button>
            </NavLink>
          </Tooltip>
        </div>
      </div>
    );
  }
}

OrderConfirm.propTypes = {
  history: PropTypes.object,
  selectedAddress: PropTypes.object,
  dueTo: PropTypes.string,
  order: PropTypes.object.isRequired,
  customerList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setInitialAddress: PropTypes.func.isRequired,
  getRestaurantList: PropTypes.func.isRequired,
};

OrderConfirm.defaultProps = {
  history: {},
  selectedAddress: {},
  dueTo: '',
  customerList: [],
};

const mapStateToProps = ({ address, order, customer }) => ({
  selectedAddress: address.selectedAddress,
  dueTo: order.dueTo,
  order: order.order,
  customerList: customer.list,
});

const mapDispatchToProps = dispatch => {
  const { setInitialAddress } = addressActionCreators;
  const { getRestaurantList } = restaurantActionCreators;

  return bindActionCreators(
    {
      setInitialAddress,
      getRestaurantList,
    },
    dispatch,
  );
};

export default compose(
  withRouter,
  connectCustomer(mapStateToProps, undefined),
  connectAddress(mapStateToProps, mapDispatchToProps),
  connectOrder(mapStateToProps, undefined),
  connectRestaurant(undefined, mapDispatchToProps),
)(OrderConfirm);
