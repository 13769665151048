/* eslint-disable no-unused-vars */
import { all, call, fork, put, take } from 'redux-saga/effects';
import {
  authActionCreators,
  HANDLE_AUTH_ERROR_REQUEST,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
} from 'core/modules/auth/actions';
import { restService } from 'utilities';
import toast from 'components/Basic/Toast';

export function* handleAuthErrorRequest({ payload, resolve, reject }) {
  const { response } = payload;
  if (response.result === 'error') {
    toast.error({
      title: 'Parece que algo salió mal, por favor, inténtalo de nuevo en un momento',
    });
    return;
  }
  try {
    if (
      (response.data && response.data.type === 'AuthenticationError') ||
      (response.error && response.error.message === 'Failed to fetch')
    ) {
      yield put(authActionCreators.logOut({}));
    }
  } catch (e) {
    console.log('e', e);
  }
}

export function* asyncLoginRequest({ payload, resolve, reject }) {
  const { eid, password } = payload;
  try {
    const response = yield call(restService, {
      api: `/auth/login/agent`,
      method: 'POST',
      params: {
        eid,
        password,
      },
    });
    if (response.status === 200) {
      yield put(authActionCreators.loginSuccess({ agent: response.data }));
      resolve(response);
    } else {
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncLogoutRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/auth/logout`,
      method: 'PUT',
      params: {
        channel: 'OTS',
      },
    });
    if (response.status === 200) {
      yield put(authActionCreators.logOutSuccess());
    }
  } catch (e) {
    reject(e);
  }
}

export function* watchHandleAuthErrorRequest() {
  while (true) {
    const action = yield take(HANDLE_AUTH_ERROR_REQUEST);
    yield* handleAuthErrorRequest(action);
  }
}

export function* watchLoginRequest() {
  while (true) {
    const action = yield take(LOGIN_REQUEST);
    yield* asyncLoginRequest(action);
  }
}

export function* watchLogoutRequest() {
  while (true) {
    const action = yield take(LOGOUT_REQUEST);
    yield* asyncLogoutRequest(action);
  }
}

export default function*() {
  yield all([fork(watchHandleAuthErrorRequest), fork(watchLoginRequest), fork(watchLogoutRequest)]);
}
