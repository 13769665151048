import React, {memo} from 'react';
import { Add, Remove } from '@material-ui/icons';
import PropTypes from 'prop-types';

function NumberPicker({ size, selectedNumber, maxvalue, onChange, ...props }) {
  const handleMinus = () => {
    if (selectedNumber !== 1) {
      onChange(selectedNumber - 1);
    }
  };

  const handlePlus = () => {
    if (maxvalue && selectedNumber + 1 > maxvalue) {
      return;
    }

    onChange(selectedNumber + 1);
  };

  return (
    <div className="number_picker_wrapper" {...props}>
      <div className={`number_wrapper ${size}`}>
        <div className="minus" onClick={handleMinus}>
          <Remove />
        </div>
        <p>{selectedNumber}</p>
        <div className="plus" onClick={handlePlus}>
          <Add />
        </div>
      </div>
    </div>
  );
}

NumberPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedNumber: PropTypes.number,
  size: PropTypes.string,
  maxvalue: PropTypes.number,
};

NumberPicker.defaultProps = {
  selectedNumber: 1,
  size: '',
  maxvalue: 0,
};

export default NumberPicker;
