import { createAction } from 'redux-actions';
import { createPromiseAction } from 'core/modules/utils';

/**
 * Action Types
 */
export const SET_INITIAL_ORDER_VALUE = '@order/SET_INITIAL_ORDER_VALUE';
export const SET_INITIAL_ORDER_VALUE_SUCCESS = '@order/SET_INITIAL_ORDER_VALUE_SUCCESS';
export const GET_MY_ORDER_REQUEST = '@order/GET_MY_ORDER_REQUEST';
export const GET_MY_ORDER_SUCCESS = '@order/GET_MY_ORDER_SUCCESS';
export const GET_MY_ORDER_FAILURE = '@order/GET_MY_ORDER_FAILURE';
export const GET_CHECK_MY_ORDER_REQUEST = '@order/GET_CHECK_MY_ORDER_REQUEST';
export const CREATE_MY_ORDER_REQUEST = '@order/CREATE_MY_ORDER_REQUEST';
export const CREATE_MY_ORDER_SUCCESS = '@order/CREATE_MY_ORDER_SUCCESS';
export const CREATE_MY_ORDER_FAILURE = '@order/CREATE_MY_ORDER_FAILURE';
export const CANCEL_MY_ORDER_REQUEST = '@order/CANCEL_MY_ORDER_REQUEST';
export const CANCEL_MY_ORDER_SUCCESS = '@order/CANCEL_MY_ORDER_SUCCESS';
export const CANCEL_MY_ORDER_FAILURE = '@order/CANCEL_MY_ORDER_FAILURE';
export const GET_SPECIAL_ORDERS_REQUEST = '@order/GET_SPECIAL_ORDERS_REQUEST';
export const GET_SPECIAL_ORDERS_SUCCESS = '@order/GET_SPECIAL_ORDERS_SUCCESS';
export const GET_SPECIAL_ORDERS_FAILURE = '@order/GET_SPECIAL_ORDERS_FAILURE';
export const UPDATE_ORDER_REQUEST = '@order/UPDATE_ORDER_REQUEST';

/**
 * Action Creators
 */
export const orderActionCreators = {
  setInitialOrder: createPromiseAction(SET_INITIAL_ORDER_VALUE),
  setInitialOrderSuccess: createAction(SET_INITIAL_ORDER_VALUE_SUCCESS),
  getMyOrder: createPromiseAction(GET_MY_ORDER_REQUEST),
  getMyOrderSuccess: createAction(GET_MY_ORDER_SUCCESS),
  getMyOrderFailure: createAction(GET_MY_ORDER_FAILURE),
  checkMyOrder: createPromiseAction(GET_CHECK_MY_ORDER_REQUEST),
  createMyOrder: createPromiseAction(CREATE_MY_ORDER_REQUEST),
  createMyOrderSuccess: createAction(CREATE_MY_ORDER_SUCCESS),
  createMyOrderFailure: createAction(CREATE_MY_ORDER_FAILURE),
  cancelMyOrder: createPromiseAction(CANCEL_MY_ORDER_REQUEST),
  cancelMyOrderSuccess: createAction(CANCEL_MY_ORDER_SUCCESS),
  cancelMyOrderFailure: createAction(CANCEL_MY_ORDER_FAILURE),
  getSpecialOrders: createPromiseAction(GET_SPECIAL_ORDERS_REQUEST),
  getSpecialOrdersSuccess: createAction(GET_SPECIAL_ORDERS_SUCCESS),
  getSpecialOrdersFailure: createAction(GET_SPECIAL_ORDERS_FAILURE),
  updateOrder: createPromiseAction(UPDATE_ORDER_REQUEST),
};
