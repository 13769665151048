import { createAction } from 'redux-actions';
import { createPromiseAction } from 'core/modules/utils';

/**
 * Action Types
 */
export const GET_VERSION_BACKEND_REQUEST = '@version/GET_VERSION_BACKEND_REQUEST';
export const GET_VERSION_BACKEND_SUCCESS = '@version/GET_VERSION_BACKEND_SUCCESS';
export const GET_VERSION_BACKEND_REJECT = '@version/GET_VERSION_BACKEND_REJECT';

/**
 * Action Creators
 */
export const versionActionCreators = {
  getVersion: createPromiseAction(GET_VERSION_BACKEND_REQUEST),
  getVersionSuccess: createAction(GET_VERSION_BACKEND_SUCCESS),
  getVersionReject: createAction(GET_VERSION_BACKEND_REJECT),
};
