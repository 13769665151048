/* eslint-disable react/no-unused-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Button, Divider, Form, Icon, Input, Progress, Select, Spin, TimePicker, Tooltip } from 'antd';
import { ArrowBack, ArrowDropDown, ArrowForward, DateRange, RemoveRedEye } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
  addressActionCreators,
  catalogActionCreators,
  connectAddress,
  connectCatalog,
  connectCustomer,
  connectOrder,
  connectRestaurant,
  customerActionCreators,
  orderActionCreators,
} from 'core';
import creditImg from 'assets/img/credit_card.svg';
import cashImg from 'assets/img/cash.svg';
import eye from 'assets/img/eye.svg';
import eye_block from 'assets/img/eye_block.svg';
import visaLink from 'assets/img/visa-link.svg';
import check from 'assets/img/check.svg';
import { addPriceToProducts, getCurrency, getMappedRecipes, getPrice, isPossibleGift } from 'utilities/common';
import { promisify } from 'utilities';
import moment from 'moment-timezone';
import { HOUR_FINISH_BREAKFAST, MINUTES_FINISH_BREAKFAST } from '../../../utilities/constants';
import toast from '../../Basic/Toast';

const { Option } = Select;

const antIcon = <Icon spin style={{ fontSize: 24 }} type="loading" />;

class CheckoutCC extends PureComponent {
  constructor(props) {
    super(props);
    this.days = [];
    this.expireMonths = [
      { value: 1, label: 'enero' },
      { value: 2, label: 'febrero' },
      { value: 3, label: 'marzo' },
      { value: 4, label: 'abril' },
      { value: 5, label: 'mayo' },
      { value: 6, label: 'junio' },
      { value: 7, label: 'julio' },
      { value: 8, label: 'agosto' },
      { value: 9, label: 'septiembre' },
      { value: 10, label: 'octubre' },
      { value: 11, label: 'noviembre' },
      { value: 12, label: 'diciembre' },
    ];
    this.cashAmountInput = null;
    this.expireYears = [];
    this.date = new Date();
    for (let i = this.date.getFullYear(); i <= this.date.getFullYear() + 20; i += 1) {
      this.expireYears.push({
        value: i,
        label: i,
      });
    }
    this.state = {
      selectedTime: moment(this.props.dueTo).format('HH:mm'),
      selectedTimeSlots: [],
      selectedDay: moment(this.props.dueTo).format('YYYY-MM-DD'),
      giftCard: '',
      linkId: '',
      paymentType: 'card',
      giftErrMsg: '',
      giftStatus: '',
      giftProducts: [],
      discountAmount: 0,
      contents: [],
      generatedVisaLink: '',
      linkIsCopied: false,
      visaLinkAuthorization: '',
      visaLinkAuthorizationButton: false,
      isLoading: false,
    };
  }

  componentWillMount() {
    const { products, cataloges } = this.props;
    let giftProducts = [];
    const giftCataloge = cataloges.find(s => s.name === 'Gift Card');
    if (giftCataloge) {
      giftProducts = products.filter(p => p.classCode === giftCataloge.id);
      this.setState({ giftProducts });
    }
    this.setState({ giftCard: '', discountAmount: 0, giftStatus: '' });
    promisify(this.props.setInitialOrder, {
      gif_card: '',
      discountAmount: 0,
    });
  }

  componentDidMount() {
    const { setFieldsValue } = this.props.form;
    setFieldsValue({ address: this.props.selectedAddress.id });

    this.getTimeSlots(this.props.selectedAddress);
    this.getContents();
  }

  getTimeSlots = address => {
    promisify(this.props.getRestaurantTimeSlots, {
      restaurant: address.restaurant,
      lat: this.props.selectedAddress.lat,
      long: this.props.selectedAddress.long,
    }).then(res => {
      if (res.timeSlots && Array.isArray(res.timeSlots)) {
        res.timeSlots.forEach(t => {
          // eslint-disable-next-line no-param-reassign
          t.time = moment(t.time)
            .add(15, 'minutes')
            .toDate();
        });
        const maxDueTo = moment(res.maxDueTo);
        const minDueTo = moment(res.minDueTo);
        const diffDays = maxDueTo.diff(minDueTo, 'days') + 1;
        this.days = [];
        const now = moment().tz('America/Guatemala');
        for (let i = 0; i < diffDays; i += 1) {
          const today = moment(now, 'YYYY-MM-DD');
          const changedDate = today.add('days', i);
          this.days.push({
            label: i === 0 ? 'Hoy' : changedDate.format('DD'),
            value: changedDate.format('YYYY-MM-DD'),
          });
        }
        if (this.days.length !== 0) {
          this.setState({
            timeSlots: res.timeSlots,
          });
          const todayTimeSlots = res.timeSlots.filter(
            t =>
              moment(t.time)
                .tz('America/Guatemala')
                .format('YYYY-MM-DD') === this.state.selectedDay,
          );
          if (todayTimeSlots.length !== 0) {
            this.setState({
              selectedTimeSlots: todayTimeSlots,
            });
          } else {
            this.setState({
              selectedTimeSlots: [],
            });
          }
        }
      }
    });
  };

  getContents = () => {
    const { cart, prices } = this.props;
    const contents = cart.map(product => {
      const { aggregate, cookieNote, customized, item, selectedGroups, totalCount: quantity, size } = product;

      const options = [];
      Object.keys(selectedGroups).forEach(key => {
        if (!selectedGroups[key]) return;
        options.push(
          ...Object.keys(selectedGroups[key]).map(optionKey => {
            const optionCustomized = selectedGroups[key][optionKey].recipes
              ? selectedGroups[key][optionKey].recipes.filter(recipe => recipe.type === 'I')
              : [];
            const optionAggregate = selectedGroups[key][optionKey].recipes
              ? selectedGroups[key][optionKey].recipes.filter(recipe => recipe.type === 'A')
              : [];
            return {
              option: selectedGroups[key][optionKey].id,
              ingredients: getMappedRecipes(optionCustomized, prices),
              aggregates: getMappedRecipes(optionAggregate, prices),
              price: getPrice(prices, selectedGroups[key][optionKey].productCode),
              optionDetail: { ...selectedGroups[key][optionKey] },
            };
          }),
        );
      });
      return {
        item: item.id,
        size: size.id,
        quantity,
        agent: 0,
        cookingNote: cookieNote || undefined,
        ingredients: getMappedRecipes(customized, prices),
        aggregates: getMappedRecipes(aggregate, prices),
        options,
      };
    });
    this.setState({ contents });
    return contents;
  };

  isRequireGiftCard = () => {
    const { giftProducts } = this.state;
    const { cart, prices, sizes } = this.props;
    const updatedProducts = addPriceToProducts(giftProducts, prices, sizes);

    return (
      cart.findIndex(
        c =>
          updatedProducts.findIndex(g => g.defaultCode === c.item.productCode) !== -1 ||
          updatedProducts.findIndex(g => g.defaultCode === (c.size && c.size.productCode)) !== -1,
      ) !== -1
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    const { gif_card } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (this.isRequireGiftCard() && !gif_card) {
        this.setState({ giftErrMsg: this.props.intl.formatMessage({ id: 'Gift card is required!' }) });
        return;
      }
      if (!err) {
        this.setState({ giftErrMsg: '' });
        this.props.onContinue({ ...values, linkId: this.state.linkId, paymentType: this.state.paymentType });
      }
    });
  };

  handleChange = e => {
    this.setState({ giftCard: e.target.value });
  };

  handleCheckGiftCard = () => {
    const { gif_card } = this.props.form.getFieldsValue(['gif_card']);
    const { cart, gif_card: giftCard } = this.props;

    if (giftCard) {
      this.setState({ giftErrMsg: this.props.intl.formatMessage({ id: 'Gift card is already added' }) });
      return;
    }

    if (gif_card && (gif_card.length < 10 || gif_card.length > 12)) {
      this.setState({
        giftErrMsg: this.props.intl.formatMessage({ id: 'Gift card code should be ten or twelve digits!' }),
      });
      return;
    }
    promisify(this.props.checkValidateGiftCard, {
      giftCard: gif_card,
    })
      .then(res => {
        if (res && res.data && res.data.resp) {
          if (res.data.resp.codigo === '0') {
            this.setState({
              giftErrMsg: res.data.resp.mensaje || this.props.intl.formatMessage({ id: 'Gift card code is invalid' }),
              giftStatus: 'invalid',
            });
          } else if (res.data.resp.cod_producto && !isPossibleGift(cart, res.data.resp.cod_producto)) {
            this.setState({
              giftErrMsg: this.props.intl.formatMessage({ id: 'Cart does not include gift product' }),
              giftStatus: 'invalid',
            });
          } else {
            promisify(this.props.setInitialOrder, {
              gif_card,
              discountAmount: +res.data.resp.monto,
            });
            this.setState({
              giftErrMsg: '',
              discountAmount: +res.data.resp.monto,
              giftCard: gif_card,
              giftStatus: 'valid',
            });
          }
        }
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  handleVisaLinkCheck = async () => {
    this.setState({ visaLinkAuthorizationButton: true });
    try {
      await promisify(this.props.customerVisaLinkCheck, { id: this.state.linkId })
        .then(res => {
          if (res.data.authorizationCode) {
            this.setState({ visaLinkAuthorization: res.data.authorizationCode });
          }
        })
        .catch(err => console.log(err));
    } catch (e) {}
  };

  validateCardNumber = (rule, value, callback) => {
    const regex = new RegExp('\\d{4} \\d{4} \\d{4} \\d{4}');
    if (!regex.test(value)) {
      callback('El número de tarjeta de crédito debe contener 16 dígitos');
    } else {
      callback();
    }
  };

  handleChangePaymentMethod = type => {
    const { cart, form } = this.props;
    this.setState({ paymentType: type });
    let totalAmount = 0;

    if (cart && cart.length !== 0 && type === 'cash') {
      cart.forEach(c => {
        totalAmount += (c.amount * c.totalCount) / 100;
      });
      setTimeout(() => {
        form.setFieldsValue({ cash_amount: 100 * (parseInt(totalAmount / 100, 10) + 1) });
      }, 500);
    }
  };

  handleSelectAddress = addressId => {
    const { cart, cataloges, dueTo } = this.props;
    this.setState({ isLoading: true });
    const address = this.props.addressList.filter(item => item.id === addressId)[0];
    promisify(this.props.getScheduleSuperCatalogs, {
      dueTo,
      restaurant: address.restaurant,
    })
      .then(res => {
        const unavailableProductNames = [];
        cart.forEach(({ item }) => {
          const selectedCatalogId = cataloges.find(c => `${c.id}` === `${item.classCode}`).superClassCode;
          if (!res.find(c => c.id === selectedCatalogId)) {
            unavailableProductNames.push(item.name);
          }
        });
        this.setState({ isLoading: false });
        if (unavailableProductNames.length > 0) {
          toast.error({
            title:
              this.props.intl.formatMessage({ id: 'One or more products are not available' }) +
              unavailableProductNames.join(', '),
          });
        } else {
          promisify(this.props.setInitialAddress, { selectedAddress: address });
          this.getTimeSlots(address);
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleChangeDays = value => {
    const { timeSlots } = this.state;
    const selectedTimeSlots = timeSlots.filter(
      t =>
        moment(t.time)
          .tz('America/Guatemala')
          .format('YYYY-MM-DD') === value,
    );
    const selectedTime =
      selectedTimeSlots.length === 0
        ? ''
        : moment(
            selectedTimeSlots.filter(
              item =>
                moment(item.time)
                  .tz('America/Guatemala')
                  .format('HH:mm') === this.state.selectedTime,
            )[0].time,
          )
            .tz('America/Guatemala')
            .format('HH:mm');

    const dueTo = moment(`${value} ${selectedTime}`)
      .tz('America/Guatemala')
      .toString();

    promisify(this.props.setInitialOrder, { dueTo });
    this.setState({
      timeSlots,
      selectedTimeSlots,
      selectedDay: value,
      selectedTime,
    });
  };

  handleChangeTime = (time, timeString) => {
    const { selectedDay } = this.state;
    this.setState({ selectedTime: timeString });
    const dueTo = moment(`${selectedDay} ${timeString}`)
      .tz('America/Guatemala')
      .toString();
    promisify(this.props.setInitialOrder, { dueTo });
  };

  getDueTo = () => {
    const { selectedDay, selectedTime } = this.state;
    const dueTo = moment(`${selectedDay} ${selectedTime}`)
      .tz('America/Guatemala')
      .toString();
    return dueTo;
  };

  getPartDay = date => {
    const dueToHours = new Date(date).getHours();
    const duoToMinutes = new Date(date).getMinutes();
    if (
      dueToHours < HOUR_FINISH_BREAKFAST ||
      (dueToHours === HOUR_FINISH_BREAKFAST && duoToMinutes < MINUTES_FINISH_BREAKFAST)
    ) {
      return 'breakfasts';
    }
    return 'dinner';
  };

  generateVisaLink = async () => {
    const { cart, selectedAddress } = this.props;
    const { discountAmount } = this.state;
    let totalAmount = 0;
    if (cart && cart.length !== 0) {
      cart.forEach(c => {
        totalAmount += (c.amount * c.totalCount) / 100;
      });
    }
    this.setState({ isLoading: true });
    try {
      const { response, linkId } = await promisify(this.props.generateVisaLink, {
        customerId: selectedAddress.customer,
        amount: Math.max(totalAmount - discountAmount, 0),
      });
      this.setState({ isLoading: false, generatedVisaLink: response.data[0].url, linkId, linkIsCopied: false });
    } catch (e) {
      this.setState({ generatedVisaLink: '', isLoading: false });
      console.log(e);
    }
  };

  copyVisaLink = async () => {
    const { generatedVisaLink } = this.state;
    await navigator.clipboard.writeText(generatedVisaLink);
    this.setState({ linkIsCopied: true });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      paymentType,
      giftCard,
      giftErrMsg,
      discountAmount,
      giftStatus,
      selectedDay,
      selectedTimeSlots,
      selectedTime,
      isLoading,
      generatedVisaLink,
      linkIsCopied,
    } = this.state;
    const { cart, paymentId, restaurantInfo: restaurant, dueTo } = this.props;
    let totalAmount = 0;
    if (cart && cart.length !== 0) {
      cart.forEach(c => {
        totalAmount += (c.amount * c.totalCount) / 100;
      });
    }
    const disabledHours = [];

    const disabledMinutes = [];
    let startHour = -1;
    let endHour = -1;
    let startMinute = -1;
    if (selectedTimeSlots.length !== 0) {
      startHour = moment(selectedTimeSlots[0].time)
        .tz('America/Guatemala')
        .format('H');
      endHour = moment(selectedTimeSlots[selectedTimeSlots.length - 1].time)
        .tz('America/Guatemala')
        .format('H');
      startMinute = moment(selectedTimeSlots[0].time)
        .tz('America/Guatemala')
        .format('m');
      for (let i = 0; i < 24; i += 1) {
        if (i < parseInt(startHour, 10) || i > parseInt(endHour, 10)) {
          disabledHours.push(i);
        }
      }
      for (let i = 0; i < 60; i += 15) {
        if (i < parseInt(startMinute, 10)) {
          disabledMinutes.push(i);
        }
      }
    }
    const country = process.env.REACT_APP_COUNTRY || 'gt';

    return (
      <Spin indicator={antIcon} spinning={isLoading}>
        <div className="checkout_CC_layout step-fade-in">
          <div className="checkout_CC_wrapper">
            <div className="order_progress_wrapper">
              <Progress percent={80} showInfo={false} />
            </div>
            <div className="back_btn_wrapper">
              <div className="back_btn" onClick={this.props.onBack}>
                <ArrowBack />
              </div>
              <h3>
                <FormattedMessage id="Order details" />
              </h3>
            </div>
            <Form onSubmit={this.handleSubmit}>
              <Divider className="summary_tooltip_wrapper" orientation="center">
                <Tooltip
                  placement="top"
                  title={
                    <div style={{ width: 273, padding: '17px' }}>
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          color: 'white',
                          padding: '15px 0px',
                          marginBottom: 29,
                          borderBottom: '1px solid #434343',
                        }}
                      >
                        Resumen de pedido
                      </p>
                      {cart && cart.length !== 0 && (
                        <div className="cart_content_wrapper">
                          <div className="product_list scrollbar">
                            {cart.map((product, index) => {
                              return (
                                <div className="product" key={index} style={{ marginBottom: 14 }}>
                                  <div className="product_detail">
                                    <p
                                      className="name"
                                      dangerouslySetInnerHTML={{ __html: product.item.name }}
                                      style={{ textAlign: 'left', fontSize: 12, fontWeight: 'bold' }}
                                    />
                                    {product.amount && (
                                      <p
                                        className="quantity"
                                        style={{ textAlign: 'left', fontSize: 11, fontWeight: 500, color: '#A3ACC4' }}
                                      >
                                        {`${getCurrency().symbol}${parseFloat(product.amount / 100).toFixed(2)} x ${
                                          product.totalCount
                                        } = ${getCurrency().symbol}${(product.amount * product.totalCount) / 100}`}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div
                            className="total_wrapper"
                            style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}
                          >
                            <div>
                              <p className="p-large" style={{ textAlign: 'left', fontSize: 12, fontWeight: 'bold' }}>
                                Total del pedido:{' '}
                              </p>
                              <p className="p-medium" style={{ textAlign: 'left', fontSize: 16, fontWeight: 'bold' }}>
                                {`${getCurrency().symbol}`}
                                {parseFloat(totalAmount).toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  }
                >
                  <div className="summary_tooltip">
                    <FormattedMessage id="See order summary" />
                    <RemoveRedEye />
                  </div>
                </Tooltip>
              </Divider>
              <Divider orientation="left">
                <div>
                  <FormattedMessage id="Payment method" />
                </div>
              </Divider>
              {discountAmount >= totalAmount && !giftCard && (
                <p className="gift-card-cover">
                  La tarjeta de regalo cubre el 100% del total del pedido, no es necesario un método de pago.
                </p>
              )}
              {((discountAmount < totalAmount && giftStatus === 'valid') || !giftCard) && (
                <div className="payment_method_wrapper">
                  <div
                    className={this.state.paymentType === 'card' ? ' payment_method active' : 'payment_method'}
                    onClick={() => this.handleChangePaymentMethod('card')}
                  >
                    <img alt="payment img" src={creditImg} />
                    <p className="p-small">
                      <FormattedMessage id="Credit or debit card" />
                    </p>
                  </div>
                  <div
                    className={this.state.paymentType === 'cash' ? ' payment_method active' : 'payment_method'}
                    onClick={() => this.handleChangePaymentMethod('cash')}
                  >
                    <img alt="payment img" src={cashImg} />
                    <p className="p-small">
                      <FormattedMessage id="Cash on delivery" />
                    </p>
                  </div>
                  {country === 'gt' && (
                    <div
                      className={this.state.paymentType === 'visa-link' ? ' payment_method active' : 'payment_method'}
                      onClick={() => this.handleChangePaymentMethod('visa-link')}
                    >
                      <img alt="payment img" src={visaLink} />
                      <p className="p-small">
                        <FormattedMessage id="VISA_LINK" />
                      </p>
                    </div>
                  )}
                </div>
              )}
              {((discountAmount < totalAmount && giftStatus === 'valid') || !giftCard) && (
                <div className="checkout_CC_detail scrollbar">
                  {paymentType === 'cash' && (
                    <div className="cash_input_wrapper">
                      <p className="p-small checkout_title">
                        <FormattedMessage id="Cash payment details, how much will you pay?" />
                      </p>
                      <Form.Item>
                        {getFieldDecorator('cash_amount', {
                          rules: [
                            {
                              required: true,
                              message: this.props.intl.formatMessage({ id: 'Please input your cash amount!' }),
                            },
                          ],
                        })(
                          <Input
                            min={0}
                            placeholder={this.props.intl.formatMessage({ id: 'Enter the amount in cash' })}
                            ref={input => {
                              this.cashAmountInput = input;
                            }}
                            type="number"
                          />,
                        )}
                      </Form.Item>
                      {!this.props.form.getFieldValue('cash_amount') && (
                        <p className="cash_note">
                          <FormattedMessage id="Enter the amount to calculate the change" />
                        </p>
                      )}
                      {this.props.form.getFieldValue('cash_amount') &&
                        discountAmount < totalAmount &&
                        this.props.form.getFieldValue('cash_amount') > totalAmount - discountAmount && (
                          <p className="cash_note">
                            Pagarás con {getCurrency().symbol}
                            {parseFloat(this.props.form.getFieldValue('cash_amount')).toFixed(2)}, tu cambio será de{' '}
                            {getCurrency().symbol}
                            {parseFloat(
                              Number(this.props.form.getFieldValue('cash_amount')) + discountAmount - totalAmount,
                            ).toFixed(2)}
                          </p>
                        )}
                    </div>
                  )}
                  {paymentType === 'card' && (
                    <div className="card_input_wrapper">
                      <p className="p-small checkout_title">
                        <FormattedMessage id="Card details" />
                      </p>
                      {paymentId === -1 && (
                        <Form.Item>
                          {getFieldDecorator('card_number', {
                            rules: [
                              {
                                required: true,
                                message: this.props.intl.formatMessage({ id: 'Please input your card number!' }),
                              },
                              {
                                validator: this.validateCardNumber,
                              },
                            ],
                          })(
                            <NumberFormat
                              format="#### #### #### ####"
                              placeholder={this.props.intl.formatMessage({ id: 'Card number' })}
                            />,
                          )}
                        </Form.Item>
                      )}
                      {paymentId === -1 && (
                        <div className="expire_select_wrapper">
                          <Form.Item>
                            {getFieldDecorator('card_month', {
                              rules: [
                                {
                                  required: true,
                                  message: this.props.intl.formatMessage({ id: 'Expire month is required!' }),
                                },
                              ],
                            })(
                              <Select
                                className="expire_month"
                                placeholder={this.props.intl.formatMessage({ id: 'Expiration month' })}
                                suffixIcon={<ArrowDropDown />}
                              >
                                {this.expireMonths
                                  .filter(({ value }) => {
                                    const { form } = this.props;
                                    const year = form.getFieldValue('card_year');
                                    return year === this.date.getFullYear() ? this.date.getMonth() < value : value;
                                  })
                                  .map(item => (
                                    <Option key={Math.random()} value={item.value}>
                                      {item.label}
                                    </Option>
                                  ))}
                              </Select>,
                            )}
                          </Form.Item>
                          <Form.Item>
                            {getFieldDecorator('card_year', {
                              rules: [
                                {
                                  required: true,
                                  message: this.props.intl.formatMessage({ id: 'Expire year is required!' }),
                                },
                              ],
                            })(
                              <Select
                                className="expire_year"
                                placeholder={this.props.intl.formatMessage({ id: 'Expiration year' })}
                                suffixIcon={<ArrowDropDown />}
                              >
                                {this.expireYears
                                  .filter(({ value }) => {
                                    const { form } = this.props;
                                    const month = form.getFieldValue('card_month');
                                    // eslint-disable-next-line no-nested-ternary
                                    return value === this.date.getFullYear()
                                      ? month
                                        ? month > this.date.getMonth()
                                        : value
                                      : value;
                                  })
                                  .map(item => (
                                    <Option key={Math.random()} value={item.value}>
                                      {item.label}
                                    </Option>
                                  ))}
                              </Select>,
                            )}
                          </Form.Item>
                        </div>
                      )}
                    </div>
                  )}
                  {paymentType === 'visa-link' && (
                    <div className="visa_link_wrapper">
                      <div className="visa_link_generate">
                        <Button
                          className={`visa_link_button ${generatedVisaLink ? 'success' : ''}`}
                          onClick={this.generateVisaLink}
                        >
                          <FormattedMessage id="GenerateLink" />
                        </Button>
                        {generatedVisaLink ? (
                          <Button
                            className={`visa_link_button ${linkIsCopied ? 'success' : ''}`}
                            onClick={this.copyVisaLink}
                          >
                            <FormattedMessage id="CopyLink" />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="visa_link_show_auth">
                        {generatedVisaLink && (
                          <Button
                            className={`visa_link_show_auth_button ${
                              this.state.visaLinkAuthorization !== '' ? 'active' : ''
                            }`}
                            onClick={this.handleVisaLinkCheck}
                          >
                            <span>Mostrar autorización</span>{' '}
                            <img alt="" src={this.state.visaLinkAuthorization !== '' ? eye_block : eye} />
                          </Button>
                        )}
                        {this.state.visaLinkAuthorizationButton && (
                          <>
                            {this.state.visaLinkAuthorization === '' && <span>El pedido aún no ha sido pagado</span>}
                            {this.state.visaLinkAuthorization !== '' && (
                              <div className="visa_link_show_auth_success">
                                <img alt="" src={check} />
                                <span>{this.state.visaLinkAuthorization}</span>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <Divider orientation="left">
                <div>
                  <FormattedMessage id="Gift card" />
                </div>
              </Divider>
              <div className="gift_card_wrapper">
                <Form.Item>
                  <p className="p-medium gift_card_number">
                    <FormattedMessage id="Card number" />
                  </p>
                  <div className="gift_card_input">
                    {getFieldDecorator('gif_card', {
                      rules: [],
                    })(
                      <Input
                        onChange={this.handleChange}
                        placeholder={this.props.intl.formatMessage({ id: 'Card number' })}
                        type="number"
                        value={giftCard}
                      />,
                    )}
                    <div className="gift-card-btn" onClick={this.handleCheckGiftCard}>
                      {this.props.intl.formatMessage({ id: 'Validate' })}
                    </div>
                  </div>
                </Form.Item>
                {giftErrMsg ? <p className="error_msg">{giftErrMsg}</p> : null}
                {this.props.gif_card ? (
                  <div className="gift-card-info">
                    <Icon
                      onClick={() => {
                        promisify(this.props.setInitialOrder, {
                          gif_card: '',
                          discountAmount: 0,
                        });
                        this.setState({ discountAmount: 0, giftStatus: '' });
                      }}
                      type="close"
                    />
                    <p>
                      Tarjeta de regalo aplicada por monto de: {getCurrency().symbol}
                      {`${parseFloat(discountAmount).toFixed(2)}`}
                    </p>
                  </div>
                ) : null}
              </div>
              <Divider orientation="left">
                <div>
                  <FormattedMessage id="Delivery confirmation" />
                </div>
              </Divider>
              <div className="option-wrapper">
                <Form.Item>
                  {getFieldDecorator('address', {
                    rules: [],
                  })(
                    <Select
                      className="select-address"
                      defaultValue={this.props.selectedAddress.id}
                      onSelect={this.handleSelectAddress}
                      suffixIcon={<ArrowDropDown />}
                      value={this.props.selectedAddress.id}
                    >
                      {this.props.addressList
                        .filter(item => item.restaurant || item.belongsToDeliveryArea)
                        .map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.address}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                {restaurant.description && dueTo && (
                  <p className="p-small store_service_time">
                    {restaurant.timeService === '00' ? (
                      <span>
                        Lo sentimos, no tenemos servicio a domicilio para el restaurant seleccionado por el momento.
                      </span>
                    ) : (
                      <>
                        Tiempo estimado de entrega en este momento del restaurante:{' '}
                        <span>{restaurant.description || ''}</span>, es de <span>{restaurant.timeService} minutos</span>
                      </>
                    )}
                  </p>
                )}
                <div className="delivery_time_wrapper">
                  <div className="dropdown_wrapper">
                    <Select
                      className="date_list"
                      defaultValue={selectedDay}
                      onSelect={this.handleChangeDays}
                      suffixIcon={<DateRange />}
                      value={selectedDay}
                    >
                      {this.days.map(item => (
                        <Option key={item.label} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                    <TimePicker
                      className="time_list"
                      disabled={selectedTimeSlots.length === 0}
                      disabledHours={() => {
                        return disabledHours;
                      }}
                      disabledMinutes={hour => {
                        if (startHour && hour === parseInt(startHour, 10)) {
                          return disabledMinutes;
                        }
                        return [];
                      }}
                      format="HH:mm"
                      hideDisabledOptions
                      minuteStep={15}
                      onChange={this.handleChangeTime}
                      value={selectedTime ? moment(`${selectedTime}`, 'HH:mm').tz('America/Guatemala') : null}
                    />
                  </div>
                </div>
              </div>
              <div className="total_wrapper">
                <div>
                  <p className="p-large">
                    <FormattedMessage id="Total" />: {getCurrency().symbol}
                    {parseFloat(discountAmount ? Math.max(totalAmount - discountAmount, 0) : totalAmount).toFixed(2)}
                  </p>
                  {discountAmount && (
                    <p className="p-medium">
                      Total sin descuento: {getCurrency().symbol}
                      {parseFloat(totalAmount).toFixed(2)}
                    </p>
                  )}
                </div>
                <Button
                  disabled={
                    !this.props.selectedAddress.restaurant ||
                    !selectedDay ||
                    !selectedTime ||
                    !selectedTimeSlots.length ||
                    restaurant.timeService === '00' ||
                    (paymentType === 'cash' &&
                      discountAmount < totalAmount &&
                      (!this.props.form.getFieldValue('cash_amount') ||
                        this.props.form.getFieldValue('cash_amount') < totalAmount - discountAmount)) ||
                    (paymentType === 'visa-link' && this.state.linkId === '') ||
                    (paymentType === 'card' &&
                      discountAmount < totalAmount &&
                      paymentId === -1 &&
                      (!this.props.form.getFieldValue('card_number') ||
                        !this.props.form.getFieldValue('card_month') ||
                        !this.props.form.getFieldValue('card_year'))) ||
                    (giftCard && giftErrMsg)
                  }
                  htmlType="submit"
                >
                  <div>
                    <p>
                      <FormattedMessage id="Continue" />
                    </p>
                  </div>
                  <ArrowForward />
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}

CheckoutCC.propTypes = {
  form: PropTypes.object,
  cart: PropTypes.array,
  products: PropTypes.array,
  cataloges: PropTypes.array,
  sizes: PropTypes.array,
  prices: PropTypes.array,
  paymentMethods: PropTypes.array,
  paymentId: PropTypes.number,
  errMsg: PropTypes.string,
  intl: intlShape.isRequired,
  gif_card: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  handlePaymentSelect: PropTypes.func.isRequired,
  deletePayment: PropTypes.func.isRequired,
  checkValidateGiftCard: PropTypes.func.isRequired,
  setInitialOrder: PropTypes.func.isRequired,
  getRestaurantTimeSlots: PropTypes.func.isRequired,
  getScheduleSuperCatalogs: PropTypes.func.isRequired,
  addressList: PropTypes.array,
  selectedAddress: PropTypes.object,
  restaurantInfo: PropTypes.number,
  dueTo: PropTypes.string,
  setInitialAddress: PropTypes.func.isRequired,
  customerVisaLinkCheck: PropTypes.func.isRequired,
  generateVisaLink: PropTypes.func.isRequired,
};

CheckoutCC.defaultProps = {
  form: {},
  cart: [],
  products: [],
  cataloges: [],
  prices: [],
  sizes: [],
  paymentMethods: [],
  paymentId: -1,
  errMsg: '',
  gif_card: '',
  addressList: [],
  selectedAddress: {},
  restaurantInfo: -1,
  dueTo: '',
};

const mapStateToProps = ({ catalog, order, address, customer }) => ({
  cart: catalog.cart,
  products: catalog.products,
  cataloges: catalog.cataloges,
  prices: catalog.prices,
  sizes: catalog.sizes,
  selectedAddress: address.selectedAddress,
  gif_card: order.gif_card,
  discountAmount: order.discountAmount,
  addressList: customer.addressList,
  dueTo: order.dueTo,
  partDay: order.partDay,
});

const mapDispatchToProps = dispatch => {
  const { getScheduleSuperCatalogs } = catalogActionCreators;
  const { setInitialAddress } = addressActionCreators;

  const { checkValidateGiftCard, generateVisaLink, customerVisaLinkCheck } = customerActionCreators;
  const { setInitialOrder } = orderActionCreators;

  return bindActionCreators(
    {
      customerVisaLinkCheck,
      generateVisaLink,
      checkValidateGiftCard,
      setInitialOrder,
      setInitialAddress,
      getScheduleSuperCatalogs,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  Form.create({ name: 'billing_form' }),
  connectCatalog(mapStateToProps, mapDispatchToProps),
  connectCustomer(mapStateToProps, mapDispatchToProps),
  connectOrder(mapStateToProps, mapDispatchToProps),
  connectAddress(mapStateToProps, mapDispatchToProps),
  connectCustomer(mapStateToProps, mapDispatchToProps),
  connectRestaurant(undefined, mapDispatchToProps),
  withRouter,
)(CheckoutCC);
