/* eslint-disable no-unused-vars */
import { all, call, fork, put, take } from 'redux-saga/effects';
import { authActionCreators } from 'core/modules/auth/actions';
import {
  addressActionCreators,
  CREATE_MY_ADDRESS_REQUEST,
  DELETE_MY_ADDRESS_REQUEST,
  GET_MY_ADDRESS_REQUEST,
  SET_INITIAL_ADDRESS_VALUE,
  UPDATE_MY_ADDRESS_REQUEST,
} from 'core/modules/address/actions';

import { restService } from 'utilities';

export function* asyncSetInitialValueRequest({ payload, resolve, reject }) {
  try {
    yield put(addressActionCreators.setInitialAddressSuccess({ ...payload }));
    resolve(true);
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetMyAddressRequest({ payload, resolve, reject }) {
  const { id } = payload;
  try {
    const response = yield call(restService, {
      api: `/customers/${id}/addresses?pendingOrders=true`,
      method: 'GET',
    });
    if (response.status === 200) {
      yield put(addressActionCreators.getMyAddressSuccess({ list: response.data.rows }));
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncHandleMyAddressRequest({ payload, resolve, reject }) {
  const {
    id,
    customer,
    name,
    address,
    googleAddress,
    type,
    long,
    lat,
    description,
    reference,
    orderedAt,
    department,
    municipality,
    zone,
    restaurant,
    state,
    postalCode,
    addressCity,
    addressState,
    addressCountry,
  } = payload;
  try {
    const response = yield call(restService, {
      api: id ? `/customers/addresses/${id}?force=true` : '/customers/addresses?force=true',
      method: id ? 'PUT' : 'POST',
      params: {
        customer: !customer ? undefined : customer,
        name: !name ? undefined : name.trim(),
        address: !address ? undefined : address.trim(),
        googleAddress: !googleAddress ? undefined : googleAddress.trim(),
        type: !type ? undefined : type,
        long: !long ? undefined : long,
        lat: !lat ? undefined : lat,
        description: !description ? undefined : description.trim(),
        reference: !reference ? undefined : reference.trim(),
        orderedAt: !orderedAt ? undefined : orderedAt,
        department: !department ? undefined : department,
        municipality: !municipality ? undefined : municipality,
        zone: !zone ? undefined : zone,
        restaurant: !restaurant ? undefined : restaurant,
        state: !state ? undefined : state,
        postalCode: !postalCode ? undefined : postalCode.long_name,
        addressCity: !addressCity ? undefined : addressCity,
        addressState: !addressState ? undefined : addressState,
        addressCountry: !addressCountry ? undefined : addressCountry,
      },
    });
    if (response.status === 201 || response.status === 202) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncDeleteMyAddressRequest({ payload, resolve, reject }) {
  const { id } = payload;

  try {
    const response = yield call(restService, {
      api: `/customers/addresses/${id}`,
      method: 'DELETE',
      params: {},
    });
    if (response.status === 202) {
      yield put(addressActionCreators.getMyAddress({ id: 0 }, resolve, reject));
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* watchSetInitialValueRequest() {
  while (true) {
    const action = yield take(SET_INITIAL_ADDRESS_VALUE);
    yield* asyncSetInitialValueRequest(action);
  }
}

export function* watchGetMyAddressRequest() {
  while (true) {
    const action = yield take(GET_MY_ADDRESS_REQUEST);
    yield* asyncGetMyAddressRequest(action);
  }
}

export function* watchCreateMyAddressRequest() {
  while (true) {
    const action = yield take(CREATE_MY_ADDRESS_REQUEST);
    yield* asyncHandleMyAddressRequest(action);
  }
}

export function* watchUpdateMyAddressRequest() {
  while (true) {
    const action = yield take(UPDATE_MY_ADDRESS_REQUEST);
    yield* asyncHandleMyAddressRequest(action);
  }
}

export function* watchDeleteMyAddressRequest() {
  while (true) {
    const action = yield take(DELETE_MY_ADDRESS_REQUEST);
    yield* asyncDeleteMyAddressRequest(action);
  }
}

export default function*() {
  yield all([
    fork(watchSetInitialValueRequest),
    fork(watchGetMyAddressRequest),
    fork(watchCreateMyAddressRequest),
    fork(watchUpdateMyAddressRequest),
    fork(watchDeleteMyAddressRequest),
  ]);
}
