import React, { PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Main from 'containers/Main';
import Login from 'containers/Auth/Login';
import Version from "../Main/Version";

class RoutesContainer extends PureComponent {
  render() {
    return (
      <Switch>
        <Route component={Version} path="/version" />
        <Route component={Main} path={['/ots/:pageType', '/ots/:pageType/:subpageType']} />
        <Route component={Login} path="/" />
        <Redirect to="/404" />
      </Switch>
    );
  }
}

export default RoutesContainer;
