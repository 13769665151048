import { createAction } from 'redux-actions';
import { createPromiseAction } from 'core/modules/utils';

/**
 * Action Types
 */
export const GET_SETTINGS_LIST_REQUEST = '@settings/GET_SETTINGS_LIST_REQUEST';
export const GET_SETTINGS_LIST_SUCCESS = '@settings/GET_SETTINGS_LIST_SUCCESS';
export const GET_SETTINGS_LIST_FAILURE = '@settings/GET_SETTINGS_LIST_FAILURE';

/**
 * Action Creators
 */
export const settingsActionCreators = {
  getSettingsList: createPromiseAction(GET_SETTINGS_LIST_REQUEST),
  getSettingsListSuccess: createAction(GET_SETTINGS_LIST_SUCCESS),
  getSettingsListFailure: createAction(GET_SETTINGS_LIST_FAILURE),
};
