import React from 'react';
import PropTypes from 'prop-types';
import placeHolderImg from 'assets/img/placeholder.svg';

export function CustomImg({ src, alt }) {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  return (
    <div className="smooth-image-wrapper">
      {!imageLoaded && <img alt="placeholder" src={placeHolderImg} />}
      <img
        alt={alt}
        className={`smooth-image image-${imageLoaded ? 'visible' : 'hidden'}`}
        onLoad={() => setImageLoaded(true)}
        src={src}
      />
    </div>
  );
}

CustomImg.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

CustomImg.defaultProps = {
  src: '',
  alt: 'photo',
};

export default CustomImg;
