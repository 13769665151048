import React, {memo} from 'react';
import { ClearAllOutlined, CloseOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import placeHolderImg from '../../assets/img/placeholder.svg';
import { getCurrency } from '../../utilities/common';
import NumberPicker from './NumberPicker';

const ProductCart = ({ product, index, contents, handleTotalCount, onSingleDetail, removeOrder, getProductInfo }) => {

  return (
    <div className="product" key={index}>
      <div className="product_brand">
        <img
          alt="food"
          src={
            product.item.productCode !== undefined
              ? `${process.env.REACT_APP_S3}/${
                  product.item.productCode > 0 ? product.item.productCode : getProductInfo(product.item).productCode
                }.jpg`
              : placeHolderImg
          }
        />
      </div>
      <div className="product_detail">
        <p className="name" dangerouslySetInnerHTML={{ __html: product.item.name }} />
        {contents.length > 0 &&
          contents[index].options.map(({ optionDetail, price, ingredients = [], aggregates = [] }, _index) => (
            <div key={_index}>
              <div
                dangerouslySetInnerHTML={{
                  __html: optionDetail.name + (!price ? '' : ` +${getCurrency().symbol}${(price / 100).toFixed(2)}`),
                }}
              />
              {ingredients
                .filter(({ quantity }) => quantity === 0 || quantity > 1)
                .map(({ description, price: _price, quantity }) => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${quantity === 0 ? 'Sin' : 'Extra'} ${description || ''}${
                        quantity === 0 || !_price
                          ? ''
                          : ` +${getCurrency().symbol}${((_price / 100) * (quantity - 1)).toFixed(2)}`
                      }`,
                    }}
                    key={Math.random()}
                  />
                ))}
              {aggregates
                .filter(({ quantity }) => quantity > 0)
                .map(({ description, price: _price, quantity }) => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `Extra: ${description}${
                        !_price ? '' : ` +${getCurrency().symbol}${((_price / 100) * quantity).toFixed(2)}`
                      }`,
                    }}
                    key={Math.random()}
                  />
                ))}
            </div>
          ))}
        {contents.length > 0 &&
          contents[index].ingredients
            .filter(({ quantity }) => quantity === 0 || quantity > 1)
            .map(({ description, price, quantity }) => (
              <div
                className="quantity"
                dangerouslySetInnerHTML={{
                  __html: `${quantity === 0 ? 'Sin:' : 'Extra:'} ${description || ''}${
                    quantity === 0 || !price
                      ? ''
                      : ` +${getCurrency().symbol}${((price / 100) * (quantity - 1)).toFixed(2)}`
                  }`,
                }}
                key={Math.random()}
              />
            ))}
        {contents.length > 0 &&
          contents[index].aggregates
            .filter(({ quantity }) => quantity > 0)
            .map(({ description, price, quantity }) => (
              <div
                className="quantity"
                dangerouslySetInnerHTML={{
                  __html: `Extra: ${description} ${
                    !price ? '' : `${getCurrency().symbol}${((price / 100) * quantity).toFixed(2)}`
                  }`,
                }}
                key={Math.random()}
              />
            ))}
        {product.amount && (
          <p className="quantity">
            {`${getCurrency().symbol}${parseFloat(product.amount / 100).toFixed(2)} x ${product.totalCount} = ${
              getCurrency().symbol
            }${(product.amount * product.totalCount) / 100}`}
          </p>
        )}
        <div className="change_quantity">
          <NumberPicker onChange={value => handleTotalCount(value, index)} selectedNumber={product.totalCount} />
          <div className="divider" />
          <div className="clear_all" onClick={() => onSingleDetail(product.item.id, index)}>
            <ClearAllOutlined />
          </div>
          <div className="divider" />
          <div className="close" onClick={() => removeOrder(index)}>
            <CloseOutlined />
          </div>
        </div>
      </div>
    </div>
  );
};
ProductCart.propTypes = {
  product: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  contents: PropTypes.object.isRequired,
  handleTotalCount: PropTypes.func.isRequired,
  onSingleDetail: PropTypes.func.isRequired,
  removeOrder: PropTypes.func.isRequired,
  getProductInfo: PropTypes.func.isRequired,
};

export default ProductCart;
