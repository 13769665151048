import React from 'react';
import PropTypes from 'prop-types';
import ModalDlg from 'components/Basic/ModalDlg';

const LinkModal = ({ onClose, title, url, visible, ...props }) => (
  <ModalDlg handleCancel={onClose} isOverlay visible={visible} width={720} {...props}>
    <iframe src={url} style={{ height: 720 }} title={title} />
  </ModalDlg>
);

LinkModal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

LinkModal.defaultProps = {
  onClose: () => {},
  title: '',
  visible: false,
};

export default LinkModal;
