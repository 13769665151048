import { connect } from 'react-redux';
import { regionActionCreators } from 'core/modules/region/actions';

function mapStateToProps({ region }) {
  return {
    region,
  };
}

const mapDispatchToProps = regionActionCreators;

export function connectRegion(configMapStateToProps = mapStateToProps) {
  return connect(configMapStateToProps, mapDispatchToProps);
}
