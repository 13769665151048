import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';
import { Button, Select, TimePicker } from 'antd';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowForward, DateRange } from '@material-ui/icons';

import {
  addressActionCreators,
  catalogActionCreators,
  connectAddress,
  connectCatalog,
  connectCustomer,
  connectOrder,
  connectRegion,
  connectRestaurant,
  customerActionCreators,
  orderActionCreators,
  regionActionCreators,
  restaurantActionCreators,
} from 'core';
import { promisify } from 'utilities';

const { Option } = Select;
let days = [];
const disabledHours = [];
const disabledMinutes = [];

function BusytimeModal({
  title,
  subtitle,
  deliveryAddress,
  isOpenModal,
  setInitialOrder,
  getRestaurantTimeSlots,
  onCancel,
  onOk,
}) {
  const currentDate = moment().tz('America/Guatemala');
  const [selectedDay, setSelectedDay] = useState(moment(currentDate, 'YYYY-MM-DD').format('YYYY-MM-DD'));
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [selectedTime, setSelectedTime] = useState('');

  let startHour = -1;
  let endHour = -1;
  let startMinute = -1;

  useEffect(() => {
    if (selectedTimeSlots.length !== 0) {
      startHour = moment(selectedTimeSlots[0].time)
        .tz('America/Guatemala')
        .format('H');
      endHour = moment(selectedTimeSlots[selectedTimeSlots.length - 1].time)
        .tz('America/Guatemala')
        .format('H');
      startMinute = moment(selectedTimeSlots[0].time)
        .tz('America/Guatemala')
        .format('m');
      for (let i = 0; i < 24; i += 1) {
        if (i < parseInt(startHour, 10) || i > parseInt(endHour, 10)) {
          disabledHours.push(i);
        }
      }

      for (let i = 0; i < 60; i += 15) {
        if (i < parseInt(startMinute, 10)) {
          disabledMinutes.push(i);
        }
      }
    }
  }, [selectedTimeSlots]);

  const handleChangeDays = value => {
    const tempSelectedTimeSlots = timeSlots.filter(
      t =>
        moment(t.time)
          .tz('America/Guatemala')
          .format('YYYY-MM-DD') === value,
    );
    setSelectedTimeSlots(tempSelectedTimeSlots);
    setSelectedDay(value);
    setSelectedTime(
      tempSelectedTimeSlots.length === 0
        ? ''
        : moment(tempSelectedTimeSlots[0].time)
            .tz('America/Guatemala')
            .format('HH:mm'),
    );
  };

  const handleChangeTime = (time, timeString) => {
    setSelectedTime(timeString);
  };

  const getDueTo = () => {
    const dueToTemp = moment(`${selectedDay} ${selectedTime}`)
      .tz('America/Guatemala')
      .toString();
    return dueToTemp;
  };

  const handleSelectAddress = item => {
    days = [];
    setSelectedTimeSlots([]);
    if (item.belongsToDeliveryArea || item.restaurant) {
      promisify(getRestaurantTimeSlots, {
        restaurant: item.restaurant,
        lat: item.lat,
        long: item.long,
      }).then(res => {
        if (res.timeSlots && Array.isArray(res.timeSlots)) {
          res.timeSlots.forEach(t => {
            // eslint-disable-next-line no-param-reassign
            t.time = moment(t.time)
              .add(15, 'minutes')
              .toDate();
          });
          const maxDueTo = moment(res.maxDueTo);
          const minDueTo = moment(res.minDueTo);
          const diffDays = maxDueTo.diff(minDueTo, 'days') + 1;
          const now = moment().tz('America/Guatemala');
          for (let i = 0; i < diffDays; i += 1) {
            const today = moment(now, 'YYYY-MM-DD');
            const changedDate = today.add('days', i);
            days.push({
              label: i === 0 ? 'Hoy' : changedDate.format('DD'),
              value: changedDate.format('YYYY-MM-DD'),
            });
          }
          if (days.length !== 0) {
            setTimeSlots(res.timeSlots);
            let todayTimeSlots = res.timeSlots.filter(
              t =>
                moment(t.time)
                  .tz('America/Guatemala')
                  .format('YYYY-MM-DD') === days[0].value,
            );
            if (todayTimeSlots.length === 0 && res.timeSlots.length !== 0 && days.length > 1) {
              todayTimeSlots = res.timeSlots.filter(
                t =>
                  moment(t.time)
                    .tz('America/Guatemala')
                    .format('YYYY-MM-DD') === days[1].value,
              );
              setSelectedDay(days[1].value);
            } else {
              setSelectedDay(days[0].value);
            }
            if (todayTimeSlots.length !== 0) {
              setSelectedTimeSlots(todayTimeSlots);
              setSelectedTime(
                moment(todayTimeSlots[0].time)
                  .tz('America/Guatemala')
                  .format('HH:mm'),
              );
            } else {
              setSelectedTimeSlots([]);
              setSelectedTime('');
            }
          }
        }
      });
    }
  };

  const handleStartOrder = () => {
    if (deliveryAddress && deliveryAddress.restaurant) {
      promisify(setInitialOrder, { dueTo: getDueTo() });
      onOk();
    }
  };

  useEffect(() => {
    if (deliveryAddress && isOpenModal) {
      handleSelectAddress(deliveryAddress);
    }
  }, [deliveryAddress, isOpenModal]);

  return (
    <ModalDlg className="busy_time_modal" handleCancel={onCancel} isCenter visible={isOpenModal} width={634}>
      <div className="modal_header">
        <h3>{title}</h3>
        <p className="description">{subtitle}</p>
      </div>
      <div className="modal_content">
        <div className="delivery_time_wrapper">
          <div className="dropdown_wrapper">
            <Select
              className="date_list"
              defaultValue={selectedDay}
              onSelect={handleChangeDays}
              suffixIcon={<DateRange />}
              value={selectedDay}
            >
              {days.map(item => (
                <Option key={item.label} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <TimePicker
              className="time_list"
              disabled={selectedTimeSlots.length === 0}
              disabledHours={() => {
                return disabledHours;
              }}
              disabledMinutes={hour => {
                if (startHour && hour === parseInt(startHour, 10)) {
                  return disabledMinutes;
                }
                return [];
              }}
              format="HH:mm"
              hideDisabledOptions
              minuteStep={15}
              onChange={handleChangeTime}
              value={selectedTime ? moment(`${selectedTime}`, 'HH:mm').tz('America/Guatemala') : null}
            />
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <div className="btn_wrapper">
          <p onClick={onCancel}>Cancelar el pedido</p>
          <Button
            className="remove_btn"
            disabled={
              !selectedTimeSlots.length ||
              !deliveryAddress ||
              !deliveryAddress.restaurant ||
              !selectedDay ||
              !selectedTime
            }
            onClick={handleStartOrder}
          >
            Asignar nueva fecha y hora de entrega
            <ArrowForward />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
}

BusytimeModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  setInitialOrder: PropTypes.func.isRequired,
  getRestaurantTimeSlots: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  deliveryAddress: PropTypes.object,
  isOpenModal: PropTypes.bool,
};

BusytimeModal.defaultProps = {
  isOpenModal: false,
  title: '',
  subtitle: '',
  deliveryAddress: {},
};

const mapDispatchToProps = dispatch => {
  const { setInitialAddress, updateMyAddress } = addressActionCreators;
  const { setInitialOrder, checkMyOrder } = orderActionCreators;
  const { setInitialCatalog } = catalogActionCreators;
  const { getRestaurantTimeSlots, getDeliveryAreas, getRestaurantList } = restaurantActionCreators;
  const { getBestRestaurants, getCustomerAddress } = customerActionCreators;
  const { getZone } = regionActionCreators;

  return bindActionCreators(
    {
      setInitialAddress,
      getBestRestaurants,
      updateMyAddress,
      setInitialOrder,
      setInitialCatalog,
      checkMyOrder,
      getRestaurantTimeSlots,
      getDeliveryAreas,
      getRestaurantList,
      getZone,
      getCustomerAddress,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  connectAddress(undefined, mapDispatchToProps),
  connectOrder(undefined, mapDispatchToProps),
  connectCatalog(undefined, mapDispatchToProps),
  connectRestaurant(undefined, mapDispatchToProps),
  connectCustomer(undefined, mapDispatchToProps),
  connectRegion(undefined, mapDispatchToProps),
)(BusytimeModal);
