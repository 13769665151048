export const getPaymentError = error => {
  switch (error.code) {
    case 101:
      return 'CybersourceDeclinedMissingFields';
    case 102:
      return 'CybersourceDeclinedInvalidData';
    case 104:
      return 'CybersourceDeclinedIdenticalAuthorization';
    case 110:
      return 'CybersourcePartialApprove';
    case 150:
      return 'CybersourceErrorSystemFailure';
    case 151:
      return 'CybersourceErrorReceivedTimeout';
    case 152:
      return 'CybersourceErrorReceived';
    case 154:
      return 'CybersourceBadMaCKey';
    case 200:
      return 'CybersourceDeclinedAVSCybersource';
    case 201:
      return 'CybersourceDeclinedBankQuestion';
    case 202:
      return 'CybersourceDeclinedExpiredCard';
    case 203:
      return 'CybersourceDeclinedCard';
    case 204:
      return 'CybersourceDeclinedInsufficientFunds';
    case 205:
      return 'CybersourceDeclinedStolenCard';
    case 207:
      return 'CybersourceDeclinedBankUnavailable';
    case 208:
      return 'CybersourceDeclinedInactiveCard';
    case 209:
      return 'CybersourceDeclinedCIDMismatch';
    case 210:
      return 'CybersourceDeclinedCreditLimit';
    case 211:
      return 'CybersourceDeclinedInvalidCardVerificationNumber';
    case 220:
      return 'CybersourceDeclinedGeneral';
    case 221:
      return 'CybersourceDeclinedProcessorNegativeFile';
    case 222:
      return 'CybersourceDeclinedFrozenAccount';
    case 230:
      return 'CybersourceDeclinedCVCybersource';
    case 231:
      return 'CybersourceDeclinedInvalidAccountNumber';
    case 232:
      return 'CybersourceDeclinedCardTypeUnacceptable';
    case 233:
      return 'CybersourceDeclinedGeneralProcessor';
    case 234:
      return 'CybersourceDeclinedMerchantConfiguration';
    case 235:
      return 'CybersourceDeclinedExceedOriginalAuthorizedAmount';
    case 236:
      return 'CybersourceDeclinedProcessorFailure';
    case 237:
      return 'CybersourceDeclinedAuthorizationReversed';
    case 238:
      return 'CybersourceDeclinedAuthorizationCaptured';
    case 239:
      return 'CybersourceDeclinedMismatchPreviousTransactionAmount';
    case 240:
      return 'CybersourceDeclinedInvalidCardType';
    case 241:
      return 'CybersourceDeclinedInvalidRequestID';
    case 242:
      return 'CybersourceDeclinedMissingAuthorizationRecord';
    case 243:
      return 'CybersourceDeclinedTransactionSettled';
    case 246:
      return 'CybersourceDeclinedCaptureNotVoidable';
    case 247:
      return 'CybersourceDeclinedCreditVoided';
    case 248:
      return 'CybersourceDeclinedBoletoRequest';
    case 250:
      return 'CybersourceErrorPaymentProcessorTimeout';
    case 251:
      return 'CybersourceDeclinedPinlessCardLimit';
    case 254:
      return 'CybersourceDeclinedAccountStandAloneRefunds';
    case 268:
      return 'CybersourceTransactionError';
    case 400:
      return 'CybersourceDeclinedFraudScoreExceeded';
    case 450:
      return 'CybersourceApartmentMissing';
    case 451:
      return 'CybersourceInsufficientAddressInformation';
    case 452:
      return 'CybersourceHouseNotFound';
    case 453:
      return 'CybersourceMultipleAddressMatch';
    case 454:
      return 'CybersourcePOBoxIdentifierNotFound';
    case 455:
      return 'CybersourceRouteNotFound';
    case 456:
      return 'CybersourceStreetNotFound';
    case 457:
      return 'CybersourcePostalNotFound';
    case 458:
      return 'CybersourceUnableVerifyAddress';
    case 459:
      return 'CybersourceMultipleInternationalAddressMatch';
    case 460:
      return 'CybersourceAddressNotFound';
    case 461:
      return 'CybersourceErrorUnsupportedCharacterSet';
    case 475:
      return 'CybersourceErrorCardholderEnrolledPayerAuthentication';
    case 476:
      return 'CybersourceErrorEncounteredPayerAuthentication';
    case 478:
      return 'CybersourceSCARequired';
    case 480:
      return 'CybersourceOrderMarkedReview';
    case 481:
      return 'CybersourceErrorOrderRejected';
    case 490:
      return 'CybersourceAggregatorNotAccepting';
    case 491:
      return 'CybersourceAggregatorUnavailable';
    case 520:
      return 'CybersourceDeclinedAuthorizationRequestCybersource';
    case 700:
      return 'CybersourceCustomerMatchDeniedParties';
    case 701:
      return 'CybersourceExportBillShipCountryMatch';
    case 702:
      return 'CybersourceExportEmailCountryMatch';
    case 703:
      return 'CybersourceExportHostnameIpCountryMatch';
    default:
      return 'CybersourceUnknown';
  }
};
