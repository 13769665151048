import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import { Button, Input, Select, Switch } from 'antd';
import Geocode from 'react-geocode';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
  addressActionCreators,
  connectAddress,
  connectCustomer,
  connectRegion,
  connectRestaurant,
  customerActionCreators,
  regionActionCreators,
} from 'core';
import ModalDlg from 'components/Basic/ModalDlg';
import Marker from 'components/Basic/Marker';
import toast from 'components/Basic/Toast';
import LocationSearchInput from 'components/Basic/LocationSearchInput';
import { ArrowDropDown, ArrowForward, Close } from '@material-ui/icons';
import { promisify } from 'utilities';
import { getCountry, getLocations } from 'utilities/common';
import { COLOR_SCHEMA } from 'utilities/constants';

const { Option } = Select;
let mapRef = null;
let mapsRef = null;
let drawingManager;

const blockStoreAssignedToAddress = false;

function DeliveryUpdateModal({
  intl,
  deliveryAreas,
  restaurants,
  clientId,
  deliveryAddress,
  isCreateAddress,
  isOpenModal,
  departments,
  municipalities,
  zones,
  selectedRestaurantId,
  getBestRestaurants,
  getCustomerAddress,
  onCloseModal,
  setInitialRegion,
  getDepartments,
  getMunicipalities,
  getZones,
  getZone,
  getRestaurantsFromZone,
  setInitialAddress,
  createMyAddress,
  updateMyAddress,
  onUpdatedAddress,
  checkMyAddressInRestaurant,
  isRestaurant,
  restaurantSarAssigned,
}) {
  const [formattedAddress, setFormattedAddress] = useState('');
  const [additionalAddress, setAdditionalAddress] = useState('');
  const [reference, setReference] = useState('');
  const [center, setCenter] = useState({
    lat: getLocations().lat,
    lng: getLocations().lng,
  });
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedMunicipality, setSelectedMunicipality] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [assignedRestaurants, setAssignedRestaurants] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(11);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isRestaurantState, setIsRestraurantState] = useState(false);
  const [isInitialLoadData, setIsInitialLoadData] = useState(true);

  useEffect(() => {
    Geocode.setApiKey('AIzaSyBFvkGMh2tCKhf4X61mWH7RDfCnvgVywX8');
    promisify(setInitialRegion, {
      municipalities: [],
      zones: [],
      selectedRestaurantId: null,
    });
    promisify(getDepartments, { offset: 0, limit: 100 });
    setSelectedDepartment(null);
    setSelectedMunicipality(null);
    setSelectedZone(null);
    setAssignedRestaurants([]);
    if (deliveryAddress.address && isOpenModal) {
      setZoomLevel(11);
      setFormattedAddress(deliveryAddress.address);
      setReference(deliveryAddress.reference);
      checkCoords({
        lng: deliveryAddress.long || getLocations().lng,
        lat: deliveryAddress.lat || getLocations().lat,
      });
      setCenter({
        lat: deliveryAddress.lat || getLocations().lat,
        lng: deliveryAddress.long || getLocations().lng,
      });
      // eslint-disable-next-line no-use-before-define
      populateRegion({
        lat: deliveryAddress.lat || getLocations().lat,
        lng: deliveryAddress.long || getLocations().lng,
      });
    } else {
      setFormattedAddress('');
      setAdditionalAddress('');
      setReference('');
      setCenter({
        lat: getLocations().lat,
        lng: getLocations().lng,
      });
      setIsConfirmed(false);
    }
  }, [isOpenModal]);

  const drawOtherRegions = () => {
    if (deliveryAreas && restaurants) {
      const infoWindow = new mapsRef.InfoWindow();
      const now = moment().locale('en');
      const dayText = now.format('dddd').toLowerCase();
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < deliveryAreas.length; i++) {
        if (deliveryAreas[i].area && deliveryAreas[i].area.areas && Array.isArray(deliveryAreas[i].area.areas)) {
          const shapes = [];
          deliveryAreas[i].area.areas.forEach(polygon => {
            const coords = [];
            polygon.forEach(p => {
              coords.push({ lat: p.latitude || p.lat, lng: p.longitude || p.lng });
            });
            shapes.push(coords);
          });
          const startTime = moment(deliveryAreas[i][`${dayText}OpenTime`], 'HH:mm');
          const endTime = moment(deliveryAreas[i][`${dayText}CloseTime`], 'HH:mm');
          shapes.forEach(shape => {
            const polygonsObj = new mapsRef.Polygon({
              paths: shape,
              strokeColor: deliveryAreas[i].type === 'I' ? COLOR_SCHEMA[deliveryAreas[i].restaurant % 34] : '#000000',
              strokeWeight: 2,
              fillColor: deliveryAreas[i].type === 'I' ? COLOR_SCHEMA[deliveryAreas[i].restaurant % 34] : '#000000',
              fillOpacity: 0.3,
            });
            polygonsObj.setMap(mapRef);
            mapsRef.event.addListener(polygonsObj, 'click', e => {
              if (startTime.isValid() && endTime.isValid()) {
                infoWindow.setContent(`${startTime.format('h:mm a')} - ${endTime.format('h:mm a')}`);
                infoWindow.setPosition(e.latLng);
                infoWindow.open(mapRef);
              }
            });
          });
        }
      }
    }
  };

  const initArea = () => {
    if (mapRef && mapsRef) {
      setTimeout(() => {
        drawOtherRegions();
      }, 2000);
    }
  };

  useEffect(() => {
    initArea();
  }, [mapRef, mapsRef, deliveryAreas, restaurants]);

  const handleGoogleAPILoaded = ({ map, maps }) => {
    drawingManager = new maps.drawing.DrawingManager({
      // drawingMode: maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        drawingModes: [maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        editable: false,
        strokeColor: '#D0021B',
        strokeWeight: 2,
        fillColor: '#D0021B',
        fillOpacity: 0.07,
        suppressUndo: false,
      },
    });
    mapRef = map;
    mapsRef = maps;
    drawingManager.setMap(map);
  };

  const handleCloseModal = () => {
    onCloseModal()
    setIsInitialLoadData(true);
  }

  const handleContinue = async () => {
    if (isCreateAddress) {
      handleCloseModal();
      await promisify(createMyAddress, {
        customer: clientId,
        address: formattedAddress,
        googleAddress: '',
        description: '',
        name: '',
        type: 'E',
        lat: center.lat,
        long: center.lng,
        reference,
        department: selectedDepartment,
        municipality: selectedMunicipality,
        zone: selectedZone,
        restaurant: selectedRestaurantId,
      })
        .then(res => {
          promisify(setInitialAddress, { selectedAddress: res.data });
        })
        .catch(error => {
          console.log('error', error);
        });
    } else {
      handleCloseModal();
      await promisify(updateMyAddress, {
        id: deliveryAddress.id,
        customer: clientId,
        address: formattedAddress,
        description: additionalAddress,
        name: '',
        type: deliveryAddress.type || 'E',
        lat: center.lat,
        long: center.lng,
        reference,
        department: selectedDepartment,
        municipality: selectedMunicipality,
        zone: selectedZone,
        restaurant: selectedRestaurantId,
      })
        .then(res => {
          promisify(setInitialAddress, { selectedAddress: res.data });
          onUpdatedAddress(res.data);
        })
        .catch(error => {
          console.log('error', error);
        });
    }
    await promisify(getCustomerAddress, {
      id: clientId,
      pendingOrders: true,
    });
  };

  const handleDepartmentSelect = async id => {
    setSelectedDepartment(id);
    setSelectedMunicipality(null);
    setSelectedZone(null);
    promisify(setInitialRegion, {
      selectedRestaurantId: null,
    });

    await promisify(getMunicipalities, {
      offset: 0,
      limit: 100,
      filter: `&filter=deptCode eq ${id}`,
    });
  };

  const handleMunicipality = async (id, deptCode) => {
    setSelectedMunicipality(id);
    setSelectedZone(null);
    promisify(setInitialRegion, {
      selectedRestaurantId: null,
    });
    await promisify(getZones, {
      offset: 0,
      limit: 100,
      filter: `&filter=deptCode eq ${deptCode || selectedDepartment}&filter=municipalityCode eq ${id}`,
    });
  };

  const handleMunicipalityInitLoad = async (id, deptCode) => {
    setSelectedMunicipality(deptCode);
    setSelectedZone(null);
    promisify(setInitialRegion, {
      selectedRestaurantId: null,
    });
    await promisify(getZones, {
      offset: 0,
      limit: 100,
      filter: `&filter=deptCode eq ${id || selectedDepartment}&filter=municipalityCode eq ${deptCode}`,
    });
  };

  const handleZone = async id => {
    setSelectedZone(id);
    promisify(setInitialRegion, {
      selectedRestaurantId: null,
    });

    await promisify(getRestaurantsFromZone, { id }).then(res => {
      const restaurants1 = [];
      res.rows.forEach(z => {
        if (z.restaurant && z.restaurant.state === 'A') {
          restaurants1.push(z.restaurant);
        }
      });
      setAssignedRestaurants(restaurants1);
    });
  };

  const handleZoneInitLoad = async id => {
    setSelectedZone(id);
    promisify(setInitialRegion, {
      selectedRestaurantId: deliveryAddress.restaurant,
    });

    await promisify(getRestaurantsFromZone, { id }).then(res => {
      const restaurants1 = [];
      res.rows.forEach(z => {
        if (z.restaurant && z.restaurant.state === 'A') {
          restaurants1.push(z.restaurant);
        }
      });

      if (deliveryAddress.isSarChangeRestaurant === true || !res.rows.includes(restaurantSarAssigned.id)) {
        restaurants1.push(restaurantSarAssigned);
      }

      setAssignedRestaurants(restaurants1);
    });
  };

  const handleRestaurant = async id => {
    promisify(setInitialRegion, {
      selectedRestaurantId: id,
    });
  };

  const initialAddress = async deliveryaddress => {
    if (deliveryaddress.department) {
      await handleDepartmentSelect(deliveryaddress.department);
    }
    if (deliveryaddress.department && deliveryAddress.municipality) {
      await handleMunicipalityInitLoad(deliveryaddress.department, deliveryaddress.municipality);
    }
    if (deliveryAddress.zone) {
      await handleZoneInitLoad(deliveryaddress.zone);
    }
    if (deliveryAddress.restaurant) {
      handleRestaurant(deliveryaddress.restaurant);
    }
  };

  const populateRegion = async (position, ignoreAssignedRestaurant, isFirstLoad) => {
    let restaurantInfo = null;
    let zoneInfo = null;
    setCenter({
      lat: position.lat,
      lng: position.lng,
    });
    promisify(setInitialRegion, {
      municipalities: [],
      zones: [],
      selectedRestaurantId: null,
    });
    promisify(getDepartments, { offset: 0, limit: 100 });
    setSelectedDepartment(null);
    setSelectedMunicipality(null);
    setSelectedZone(null);
    setAssignedRestaurants([]);

    if (!ignoreAssignedRestaurant) {
      return;
    }

    if (ignoreAssignedRestaurant || !deliveryAddress.restaurant) {
      await promisify(getBestRestaurants, {
        lat: position.lat,
        long: position.lng,
      })
        .then(res => {
          restaurantInfo = res;
        })
        .catch(error => {
          if (error && error.status === 406 && error.data.type !== 'NotFoundRestaurantError') {
            toast.error({
              title: intl.formatMessage({ id: `${error.data.type}` }),
            });
          }
        });
    } else {
      restaurantInfo = {
        id: deliveryAddress.restaurant,
      };
      const restaurantDetail = restaurants.find(r => r.id === deliveryAddress.restaurant) || {};
      restaurantInfo = {
        ...restaurantInfo,
        ...restaurantDetail,
      };
      setAssignedRestaurants([...restaurants]);
      handleRestaurant(restaurantInfo.id);
    }

    if (restaurantInfo && restaurantInfo.zones && restaurantInfo.zones.length !== 0) {
      await promisify(getZone, { id: restaurantInfo.zones[0].id }).then(res => {
        zoneInfo = res.data;
      });
    }

    if (isFirstLoad) {
      initialAddress(deliveryAddress)
      setIsInitialLoadData(false);
      return;
    }

    if (
      restaurantInfo &&
      restaurantInfo.zones &&
      restaurantInfo.zones.length !== 0 &&
      zoneInfo.deptCode &&
      zoneInfo.municipalityCode
    ) {
      await handleDepartmentSelect(zoneInfo.deptCode);
      await handleMunicipality(zoneInfo.municipalityCode, zoneInfo.deptCode);
      await handleZone(restaurantInfo.zones[0].id);
      await handleRestaurant(restaurantInfo.id);
    }
  };

  const handleGeoInfo = (address, latLng) => {
    setAdditionalAddress(address);
    checkCoords({ lat: latLng.lat, lng: latLng.lng })
  };

  const checkCoords = ({ lng, lat }) => {
    promisify(checkMyAddressInRestaurant, {
      long: lng,
      lat,
    })
      .then((res) => {
        if (res.data.isFound) {
          setIsRestraurantState(true)
          populateRegion({ lat, lng }, true, isInitialLoadData);
          toast.success({
            title: intl.formatMessage({
              id: 'TheDeliveryAreaServesYourAddress',
            }),
          });
        } else {
          setAdditionalAddress('');
          populateRegion({ lat, lng }, false);
          toast.error({
            title: intl.formatMessage({
              id: 'TheDeliveryAreaDoesNotServeYourAddress',
            }),
          });
        }
      })
      .catch((e) => {
        console.log(e);
      })
  }

  const handleDrag = map => {
    const position = map.getCenter();
    checkCoords(position.toJSON());
  };

  const handleCopyAddress = () => {
    if (formattedAddress && formattedAddress.trim().length !== 0) {
      setZoomLevel(11);
      setAdditionalAddress(formattedAddress);
      setIsInitialLoadData(false);
    }
  };

  const handleZoom = value => {
    setZoomLevel(value);
    if (mapRef) {
      mapRef.setCenter({
        lat: center.lat,
        lng: center.lng,
      });
    }
  };

  return (
    <ModalDlg className="delivery_update_modal" handleCancel={handleCloseModal} isCenter visible={isOpenModal} width={951}>
      <div className="modal_content">
        <div className="close_btn" onClick={handleCloseModal}>
          <Close />
        </div>
        <div className="map_wrapper">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyBFvkGMh2tCKhf4X61mWH7RDfCnvgVywX8',
              libraries: ['drawing'].join(','),
            }}
            center={center}
            defaultCenter={{ lat: getLocations().lat, lng: getLocations().lng }}
            defaultZoom={20}
            draggable
            onDragEnd={handleDrag}
            onGoogleApiLoaded={handleGoogleAPILoaded}
            onZoomAnimationEnd={handleZoom}
            yesIWantToUseGoogleMapApiInternals
            zoom={zoomLevel}
          />
          <Marker lat={center.lat} lng={center.lng} />
        </div>
        <div className="address_setting_wrapper">
          <h3>
            {isCreateAddress ? (
              <FormattedMessage id="New delivery address" />
            ) : (
              <FormattedMessage id="Edit delivery address" />
            )}
          </h3>
          <div className="address_direction">
            <p className="label">
              <FormattedMessage id="Textual direction and directions" />
            </p>
            <Input.TextArea
              className="address_detail"
              onChange={e => setFormattedAddress(e.target.value)}
              placeholder={intl.formatMessage({ id: 'Delivery address - basic format' })}
              rows={3}
              value={formattedAddress}
            />
            <LocationSearchInput
              address={additionalAddress}
              disabled={
                blockStoreAssignedToAddress &&
                !isCreateAddress &&
                ((selectedDepartment && selectedMunicipality && selectedZone && selectedRestaurantId) ||
                  deliveryAddress.restaurant)
              }
              onCopyAddress={handleCopyAddress}
              onGetGeoInfo={handleGeoInfo}
              zoomLevel={zoomLevel}
            />
          </div>
          <div className="custom_address">
            <div className="address">
              <p className="label">Referencia</p>
              <Input className="address_reference" onChange={e => setReference(e.target.value)} value={reference} />
            </div>
            <div className="address">
              <p className="label">
                <FormattedMessage id="Country" />
              </p>
              <Select className="country_list" suffixIcon={<ArrowDropDown />} value="GA">
                <Option value="GA">{getCountry()}</Option>
              </Select>
            </div>
            <div className="address">
              <p className="label">
                <FormattedMessage id="Department / Province" />
              </p>
              <Select
                className="department_list"
                disabled={
                  blockStoreAssignedToAddress &&
                  !isCreateAddress &&
                  ((selectedDepartment && selectedMunicipality && selectedZone && selectedRestaurantId) ||
                    deliveryAddress.restaurant)
                }
                onSelect={handleDepartmentSelect}
                suffixIcon={<ArrowDropDown />}
                value={departments && departments.length !== 0 ? selectedDepartment : ''}
              >
                {departments &&
                  departments.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.description}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="address">
              <p className="label">
                <FormattedMessage id="City / Municipality" />
              </p>
              <Select
                className="municipity_list"
                disabled={
                  blockStoreAssignedToAddress &&
                  !isCreateAddress &&
                  ((selectedDepartment && selectedMunicipality && selectedZone && selectedRestaurantId) ||
                    deliveryAddress.restaurant)
                }
                onSelect={handleMunicipality}
                suffixIcon={<ArrowDropDown />}
                value={municipalities && municipalities.length !== 0 ? selectedMunicipality : ''}
              >
                {municipalities &&
                  municipalities.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.description}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="address">
              <p className="label">
                <FormattedMessage id="Zone or sector" />
              </p>
              <Select
                className="zone_list"
                disabled={
                  blockStoreAssignedToAddress &&
                  !isCreateAddress &&
                  ((selectedDepartment && selectedMunicipality && selectedZone && selectedRestaurantId) ||
                    deliveryAddress.restaurant)
                }
                onSelect={handleZone}
                suffixIcon={<ArrowDropDown />}
                value={zones && zones.length !== 0 ? selectedZone : ''}
              >
                {zones &&
                  zones.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.description}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="address restaurant">
              <p className="label">
                <FormattedMessage id="Assigned restaurant" />
              </p>
              <div>
                <Select
                  className="restaurant_list"
                  disabled={
                    blockStoreAssignedToAddress &&
                    !isCreateAddress &&
                    ((selectedDepartment && selectedMunicipality && selectedZone && selectedRestaurantId) ||
                      deliveryAddress.restaurant)
                  }
                  onSelect={handleRestaurant}
                  suffixIcon={<ArrowDropDown />}
                  value={assignedRestaurants && assignedRestaurants.length !== 0 ? selectedRestaurantId : ''}
                >
                  {assignedRestaurants &&
                    assignedRestaurants.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.description}
                      </Option>
                    ))}
                </Select>
                {/* <p className="delivery_estimation">Pendiente</p> */}
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="confirm-wrapper">
              <Switch checked={isConfirmed} onChange={checked => setIsConfirmed(checked)} />
              <FormattedMessage id="Confirmation that the data has been reviewed and is correct." />
            </div>
            <div className="footer-wrapper">
              <div className="aditional_detail">
                <p className="geo_position">
                  {center.lat} / {center.lng}
                </p>
              </div>
              <div className="btn_wrapper">
                <Button
                  className="continue_btn"
                  disabled={
                    !formattedAddress || !assignedRestaurants.find(r => r.id === selectedRestaurantId) || !isConfirmed || !isRestaurant
                  }
                  onClick={handleContinue}
                >
                  {isCreateAddress && <FormattedMessage id="Create address" />}
                  {!isCreateAddress && <FormattedMessage id="Update address" />}
                  <ArrowForward />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalDlg>
  );
}

DeliveryUpdateModal.propTypes = {
  clientId: PropTypes.number.isRequired,
  departments: PropTypes.array,
  municipalities: PropTypes.array,
  zones: PropTypes.array,
  selectedRestaurantId: PropTypes.number,
  deliveryAddress: PropTypes.object,
  isOpenModal: PropTypes.bool,
  isCreateAddress: PropTypes.bool,
  deliveryAreas: PropTypes.array,
  restaurants: PropTypes.array,
  intl: intlShape.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  getBestRestaurants: PropTypes.func.isRequired,
  getCustomerAddress: PropTypes.func.isRequired,
  setInitialRegion: PropTypes.func.isRequired,
  getDepartments: PropTypes.func.isRequired,
  getMunicipalities: PropTypes.func.isRequired,
  getZones: PropTypes.func.isRequired,
  getZone: PropTypes.func.isRequired,
  setInitialAddress: PropTypes.func.isRequired,
  createMyAddress: PropTypes.func.isRequired,
  updateMyAddress: PropTypes.func.isRequired,
  onUpdatedAddress: PropTypes.func.isRequired,
  getRestaurantsFromZone: PropTypes.func.isRequired,
  restaurantSarAssigned: PropTypes.object,
};

DeliveryUpdateModal.defaultProps = {
  departments: [],
  municipalities: [],
  zones: [],
  selectedRestaurantId: 0,
  deliveryAddress: {},
  isCreateAddress: false,
  deliveryAreas: [],
  restaurants: [],
  isOpenModal: false,
};

const mapStateToProps = ({ region }) => ({
  departments: region.departments,
  municipalities: region.municipalities,
  zones: region.zones,
  selectedRestaurantId: region.selectedRestaurantId,
  isRestaurant: region.isRestaurant,
});

const mapDispatchToProps = dispatch => {
  const { getBestRestaurants, getCustomerAddress } = customerActionCreators;
  const {
    setInitialRegion,
    getDepartments,
    getMunicipalities,
    getZones,
    getZone,
    assignRestaurants,
    getRestaurantsFromZone,
    checkMyAddressInRestaurant,
  } = regionActionCreators;
  const { setInitialAddress, createMyAddress, updateMyAddress } = addressActionCreators;

  return bindActionCreators(
    {
      setInitialRegion,
      setInitialAddress,
      getBestRestaurants,
      getCustomerAddress,
      getDepartments,
      getMunicipalities,
      getZones,
      getZone,
      assignRestaurants,
      getRestaurantsFromZone,
      checkMyAddressInRestaurant,
      createMyAddress,
      updateMyAddress,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  withRouter,
  connectRestaurant(mapStateToProps, mapDispatchToProps),
  connectRegion(mapStateToProps, mapDispatchToProps),
  connectCustomer(undefined, mapDispatchToProps),
  connectAddress(undefined, mapDispatchToProps),
)(DeliveryUpdateModal);
