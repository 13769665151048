import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Button, Icon, Input, Tooltip } from 'antd';

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.address !== this.props.address) {
      this.handleChange(this.props.address);
      geocodeByAddress(this.props.address)
        .then(results => getLatLng(results[0]))
        .then(latLng => this.props.onGetGeoInfo(this.props.address, latLng))
        .catch(error => console.error('Error', error));
    }
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = (address, placeId) => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.props.onGetGeoInfo(address, latLng, placeId))
      .catch(error => console.error('Error', error));
  };

  render() {
    const { zoomLevel, disabled } = this.props;
    return (
      <PlacesAutocomplete
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{
          componentRestrictions: { country: [process.env.REACT_APP_COUNTRY || 'gt'] },
        }}
        value={this.state.address}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <div className="location_input_wrapper">
              <Input
                {...getInputProps({
                  placeholder: 'Lugar de referencia, utiliza Google Maps',
                  className: 'location-search-input',
                })}
                disabled={disabled}
                prefix={<Icon type="search" />}
              />
              <Tooltip
                placement="top"
                title={
                  <div style={{ padding: '17px' }}>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 'bold',
                        color: 'white',
                        marginBottom: 11,
                      }}
                    >
                      Copiar dirección
                    </p>
                    <p
                      style={{
                        fontSize: 11.5,
                        color: '#B8B8B8',
                      }}
                    >
                      Copiar la dirección ingresada al campo de localización de Google Maps.
                    </p>
                  </div>
                }
              >
                <Button disabled={disabled} onClick={this.props.onCopyAddress}>
                  {zoomLevel}x<Icon type="copy" />
                </Button>
              </Tooltip>
            </div>
            <div className="autocomplete-dropdown-container">
              {loading && <div>Cargando...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

LocationSearchInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  address: PropTypes.string.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  onGetGeoInfo: PropTypes.func.isRequired,
  onCopyAddress: PropTypes.func.isRequired,
};

export default LocationSearchInput;
