import { connect } from 'react-redux';
import { orderActionCreators } from 'core/modules/order/actions';

function mapStateToProps({ myOrder }) {
  return {
    myOrder,
  };
}

const mapDispatchToProps = orderActionCreators;

export function connectOrder(configMapStateToProps = mapStateToProps) {
  return connect(configMapStateToProps, mapDispatchToProps);
}
