import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Img from 'react-image';
import { compose } from 'recompose';
import { Button, Icon, Input, Tooltip } from 'antd';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { catalogActionCreators, connectCatalog, connectOrder, connectRestaurant, orderActionCreators } from 'core';
import { Note, Refresh, Search, WarningOutlined } from '@material-ui/icons';
import placeHolderImg from 'assets/img/placeholder.svg';
import { promisify } from 'utilities';
import toast from 'components/Basic/Toast';
import { getCurrency } from 'utilities/common';

class ProductSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: '',
      results: [],
      isVisibleNote: false,
      deliveryNote: '',
    };
  }

  componentDidMount() {
    this.handleDelete();
  }

  handleSearch = e => {
    this.setState({
      searchKey: e.target.value,
    });
    if (e.target.value) {
      const results = this.props.products.filter(
        p =>
          p.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
          (p.description || '').toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1,
      );
      this.setState({ results });
    } else {
      this.setState({ results: [] });
    }
  };

  showSingleProduct = product => {
    const { selectedCatalogId, dueTo, restaurantId, stocks } = this.props;

    promisify(this.props.getScheduleSuperCatalogs, {
      dueTo,
      restaurant: restaurantId,
    }).then(res => {
      if (stocks.findIndex(s => s.productCode === (product.productCode || product.defaultCode)) !== -1) {
        toast.error({
          title: this.props.intl.formatMessage({
            id: 'Oops ... The restaurant that will take your order does not have this product available at this time.',
          }),
        });
      } else if (res.filter(c => c.id === selectedCatalogId).length === 0) {
        toast.error({
          title: this.props.intl.formatMessage({ id: 'This product is not available at this time' }),
        });
      } else {
        this.props.onSingleDetail(product.id);
      }
    });
    this.setState({
      searchKey: product.name,
      results: [],
    });
  };

  handleShowAddNote = () => {
    this.setState({ isVisibleNote: true });
  };

  handleAddNote = () => {
    const { deliveryNote } = this.state;
    promisify(this.props.setInitialOrder, { note: deliveryNote });
    this.setState({ isVisibleNote: false });
  };

  handleDelete = () => {
    promisify(this.props.setInitialOrder, { note: '' });
    this.setState({ isVisibleNote: false, deliveryNote: '' });
  };

  render() {
    const { results, isVisibleNote, deliveryNote } = this.state;
    const { isExistOrder, note, restaurantList, restaurantId } = this.props;
    let restaurantInfo = {};
    if (restaurantList.rows && restaurantId) {
      // eslint-disable-next-line prefer-destructuring
      restaurantInfo = restaurantList.rows.filter(r => r.id === restaurantId)[0];
    }

    return (
      <div className="product_search_layout">
        <div className="main_content">
          <Input
            onChange={this.handleSearch}
            onKeyDown={this.handleKeydown}
            placeholder={this.props.intl.formatMessage({ id: 'Search product' })}
            suffix={<Search />}
            value={this.state.searchKey}
          />
          <div className={`search_result_wrapper scrollbar ${results.length === 0 ? 'hide' : 'show'}`}>
            {results.map(result => (
              <div className="item" key={Math.random()} onClick={() => this.showSingleProduct(result)}>
                <div className="food_img">
                  <Img
                    alt="F"
                    src={
                      result.productCode !== undefined
                        ? `${process.env.REACT_APP_S3}/${
                            result.productCode > 0 ? result.productCode : result.defaultCode
                          }.jpg`
                        : placeHolderImg
                    }
                  />
                </div>
                <div className="food_content">
                  <p className="name" dangerouslySetInnerHTML={{ __html: result.name }} />
                  <p className="amount">
                    {getCurrency().symbol}
                    {(result.defaultPrice || 0) / 100}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={`add_note_wrapper ${!isVisibleNote ? 'hide' : 'note_show'}`}>
            <p className="title">Añadir notas al pedido</p>
            <Icon onClick={() => this.setState({ isVisibleNote: false })} type="close" />
            <Input.TextArea
              onChange={e => this.setState({ deliveryNote: e.target.value })}
              placeholder="Ingresa las notas al pedido"
              rows={6}
              value={deliveryNote}
            />
            <div className="footer">
              <p onClick={this.handleDelete}>Borrar</p>
              <Button disabled={!deliveryNote} onClick={this.handleAddNote}>
                Guardar notas
              </Button>
            </div>
          </div>
          <div className="note_refresh_wrapper">
            <Tooltip
              placement="top"
              title={
                <div>
                  <p style={{ fontSize: 14, fontWeight: 'bold', color: 'white', marginBottom: 17 }}>
                    <FormattedMessage id="Restaurant notes" />
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: (restaurantInfo.promotionObservation || '').replace(/\\n/g, '</br>'),
                    }}
                    style={{ fontSize: 11.5, color: '#B8B8B8' }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: (restaurantInfo.productObservation || '').replace(/\\n/g, '</br>'),
                    }}
                    style={{ fontSize: 11.5, color: '#B8B8B8' }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: (restaurantInfo.serviceObservation || '').replace(/\\n/g, '</br>'),
                    }}
                    style={{ fontSize: 11.5, color: '#B8B8B8' }}
                  />
                </div>
              }
            >
              <div className="warning">
                <WarningOutlined
                  className={`${
                    restaurantInfo.promotionObservation ||
                    restaurantInfo.productObservation ||
                    restaurantInfo.serviceObservation
                      ? 'active'
                      : 'inactive'
                  }`}
                />
              </div>
            </Tooltip>
            {isExistOrder && (
              <Tooltip placement="left" title={this.props.intl.formatMessage({ id: 'Add a note' })}>
                <div className="note" onClick={this.handleShowAddNote}>
                  <Note className={`${note ? 'active' : 'inactive'}`} />
                </div>
              </Tooltip>
            )}
            {isExistOrder && (
              <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'Clean cart' })}>
                <div className="refresh" onClick={this.props.onResetCart}>
                  <Refresh />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ProductSearch.propTypes = {
  intl: intlShape.isRequired,
  isExistOrder: PropTypes.bool.isRequired,
  selectedCatalogId: PropTypes.number.isRequired,
  dueTo: PropTypes.string,
  note: PropTypes.string,
  products: PropTypes.array.isRequired,
  stocks: PropTypes.array.isRequired,
  restaurantList: PropTypes.object,
  restaurantId: PropTypes.number,
  onSingleDetail: PropTypes.func.isRequired,
  getScheduleSuperCatalogs: PropTypes.func.isRequired,
  onResetCart: PropTypes.func.isRequired,
  setInitialOrder: PropTypes.func.isRequired,
};

ProductSearch.defaultProps = {
  dueTo: '',
  note: '',
  restaurantList: {},
  restaurantId: undefined,
};

const mapStateToProps = ({ order, catalog, restaurant }) => ({
  dueTo: order.dueTo,
  note: order.note,
  stocks: catalog.stocks,
  restaurantList: restaurant.list,
});

const mapDispatchToProps = dispatch => {
  const { getScheduleSuperCatalogs } = catalogActionCreators;
  const { setInitialOrder } = orderActionCreators;

  return bindActionCreators(
    {
      getScheduleSuperCatalogs,
      setInitialOrder,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  withRouter,
  connectCatalog(mapStateToProps, mapDispatchToProps),
  connectOrder(mapStateToProps, mapDispatchToProps),
  connectRestaurant(mapStateToProps, undefined),
)(ProductSearch);
