import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';
import { DeleteOutline } from '@material-ui/icons';

function DeleteConfirmModal({ title, subtitle, isOpenModal, onCancel, onOk }) {
  return (
    <ModalDlg className="delete_confirm_modal" handleCancel={onCancel} isCenter visible={isOpenModal} width={370}>
      <div className="modal_header">
        <h3>{title}</h3>
        <p className="description">{subtitle}</p>
      </div>
      <div className="modal_footer">
        <div className="btn_wrapper">
          <p onClick={onCancel}>
            <FormattedMessage id="Cancel" />
          </p>
          <Button className="remove_btn" onClick={onOk}>
            <FormattedMessage id="Continue" />
            <DeleteOutline />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
}

DeleteConfirmModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isOpenModal: PropTypes.bool,
};

DeleteConfirmModal.defaultProps = {
  isOpenModal: false,
  title: '',
  subtitle: '',
};

export default DeleteConfirmModal;
