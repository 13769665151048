import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

const TagComponent = ({ isTagActive, tag, note }) => {
  const [tooltip, setTooltip] = useState(note);
  const [active] = useState(isTagActive);

  useEffect(() => {
    setTooltip(note);
  }, [note]);

  return (
    <>
      <Tooltip placement="top" title={active ? tooltip : ''}>
        <div
          className="tag_item add_padding"
          key={tag.id}
          style={active ? { color: tag.textColor, backgroundColor: tag.buttonColor } : {}}
        >
          {tag.name}
        </div>
      </Tooltip>
    </>
  );
};

TagComponent.propTypes = {
  note: PropTypes.string.isRequired,
  isTagActive: PropTypes.bool.isRequired,
  tag: PropTypes.object.isRequired,
};

export default TagComponent;
