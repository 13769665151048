import React, { useEffect } from 'react';
import { Button } from 'antd';
import { ArrowBack } from '@material-ui/icons';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as data from '../../../package.json';
import { connectVersion, versionActionCreators } from '../../core';
import { promisify } from '../../utilities';

const Version = ({ versionBack, getVersion, history }) => {
  const currentVersionApp = data.version;
  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    promisify(getVersion, {});
  }, []);

  return (
    <div className="version_page_wrapper">
      <div>
        <p className="title">Version frontend: {currentVersionApp}</p>
        <p className="title">Version backend: {versionBack}</p>
        <Button onClick={handleGoBack}>
          <ArrowBack />
          <p>Go back</p>
        </Button>
      </div>
    </div>
  );
};

Version.propTypes = {
  getVersion: PropTypes.func.isRequired,
  versionBack: PropTypes.string,
  history: PropTypes.object.isRequired,
};

Version.defaultProps = {
  versionBack: '',
};

const mapStateToProps = ({ version }) => ({
  versionBack: version.version,
});

const mapDispatchToProps = dispatch => {
  const { getVersion } = versionActionCreators;

  return bindActionCreators(
    {
      getVersion,
    },
    dispatch,
  );
};

export default compose(injectIntl, withRouter, connectVersion(mapStateToProps, mapDispatchToProps))(Version);
