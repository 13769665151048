import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';
import { Button, Form, Icon, Input, Select, Spin } from 'antd';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import { ArrowDropDown, ArrowForward } from '@material-ui/icons';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connectCustomer, connectOrder, customerActionCreators, orderActionCreators } from 'core';
import ClientSelect from 'components/Main/Claim/ClientSelect';
import OrdersTable from 'components/Main/Claim/OrdersTable';
import SingleClaimModal from 'components/Main/Claim/SingleClaimModal';
import OrderDetailModal from 'components/Main/Order/OrderDetailModal';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { promisify } from 'utilities';
import toast from 'components/Basic/Toast';

const antIcon = <Icon spin style={{ fontSize: 24 }} type="loading" />;
const { Option } = Select;

class Claim extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      callerId: null,
      isSearch: false,
      orderDetail: null,
      isOpenOrderModal: false,
      isOpenDetailModal: false,
      orderList: [],
      customerList: [],
      clientId: 0,
      isValidNumber: true,
      isLoading: false,
      filterString: '',
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.getCustomers(`${values.caller_id}`);
      } else if (err.caller_id) {
        if (form.getFieldValue('search_type') === 'telephone') {
          toast.error({
            title: this.props.intl.formatMessage({ id: 'It seems that the phone number entered is invalid' }),
          });
        }
      }
    });
  };

  getCustomers = searchStr => {
    const { form } = this.props;
    this.setState({ isLoading: true, clientId: '' });
    let filterString = '';
    if (form.getFieldValue('search_type') === 'order') {
      filterString = `&filter=or.id eq ${searchStr}&filter=or.order eq ${searchStr}`;
      promisify(this.props.getMyOrder, {
        offset: 0,
        limit: 10,
        filter: filterString,
      })
        .then(res => {
          if (res && res.length !== 0) {
            this.setState({
              isSearch: true,
              customerList: [{ ...res[0].customer }],
            });
          } else {
            this.setState({
              isSearch: false,
            });
          }
          this.setState({ orderList: res, isLoading: false });
        })
        .catch(() => {
          this.setState({
            isSearch: false,
            isLoading: false,
          });
        });
    } else {
      if (form.getFieldValue('search_type') === 'telephone') {
        const phoneNumber = searchStr
          .replace(/-/g, '')
          .replace('(', '')
          .replace(')', '')
          .replace(/ /g, '');
        filterString = `filter=phone like %25${phoneNumber.slice(1)}%25`;
      } else if (form.getFieldValue('search_type') === 'name') {
        filterString = `filter=fullName iLike %25${searchStr}%25`;
      }

      promisify(this.props.getCustomerList, {
        filter: filterString,
      })
        .then(res => {
          this.setState({ isLoading: false });
          if (res && res.length !== 0) {
            this.setState({
              isSearch: true,
              customerList: res,
            });
            this.handleClientSelect(res[0].id);
          } else {
            this.setState({
              isSearch: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            isSearch: false,
            isLoading: false,
          });
        });
    }
    this.setState({ filterString });
  };

  handleSearch = e => {
    const { form } = this.props;
    if (e.keyCode === 13) {
      const callerId = form.getFieldValue('caller_id');
      this.setState({ callerId });
      if (
        form.getFieldValue('search_type') === 'telephone' &&
        callerId !== '' &&
        this.validatePhoneNumber(`${callerId}`)
      ) {
        this.getCustomers(`${callerId}`);
      } else {
        this.getCustomers(`${callerId}`);
      }
    }
  };

  handleChangeCaller = value => {
    this.setState({
      callerId: value,
    });
  };

  handleSelectOrder = orderInfo => {
    if (orderInfo.state === 'AD') {
      this.setState({ orderDetail: orderInfo, isOpenOrderModal: true });
    } else {
      this.setState({ orderDetail: orderInfo, isOpenDetailModal: true });
      // toast.error({
      //   title: this.props.intl.formatMessage({ id: "You can't claim" })
      // });
    }
  };

  handleBack = () => {
    this.props.history.push({
      pathname: '/ots/claim',
      state: { type: 'search' },
    });
  };

  handleClientSelect = (id, filterDate = null) => {
    this.setState({ clientId: id, orderList: [] });
    const { filterString } = this.state;
    const { form } = this.props;
    promisify(this.props.getMyOrder, {
      offset: 0,
      limit: 10,
      filter: `&filter=customer eq ${id}${filterDate || ''}${
        form.getFieldValue('search_type') === 'order' ? filterString : ''
      }`,
    }).then(res => {
      this.setState({ orderList: res });
    });
  };

  handleChangeDate = dateString => {
    const { clientId } = this.state;
    if (dateString) {
      const start = moment(dateString)
        .tz('America/Guatemala')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
      const end = moment(dateString)
        .tz('America/Guatemala')
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
      const filterDate = `&filter=dueTo between ${start} ${end}`;
      this.handleClientSelect(clientId, filterDate);
    } else {
      this.handleClientSelect(clientId, null);
    }
  };

  validatePhoneNumber = phoneNumber => {
    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
      const countryCode = number.getCountryCode();
      if (countryCode === 1 || (countryCode >= 502 && countryCode <= 506)) {
        valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
      } else {
        return false;
      }
    } catch (e) {
      valid = false;
    }
    return valid;
  };

  checkPhoneNumber = (rule, value, callback) => {
    if (!this.validatePhoneNumber(`${value}`)) {
      this.setState({ isValidNumber: false });
      callback('  ');
    } else {
      this.setState({ isValidNumber: true });
      callback();
    }
  };

  handleChangeSearchType = () => {
    const { form } = this.props;
    form.setFieldsValue({ caller_id: '' });
  };

  checkValue = (rule, value, callback) => {
    const { form } = this.props;
    if (form.getFieldValue('search_type') === 'name' && value.length > 3) {
      return callback();
    }
    if (form.getFieldValue('search_type') === 'order') {
      return callback();
    }
    if (form.getFieldValue('search_type') === 'name') {
      callback('invalid');
    }
    return null;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { form } = this.props;
    const { isSearch, orderList, isLoading, isValidNumber, customerList, clientId } = this.state;

    return (
      <div className="claim_layout scrollbar">
        <div className="main_content scrollbar">
          <div className="left_panel">
            <p className="title header_title">
              <FormattedMessage id="McDonald’s order taking interface" />
            </p>
            <div className="left_content_wrapper">
              <div className="caller_id_label">
                <p className="title">
                  <FormattedMessage id="Order search" />
                </p>
                <p className="description">
                  <FormattedMessage id="Client recognition" />
                </p>
              </div>
              <Form className="caller_form" onSubmit={this.handleSubmit}>
                <div className="search_order_wrapper">
                  <Form.Item className="order_search_type_wrapper">
                    {getFieldDecorator('search_type', {
                      rules: [],
                      initialValue: 'telephone',
                    })(
                      <Select
                        className="search_type"
                        onChange={this.handleChangeSearchType}
                        style={{ width: 120 }}
                        suffixIcon={<ArrowDropDown />}
                      >
                        <Option value="telephone">
                          <FormattedMessage id="Telephone" />
                        </Option>
                        <Option value="order">
                          <FormattedMessage id="Order No." />
                        </Option>
                        <Option value="name">
                          <FormattedMessage id="Name" />
                        </Option>
                      </Select>,
                    )}
                  </Form.Item>
                  {form.getFieldValue('search_type') === 'telephone' && (
                    <Form.Item className={`caller_id_input ${isValidNumber ? '' : 'invalid_phone'}`}>
                      {getFieldDecorator('caller_id', {
                        rules: [
                          {
                            required: true,
                            message: this.props.intl.formatMessage({ id: 'Enter a phone number' }),
                          },
                          {
                            validator: this.checkPhoneNumber,
                          },
                        ],
                      })(
                        <ReactPhoneInput
                          defaultCountry="gt"
                          onChange={this.handleChangeCaller}
                          // disableCountryCode
                          onKeyDown={this.handleSearch}
                          onlyCountries={['us', 'gt', 'bz', 'sv', 'hn', 'ni', 'cr']}
                          placeholder={this.props.intl.formatMessage({ id: 'Enter a phone number' })}
                          value={this.state.callerId}
                        />,
                      )}
                    </Form.Item>
                  )}
                  {form.getFieldValue('search_type') !== 'telephone' && (
                    <Form.Item className="caller_id">
                      {getFieldDecorator('caller_id', {
                        rules: [
                          {
                            required: true,
                            message: this.props.intl.formatMessage({ id: 'Enter the search string' }),
                          },
                          { validator: this.checkValue },
                        ],
                      })(
                        <Input
                          onChange={this.handleChangeCaller}
                          onKeyDown={this.handleSearch}
                          placeholder={this.props.intl.formatMessage({ id: 'Enter the search string' })}
                          value={this.state.callerId}
                        />,
                      )}
                    </Form.Item>
                  )}
                </div>
                <Form.Item>
                  <Button
                    disabled={
                      !this.state.callerId ||
                      (form.getFieldValue('search_type') === 'name' && form.getFieldValue('caller_id').length < 4)
                    }
                    htmlType="submit"
                  >
                    <p>
                      <FormattedMessage id="Load details" />
                    </p>
                    <ArrowForward />
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className="right_panel">
            <Spin indicator={antIcon} spinning={this.state.isLoading}>
              {!isSearch && (
                <div className="empty_screen">
                  <div className="empty_wrapper">
                    <p className="title">
                      <FormattedMessage id="Customer details" />
                    </p>
                    <p className="subtitle">
                      <FormattedMessage id="Waiting for details" />
                    </p>
                  </div>
                </div>
              )}
              {isSearch && !isLoading && (
                <>
                  <ClientSelect
                    customerList={customerList}
                    isNewOrder={false}
                    onClientSelect={this.handleClientSelect}
                  />
                  <OrdersTable
                    onDateChange={this.handleChangeDate}
                    onSelectOrder={this.handleSelectOrder}
                    orderData={orderList || []}
                  />
                </>
              )}
            </Spin>
          </div>
        </div>
        <SingleClaimModal
          isOpenModal={this.state.isOpenOrderModal}
          onCloseModal={() => this.setState({ isOpenOrderModal: false })}
          onUpdatedOrder={() => this.handleClientSelect(clientId, null)}
          orderDetail={this.state.orderDetail}
        />
        <OrderDetailModal
          isOpenModal={this.state.isOpenDetailModal}
          onCloseModal={() => this.setState({ isOpenDetailModal: false })}
          orderDetail={this.state.orderDetail}
        />
      </div>
    );
  }
}

Claim.propTypes = {
  form: PropTypes.object,
  history: PropTypes.object,
  intl: intlShape.isRequired,
  getCustomerList: PropTypes.func.isRequired,
  getMyOrder: PropTypes.func.isRequired,
};

Claim.defaultProps = {
  form: {},
  history: {},
};

const mapStateToProps = ({ customer }) => ({
  addressList: customer.addressList,
});

const mapDispatchToProps = dispatch => {
  const { getCustomerList } = customerActionCreators;
  const { getMyOrder } = orderActionCreators;

  return bindActionCreators(
    {
      getCustomerList,
      getMyOrder,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  withRouter,
  Form.create({ name: 'caller_form' }),
  connectCustomer(mapStateToProps, mapDispatchToProps),
  connectOrder(mapStateToProps, mapDispatchToProps),
)(Claim);
