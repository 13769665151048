import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ArrowForward } from '@material-ui/icons';
import confirmImg from 'assets/img/confirm.svg';

class ClaimConfirm extends PureComponent {
  render() {
    return (
      <div className="claim_confirm_layout">
        <div className="claim_confirm_wrapper">
          <img alt="confirm claim" src={confirmImg} />
          <h3>
            <FormattedMessage id="We are sorry for the inconvenience Miriam, your claim has been processed with success and we will contact you soon." />
          </h3>
          <Button>
            <FormattedMessage id="Place new order" />
            <ArrowForward />
          </Button>
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(ClaimConfirm);
