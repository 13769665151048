import { connect } from 'react-redux';
import { catalogActionCreators } from 'core/modules/catalog/actions';

function mapStateToProps({ catalog }) {
  return {
    catalog,
  };
}

const mapDispatchToProps = catalogActionCreators;

export function connectCatalog(configMapStateToProps = mapStateToProps) {
  return connect(configMapStateToProps, mapDispatchToProps);
}
