import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import Img from 'react-image';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Form, Icon, Input, Spin } from 'antd';
import { ArrowForward, LockOutlined, MailOutline } from '@material-ui/icons';
import { authActionCreators, connectAuth } from 'core';
import { promisify } from 'utilities';
import logoImg from 'assets/img/logo.svg';
import { getCountry } from 'utilities/common';

const antIcon = <Icon spin style={{ fontSize: 24 }} type="loading" />;

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errMsg: '',
    };
  }

  componentWillMount() {
    const { agent } = this.props;
    if (agent) {
      this.props.history.push('/ots/order/new');
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        await promisify(this.props.login, { ...values, eid: values.eid.trim() })
          .then(() => {
            this.setState({ isLoading: false });
            this.props.history.push('/ots/order/new');
          })
          .catch(error => {
            if (error.msg) {
              this.setState({ errMsg: error.msg });
            } else {
              this.setState({ errMsg: 'Cannot connect to internet' });
            }
            this.setState({ isLoading: false });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login_layout">
        <Spin indicator={antIcon} spinning={this.state.isLoading}>
          <div className="left_wrapper">
            <div className="logo-wrapper">
              <Img alt="logo" src={logoImg} />
            </div>
            <div className="login_wrapper">
              <p className="title">
                <FormattedMessage id="Order Taking system" />
              </p>
              <p className="description">{`${getCountry()} / OTS`}</p>
              <Form className="login-form" onSubmit={this.handleSubmit}>
                <Form.Item>
                  {getFieldDecorator('eid', {
                    rules: [
                      { required: true, message: this.props.intl.formatMessage({ id: 'Please input user code!' }) },
                    ],
                  })(
                    <Input placeholder={this.props.intl.formatMessage({ id: 'User code' })} prefix={<MailOutline />} />,
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: this.props.intl.formatMessage({ id: 'Please input your Password!' }) },
                    ],
                  })(
                    <Input
                      placeholder={this.props.intl.formatMessage({ id: 'Enter an access password' })}
                      prefix={<LockOutlined />}
                      type="password"
                    />,
                  )}
                </Form.Item>
                {this.state.errMsg && (
                  <p className="error_msg">
                    <FormattedMessage id={`${this.state.errMsg}`} />
                  </p>
                )}
                <Form.Item className="login-form-btn">
                  <Button htmlType="submit">
                    <FormattedMessage id="To access" />
                    <ArrowForward />
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="footer">
              {/* <div className="divider" />
              <a className="forgot_password" href="/forgot">
                <FormattedMessage id="login.forgot.password" />
              </a> */}
              <p className="copy_right">
                <FormattedMessage id={`McDonald’s © {year}`} values={{ year: new Date().getFullYear() }} />
              </p>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.object,
  form: PropTypes.object,
  agent: PropTypes.object,
  intl: intlShape.isRequired,
  login: PropTypes.func.isRequired,
};

Login.defaultProps = {
  history: {},
  form: {},
  agent: null,
};

const mapStateToProps = ({ auth }) => ({
  agent: auth.agent,
});

const mapDispatchToProps = dispatch => {
  const { login } = authActionCreators;

  return bindActionCreators(
    {
      login,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  withRouter,
  connectAuth(mapStateToProps, mapDispatchToProps),
  Form.create({ name: 'login-form' }),
)(Login);
