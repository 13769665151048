/* eslint-disable no-unused-vars */
import { all, call, fork, put, take } from 'redux-saga/effects';
import { authActionCreators } from 'core/modules/auth/actions';
import { GET_SETTINGS_LIST_REQUEST, settingsActionCreators } from 'core/modules/settings/actions';
import { restService } from 'utilities';

export function* asyncGetSettingsListRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `/settings`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      yield put(
        settingsActionCreators.getSettingsListSuccess({
          list: response.data.rows,
        }),
      );
      resolve(response.data.rows);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}

export function* watchSettingsListRequest() {
  while (true) {
    const action = yield take(GET_SETTINGS_LIST_REQUEST);
    yield* asyncGetSettingsListRequest(action);
  }
}

export default function*() {
  yield all([fork(watchSettingsListRequest)]);
}
