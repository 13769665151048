import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import NavBar from 'containers/Main/NavBar';
import Claim from 'containers/Main/Claim/Claim';
import ClaimConfirm from 'containers/Main/Claim/ClaimConfirm';
import NewOrder from 'containers/Main/Order/NewOrder';
import Order from 'containers/Main/Order/Order';
import OrderConfirm from 'containers/Main/Order/OrderConfirm';

class Main extends PureComponent {
  render() {
    return (
      <div className="main_layout">
        <NavBar />
        <div className="content">
          <Switch>
            <Route component={Claim} exact path="/ots/claim" />
            <Route component={ClaimConfirm} exact path="/ots/claim/confirm" />
            <Route component={NewOrder} exact path={['/ots/order/new', '/ots/order/new/:phone']} />
            <Route component={OrderConfirm} exact path="/ots/order/confirm" />
            <Route component={Order} exact path="/ots/order" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Main;
