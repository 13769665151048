import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from 'core/modules/auth/actions';
import { address, auth, catalog, customer, order, region, restaurant, settings, version } from 'core/modules';
import { resetReducer } from 'core/modules/reset';

const appReducer = combineReducers({
  auth,
  catalog,
  region,
  address,
  order,
  customer,
  restaurant,
  settings,
  version,
});

export default function rootReducer(state, action) {
  let finalState = appReducer(state, action);

  if (action.type === LOGOUT_SUCCESS) {
    finalState = resetReducer(finalState, action);
  }

  return finalState;
}
