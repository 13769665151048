import { GET_MY_ADDRESS_SUCCESS, SET_INITIAL_ADDRESS_VALUE_SUCCESS } from 'core/modules/address/actions';
import { initialState } from 'core/modules/initialState';

export default function address(state = initialState.address, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_ADDRESS_VALUE_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case GET_MY_ADDRESS_SUCCESS: {
      return {
        ...state,
        list: payload.list,
      };
    }
    default: {
      return state;
    }
  }
}
