import {
  GET_MY_ORDER_SUCCESS,
  GET_SPECIAL_ORDERS_SUCCESS,
  SET_INITIAL_ORDER_VALUE_SUCCESS,
} from 'core/modules/order/actions';
import { initialState } from 'core/modules/initialState';

export default function order(state = initialState.order, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_ORDER_VALUE_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case GET_MY_ORDER_SUCCESS: {
      return {
        ...state,
        list: payload.list,
      };
    }
    case GET_SPECIAL_ORDERS_SUCCESS: {
      return {
        ...state,
        specialOrders: payload.specialOrders,
      };
    }
    default: {
      return state;
    }
  }
}
