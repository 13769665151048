import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { Tooltip } from 'antd';
import { catalogActionCreators, connectCatalog, connectOrder } from 'core';
import placeHolderImg from 'assets/img/placeholder.svg';
import toast from 'components/Basic/Toast';
import { getCurrency } from 'utilities/common';
import { CustomImg } from 'components/Basic/CustomImg';

class FoodList extends PureComponent {
  showSingleProduct = product => {
    const {
      stocks,
      specialOrders,
    } = this.props;

    if (specialOrders.findIndex(s => s.item === product.id) !== -1) {
      toast.error({
        title: this.props.intl.formatMessage({ id: 'HasOrderedSpecialProductError' }),
      });
    } else if (stocks.findIndex(s => s.productCode === (product.productCode || product.defaultCode)) !== -1) {
      toast.error({
        title: this.props.intl.formatMessage({
          id: 'Oops ... The restaurant that will take your order does not have this product available at this time.',
        }),
      });
    } else {
      this.props.onSingleDetail(product.id);
    }
  };

  render() {
    const {
      products,
      prices,
      availableCatalogs,
      specialOrders,
    } = this.props;

    if (!availableCatalogs || availableCatalogs.length === 0) {
      return null;
    }

    return (
      <div className="food_list_layout">
        {products &&
          products.map(item => {
            return (
              <div
                className={`food_item ${
                  specialOrders.findIndex(s => s.item === item.id) !== -1 
                    ? 'inactive'
                    : ''
                }`}
                key={Math.random()}
                onClick={() => this.showSingleProduct(item)}
              >
                <Tooltip
                  placement="top"
                  title={
                    <div className="food_content">
                      <p className="name" dangerouslySetInnerHTML={{ __html: item.name }} />
                      <p className="source">{item.description}</p>
                    </div>
                  }
                >
                  <div className="food_item_wrapper">
                    <div className="food_img">
                      <CustomImg
                        src={
                          item.productCode !== undefined
                            ? `${process.env.REACT_APP_S3}/${
                                item.productCode > 0 ? item.productCode : item.defaultCode
                              }.jpg`
                            : placeHolderImg
                        }
                      />
                    </div>
                    <div className="food_content">
                      <p className="name" dangerouslySetInnerHTML={{ __html: item.name }} />
                      {prices && (
                        <p className="amount">
                          {getCurrency().symbol}
                          {item.defaultPrice / 100}
                        </p>
                      )}
                    </div>
                  </div>
                </Tooltip>
              </div>
            );
          })}
      </div>
    );
  }
}

FoodList.propTypes = {
  intl: intlShape.isRequired,
  products: PropTypes.array,
  prices: PropTypes.array,
  selectedCatalogId: PropTypes.number,
  selectedPartDay: PropTypes.string,
  partDay: PropTypes.string,
  availableCatalogs: PropTypes.array,
  stocks: PropTypes.array,
  specialOrders: PropTypes.array,
  onSingleDetail: PropTypes.func.isRequired,
};

FoodList.defaultProps = {
  prices: [],
  products: [],
  selectedCatalogId: 0,
  selectedPartDay: '',
  partDay: '',
  availableCatalogs: [],
  stocks: [],
  specialOrders: [],
};

const mapDispatchToProps = dispatch => {
  const { getScheduleSuperCatalogs } = catalogActionCreators;

  return bindActionCreators(
    {
      getScheduleSuperCatalogs,
    },
    dispatch,
  );
};

const mapStateToProps = ({ catalog, order }) => ({
  prices: catalog.prices,
  stocks: catalog.stocks,
  dueTo: order.dueTo,
  partDay: order.partDay,
});

export default compose(
  injectIntl,
  withRouter,
  connectCatalog(mapStateToProps, mapDispatchToProps),
  connectOrder(mapStateToProps, mapDispatchToProps),
)(FoodList);
