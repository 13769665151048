import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button, Icon, Select, TimePicker, Tooltip } from 'antd';
import { ArrowForward, ControlPointDuplicate, DateRange, Edit, WarningOutlined } from '@material-ui/icons';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
  addressActionCreators,
  connectAddress,
  connectCatalog,
  connectCustomer,
  connectOrder,
  connectRegion,
  connectRestaurant,
  customerActionCreators,
  orderActionCreators,
  regionActionCreators,
  restaurantActionCreators,
} from 'core';
import { promisify } from 'utilities';
import DeliveryUpdateModal from 'components/Main/Claim/DeliveryUpdateModal';
import DeleteConfirmModal from 'components/Basic/DeleteConfirmModal';
import toast from 'components/Basic/Toast';
import { HOUR_FINISH_BREAKFAST, MINUTES_FINISH_BREAKFAST } from '../../../utilities/constants';

const { Option } = Select;

class AvailableAddress extends PureComponent {
  constructor(props) {
    super(props);
    this.days = [];
    const now = moment().tz('America/Guatemala');

    this.state = {
      deliveryAddress: {},
      isAddressOpenModal: false,
      selectedDay: moment(now, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      selectedTime: '',
      isCreateAddress: true,
      timeSlots: [],
      selectedTimeSlots: [],
      deliveryAreas: [],
      isConfirmModal: false,
      isLoading: false,
    };
    promisify(this.props.setInitialOrder, { dueTo: this.getDueTo() });
  }

  componentDidMount() {
    this.loadInitialData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientId !== this.props.clientId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ deliveryAddress: {} });
    }
  }

  loadInitialData = async () => {
    await promisify(this.props.getDeliveryAreas, {})
      .then(res => {
        this.setState({ deliveryAreas: res });
      })
      .catch(e => {
        console.log('e', e);
      });
  };

  handleSelectAddress = item => {
    this.days = [];
    this.setState({
      deliveryAddress: item,
      selectedTimeSlots: [],
      isLoading: false,
    });

    if (item.belongsToDeliveryArea || item.restaurant) {
      promisify(this.props.setInitialAddress, { selectedAddress: item });
      promisify(this.props.getRestaurantTimeSlots, {
        restaurant: item.restaurant,
        lat: item.lat,
        long: item.long,
      }).then(res => {
        if (res.timeSlots && Array.isArray(res.timeSlots)) {
          res.timeSlots.forEach(t => {
            // eslint-disable-next-line no-param-reassign
            t.time = moment(t.time)
              .add(15, 'minutes')
              .toDate();
          });
          const maxDueTo = moment(res.maxDueTo);
          const minDueTo = moment(res.minDueTo);
          const diffDays = maxDueTo.diff(minDueTo, 'days') + 1;
          const now = moment().tz('America/Guatemala');
          for (let i = 0; i < diffDays; i += 1) {
            const today = moment(now, 'YYYY-MM-DD');
            const changedDate = today.add('days', i);
            this.days.push({
              label: i === 0 ? 'Hoy' : changedDate.format('DD'),
              value: changedDate.format('YYYY-MM-DD'),
            });
          }
          if (this.days.length !== 0) {
            this.setState({
              timeSlots: res.timeSlots,
            });
            let todayTimeSlots = res.timeSlots.filter(
              t =>
                moment(t.time)
                  .tz('America/Guatemala')
                  .format('YYYY-MM-DD') === this.days[0].value,
            );
            if (todayTimeSlots.length === 0 && res.timeSlots.length !== 0 && this.days.length > 1) {
              todayTimeSlots = res.timeSlots.filter(
                t =>
                  moment(t.time)
                    .tz('America/Guatemala')
                    .format('YYYY-MM-DD') === this.days[1].value,
              );
              this.setState({
                selectedDay: this.days[1].value,
              });
            } else {
              this.setState({
                selectedDay: this.days[0].value,
              });
            }
            if (todayTimeSlots.length !== 0) {
              this.setState({
                selectedTimeSlots: todayTimeSlots,
                selectedTime: moment(todayTimeSlots[0].time)
                  .tz('America/Guatemala')
                  .format('HH:mm'),
              });
            } else {
              this.setState({
                selectedTimeSlots: [],
                selectedTime: '',
              });
            }
          }
        }
      });
    } else if (item.belongsToDeliveryArea && !item.restaurant) {
      this.populateRegion(item);
    }
  };

  populateRegion = async item => {
    let restaurantInfo = {};
    let zoneInfo = null;
    const params = {
      id: item.id,
      customer: item.customer,
    };
    this.setState({ isLoading: true });
    await promisify(this.props.getBestRestaurants, {
      lat: item.lat,
      long: item.long,
    })
      .then(res => {
        restaurantInfo = res;
      })
      .catch(error => {
        this.setState({ isLoading: false });
        if (error && error.status === 406) {
          toast.error({
            title: this.props.intl.formatMessage({ id: `${error.data.type}` }),
          });
        }
      });
    if (restaurantInfo && restaurantInfo.zones && restaurantInfo.zones.length !== 0) {
      await promisify(this.props.getZone, {
        id: restaurantInfo.zones[0].id,
      }).then(res => {
        zoneInfo = res.data;
        if (restaurantInfo && restaurantInfo.zones && restaurantInfo.zones.length !== 0 && zoneInfo.deptCode) {
          params.department = zoneInfo.deptCode;
        }

        if (restaurantInfo && restaurantInfo.zones && restaurantInfo.zones.length !== 0 && zoneInfo.municipalityCode) {
          params.municipality = zoneInfo.municipalityCode;
        }

        if (restaurantInfo && restaurantInfo.zones && restaurantInfo.zones.length !== 0) {
          params.zone = restaurantInfo.zones[0].id;
        }
        if (restaurantInfo.id) {
          params.restaurant = restaurantInfo.id;
        }
        this.updateDeliveryAddress(item, params);
      });
    } else if (restaurantInfo.id) {
      params.restaurant = restaurantInfo.id;
      this.updateDeliveryAddress(item, params);
    } else {
      this.setState({ isLoading: false });
    }
  };

  updateDeliveryAddress = (item, params) => {
    if (params.restaurant) {
      promisify(this.props.updateMyAddress, {
        ...params,
      })
        .then(updatedData => {
          promisify(this.props.setInitialAddress, {
            selectedAddress: updatedData.data,
          });
          promisify(this.props.getCustomerAddress, {
            id: item.customer,
            pendingOrders: true,
          }).then(() => {
            this.handleSelectAddress(updatedData.data);
            this.setState({ isLoading: false });
          });
        })
        .catch(error => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleEditAddress = item => {
    this.setState({
      isAddressOpenModal: true,
      isCreateAddress: false,
      deliveryAddress: item,
    });
  };

  handleCreateAddress = item => {
    if (item.belongsToDeliveryArea) {
      this.setState({
        isAddressOpenModal: true,
        isCreateAddress: true,
        deliveryAddress: item,
      });
    }
  };

  handleStartOrder = () => {
    if (this.state.deliveryAddress && this.state.deliveryAddress.restaurant) {
      const params = {
        channel: 'OTS',
        restaurant: this.state.deliveryAddress.restaurant,
        customer: this.state.deliveryAddress.customer,
        deliveryAddress: this.state.deliveryAddress,
        type: 'S',
        dueTo: this.getDueTo(),
      };
      this.props.onStartOrder();
      const dueTo = this.getDueTo();
      promisify(this.props.setInitialOrder, { dueTo, partDay: this.getPartDay(dueTo) });
      promisify(this.props.checkMyOrder, { params })
        .then(() => {
          this.props.onContinue();
          this.props.history.push('/ots/order');
        })
        .catch(error => {
          this.props.onError();
          if (error) {
            if (error.status === 406) {
              if (error.data.type === 'EmptyOrderError') {
                this.props.history.push('/ots/order');
              } else if (error.data.type === 'BusyServiceError') {
                this.handleSelectAddress(this.state.deliveryAddress);
              } else {
                toast.error({
                  title: this.props.intl.formatMessage({ id: `${error.data.type}` }),
                });
              }
            }
          }
        });
    }
  };

  getDueTo = () => {
    const { selectedDay, selectedTime } = this.state;
    const dueTo = moment(`${selectedDay} ${selectedTime}`)
      .tz('America/Guatemala')
      .toString();
    return dueTo;
  };

  getPartDay = date => {
    const dueToHours = new Date(date).getHours();
    const duoToMinutes = new Date(date).getMinutes();

    if (
      dueToHours < HOUR_FINISH_BREAKFAST ||
      (dueToHours === HOUR_FINISH_BREAKFAST && duoToMinutes < MINUTES_FINISH_BREAKFAST)
    ) {
      return 'breakfasts';
    }
    return 'dinner';
  };

  handleChangeDays = value => {
    const { timeSlots } = this.state;
    const selectedTimeSlots = timeSlots.filter(
      t =>
        moment(t.time)
          .tz('America/Guatemala')
          .format('YYYY-MM-DD') === value,
    );
    this.setState({
      timeSlots,
      selectedTimeSlots,
      selectedDay: value,
      selectedTime:
        selectedTimeSlots.length === 0
          ? ''
          : moment(selectedTimeSlots[0].time)
              .tz('America/Guatemala')
              .format('HH:mm'),
    });
  };

  handleChangeTime = (time, timeString) => {
    this.setState({ selectedTime: timeString });
  };

  handleUpdatedAddress = item => {
    this.setState({ deliveryAddress: item });
    this.handleSelectAddress(item);
  };

  handleDeleteAddress = () => {
    this.setState({
      isConfirmModal: false,
      deliveryAddress: {},
      selectedTimeSlots: [],
      isLoading: false,
    });
    this.props.onDeleteAddress(this.state.selectedAddress);
  };

  render() {
    const { addressList, clientId, restaurants, customerList } = this.props;
    const user = customerList.find(c => c.id === clientId);

    const { deliveryAddress, selectedTimeSlots, selectedDay, selectedTime, deliveryAreas, isLoading } = this.state;
    const disabledHours = [];
    const disabledMinutes = [];
    let dueTo = '';
    let startHour = -1;
    let endHour = -1;
    let startMinute = -1;
    let serviceTime;
    if (selectedTimeSlots.length !== 0) {
      startHour = moment(selectedTimeSlots[0].time)
        .tz('America/Guatemala')
        .format('H');
      endHour = moment(selectedTimeSlots[selectedTimeSlots.length - 1].time)
        .tz('America/Guatemala')
        .format('H');
      startMinute = moment(selectedTimeSlots[0].time)
        .tz('America/Guatemala')
        .format('m');
      for (let i = 0; i < 24; i += 1) {
        if (i < parseInt(startHour, 10) || i > parseInt(endHour, 10)) {
          disabledHours.push(i);
        }
      }

      for (let i = 0; i < 60; i += 15) {
        if (i < parseInt(startMinute, 10)) {
          disabledMinutes.push(i);
        }
      }

      serviceTime = selectedTimeSlots[0].timeService;
    }
    let restaurantInfo = {};
    if (deliveryAddress && deliveryAddress.restaurant) {
      restaurantInfo = restaurants.find(r => r.id === deliveryAddress.restaurant) || {};
      if (serviceTime) restaurantInfo.timeService = serviceTime;
    }

    if (selectedDay && selectedTime) {
      dueTo = this.getDueTo();
    }

    return (
      <div className="available_list_wrapper">
        {user && user.state !== 'I' && addressList && addressList.length !== 0 && (
          <div className="list_header_wrapper">
            <p className="p-medium">
              <FormattedMessage id="Available addresses" />
            </p>
            <Button
              onClick={() => this.setState({ isAddressOpenModal: true, deliveryAddress: {}, isCreateAddress: true })}
            >
              <FormattedMessage id="New address" />
              <ArrowForward />
            </Button>
          </div>
        )}
        <div className="list_wrapper scrollbar">
          {user && user.state !== 'I' && (!addressList || addressList.length === 0) && (
            <div className="empty_list">
              <FormattedMessage id="New customer with no addresses available" />
              <Button
                disabled={user && user.state === 'I'}
                onClick={() => this.setState({ isAddressOpenModal: true, deliveryAddress: {}, isCreateAddress: true })}
              >
                <FormattedMessage id="New address" />
                <ArrowForward />
              </Button>
            </div>
          )}
          {user &&
            user.state !== 'I' &&
            addressList.map(item => (
              <div
                className={`item ${
                  item.id === deliveryAddress.id && (deliveryAddress.restaurant || item.belongsToDeliveryArea)
                    ? 'active-item'
                    : ''
                }`}
                key={item.id}
                onClick={() => user.state !== 'I' && this.handleSelectAddress(item)}
              >
                <div className="address-edit-btn-wrapper" style={{ minWidth: 60 }}>
                  {isLoading && item.id === deliveryAddress.id && <Icon spin type="sync" />}
                  {!isLoading && (
                    <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'Edit address' })}>
                      <Edit className="edit-button" onClick={() => this.handleEditAddress(item)} />
                    </Tooltip>
                  )}
                  {!isLoading && (
                    <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'New from this' })}>
                      <ControlPointDuplicate
                        className={`${
                          !item.restaurant && !item.belongsToDeliveryArea
                            ? 'disabled new-address-button'
                            : 'new-address-button'
                        }`}
                        onClick={() => this.handleCreateAddress(item)}
                      />
                    </Tooltip>
                  )}
                </div>
                <p
                  className={`address_name ${!item.restaurant && !item.belongsToDeliveryArea ? 'invalid_address' : ''}`}
                >
                  {item.address}
                </p>
                <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'Add order to order in process' })}>
                  {item.pendingOrders ? (
                    <div className="add_order" onClick={() => this.props.onGroupOrder(item)}>
                      <ArrowForward />
                    </div>
                  ) : null}
                </Tooltip>
                <Icon
                  className="delete-icon"
                  onClick={() => this.setState({ isConfirmModal: true, selectedAddress: item })}
                  type="close"
                />
              </div>
            ))}
        </div>
        <div
          className={`list_footer ${
            addressList &&
            addressList.length !== 0 &&
            deliveryAddress &&
            deliveryAddress.id &&
            (deliveryAddress.restaurant || deliveryAddress.belongsToDeliveryArea)
              ? 'show'
              : 'hide'
          }`}
        >
          <p className="p-medium restaurant_note_wrapper">
            <FormattedMessage id="Delivery date and time" />
            {(restaurantInfo.promotionObservation ||
              restaurantInfo.productObservation ||
              restaurantInfo.serviceObservation) && (
              <Tooltip
                placement="top"
                title={
                  <div>
                    <p style={{ fontSize: 14, fontWeight: 'bold', color: 'white', marginBottom: 17 }}>
                      <FormattedMessage id="Restaurant notes" />
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: (restaurantInfo.promotionObservation || '').replace(/\\n/g, '</br>'),
                      }}
                      style={{ fontSize: 11.5, color: '#B8B8B8' }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: (restaurantInfo.productObservation || '').replace(/\\n/g, '</br>'),
                      }}
                      style={{ fontSize: 11.5, color: '#B8B8B8' }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: (restaurantInfo.serviceObservation || '').replace(/\\n/g, '</br>'),
                      }}
                      style={{ fontSize: 11.5, color: '#B8B8B8' }}
                    />
                  </div>
                }
              >
                <div className="warning">
                  <WarningOutlined
                    className={`${
                      restaurantInfo.promotionObservation ||
                      restaurantInfo.productObservation ||
                      restaurantInfo.serviceObservation
                        ? 'active'
                        : 'inactive'
                    }`}
                  />
                </div>
              </Tooltip>
            )}
          </p>
          {restaurantInfo.description && dueTo && (
            <p className="p-medium store_service_time">
              {restaurantInfo.timeService === '00' ? (
                <span>
                  Lo sentimos, no tenemos servicio a domicilio para el restaurant seleccionado por el momento.
                </span>
              ) : (
                <>
                  Tiempo estimado de entrega en este momento del restaurante:{' '}
                  <span>{restaurantInfo.description || ''}</span>, es de{' '}
                  <span>{restaurantInfo.timeService} minutos</span>
                </>
              )}
            </p>
          )}
          <div className="delivery_time_wrapper">
            <div className="dropdown_wrapper">
              <Select
                className="date_list"
                defaultValue={selectedDay}
                onSelect={this.handleChangeDays}
                suffixIcon={<DateRange />}
                value={selectedDay}
              >
                {this.days.map(item => (
                  <Option key={item.label} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
              <TimePicker
                className="time_list"
                disabled={selectedTimeSlots.length === 0}
                disabledHours={() => {
                  return disabledHours;
                }}
                disabledMinutes={hour => {
                  if (startHour && hour === parseInt(startHour, 10)) {
                    return disabledMinutes;
                  }
                  return [];
                }}
                format="HH:mm"
                hideDisabledOptions
                minuteStep={15}
                onChange={this.handleChangeTime}
                value={selectedTime ? moment(`${selectedTime}`, 'HH:mm').tz('America/Guatemala') : null}
              />
            </div>
            <Button
              disabled={
                !selectedTimeSlots.length ||
                !deliveryAddress ||
                !deliveryAddress.restaurant ||
                !selectedDay ||
                !selectedTime ||
                restaurantInfo.timeService === '00'
              }
              onClick={this.handleStartOrder}
            >
              <FormattedMessage id="Start order" />
              <ArrowForward />
            </Button>
          </div>
        </div>
        {restaurants.length > 0 && (
          <DeliveryUpdateModal
            clientId={clientId}
            deliveryAddress={this.state.deliveryAddress}
            deliveryAreas={deliveryAreas}
            isCreateAddress={this.state.isCreateAddress}
            isOpenModal={this.state.isAddressOpenModal}
            onCloseModal={() => this.setState({ isAddressOpenModal: false })}
            onUpdatedAddress={this.handleUpdatedAddress}
            orderDetail={this.state.orderDetail}
            restaurants={restaurants}
            restaurantSarAssigned={restaurantInfo}
          />
        )}
        <DeleteConfirmModal
          isOpenModal={this.state.isConfirmModal}
          onCancel={() => this.setState({ isConfirmModal: false })}
          onOk={this.handleDeleteAddress}
          subtitle="se eliminará también de su usuario en la aplicación móvil y web."
          title="¿Estás seguro de eliminar la dirección del cliente por completo?"
        />
      </div>
    );
  }
}

AvailableAddress.propTypes = {
  customerList: PropTypes.array.isRequired,
  history: PropTypes.object,
  intl: intlShape.isRequired,
  addressList: PropTypes.array,
  clientId: PropTypes.number.isRequired,
  setInitialAddress: PropTypes.func.isRequired,
  setInitialOrder: PropTypes.func.isRequired,
  checkMyOrder: PropTypes.func.isRequired,
  getRestaurantTimeSlots: PropTypes.func.isRequired,
  getDeliveryAreas: PropTypes.func.isRequired,
  getBestRestaurants: PropTypes.func.isRequired,
  getCustomerAddress: PropTypes.func.isRequired,
  updateMyAddress: PropTypes.func.isRequired,
  getZone: PropTypes.func.isRequired,
  onGroupOrder: PropTypes.func,
  onStartOrder: PropTypes.func,
  onContinue: PropTypes.func,
  onError: PropTypes.func,
  onDeleteAddress: PropTypes.func,
  restaurants: PropTypes.array.isRequired,
};

AvailableAddress.defaultProps = {
  history: {},
  addressList: [],
  onGroupOrder: () => {},
  onStartOrder: () => {},
  onContinue: () => {},
  onError: () => {},
  onDeleteAddress: () => {},
};

const mapDispatchToProps = dispatch => {
  const { setInitialAddress, updateMyAddress } = addressActionCreators;
  const { setInitialOrder, checkMyOrder } = orderActionCreators;
  const { getRestaurantTimeSlots, getDeliveryAreas } = restaurantActionCreators;
  const { getBestRestaurants, getCustomerAddress } = customerActionCreators;
  const { getZone } = regionActionCreators;

  return bindActionCreators(
    {
      setInitialAddress,
      getBestRestaurants,
      updateMyAddress,
      setInitialOrder,
      checkMyOrder,
      getRestaurantTimeSlots,
      getDeliveryAreas,
      getZone,
      getCustomerAddress,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  withRouter,
  connectAddress(undefined, mapDispatchToProps),
  connectOrder(undefined, mapDispatchToProps),
  connectCatalog(undefined, mapDispatchToProps),
  connectRestaurant(undefined, mapDispatchToProps),
  connectCustomer(undefined, mapDispatchToProps),
  connectRegion(undefined, mapDispatchToProps),
)(AvailableAddress);
