/* eslint-disable no-unused-vars */
import { all, call, fork, put, take } from 'redux-saga/effects';
import { authActionCreators } from 'core/modules/auth/actions';
import {
  CHECK_VALIDATE_GIFT_REQUEST,
  CHECK_VALIDATE_NIT_REQUEST,
  CREATE_CUSTOMER_CALL_REQUEST,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_TAGS_REQUEST,
  CUSTOMER_VISA_LINK_CHECK_REQUEST,
  customerActionCreators,
  DELETE_CUSTOMER_TAGS_REQUEST,
  DELETE_PAYMENT_METHOD_REQUEST,
  GENERATE_VISA_LINK_REQUEST,
  GET_BEST_RESTAURANT_REQUEST,
  GET_CUSTOMER_ADDRESS_REQUEST,
  GET_CUSTOMER_LIST_REQUEST,
  GET_CUSTOMER_LIST_WITH_UPDATE_TAGS_REQUEST,
  GET_CUSTOMER_TAGS_REQUEST,
  GET_PAYMENT_METHODS_REQUEST,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_TAGS_REQUEST,
} from 'core/modules/customer/actions';
import { restService } from 'utilities';

export function* asyncHandleCustomerRequest({ payload, resolve, reject }) {
  const { firstName, lastName, phone } = payload;
  const phoneNumber = phone
    .replace(/-/g, '')
    .replace('(', '')
    .replace(')', '')
    .replace(/ /g, '');
  try {
    const response = yield call(restService, {
      api: `/customers`,
      method: 'POST',
      params: {
        firstName,
        lastName,
        phone: !phoneNumber ? null : phoneNumber,
      },
      contentType: 'multi-form',
    });
    if (response.status === 201) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncUpdateCustomerRequest({ payload, resolve, reject }) {
  const { id, firstName, lastName } = payload;

  try {
    const response = yield call(restService, {
      api: `/customers/${id}`,
      method: 'PUT',
      params: {
        firstName,
        lastName,
      },
      contentType: 'multi-form',
    });
    if (response.status === 202) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetCustomerListRequest({ payload, resolve, reject }) {
  const { or, filter, sort } = payload;
  try {
    const response = yield call(restService, {
      api: `/customers?limit=100&${or ? `or=${or}` : ''}${filter || ''}${
        sort ? `&sort=${sort} asc` : '&sort=createdAt desc'
      }`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      yield put(
        customerActionCreators.getCustomerListSuccess({
          list: response.data.rows,
        }),
      );
      resolve(response.data.rows);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetCustomerListWithUpdateRequest({ payload, resolve, reject }) {
  const { or, filter, sort } = payload;
  try {
    const response = yield call(restService, {
      api: `/customers?limit=100&${or ? `or=${or}` : ''}${filter || ''}${
        sort ? `&sort=${sort} asc` : '&sort=createdAt desc'
      }`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      yield put(
        customerActionCreators.getCustomerListWithUpdateTagsSuccess({
          list: response.data.rows,
        }),
      );
      resolve(response.data.rows);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetCustomerAddressRequest({ payload, resolve, reject }) {
  const { id, pendingOrders } = payload;
  try {
    const response = yield call(restService, {
      api: `/customers/${id}/addresses${pendingOrders ? `?pendingOrders=${pendingOrders}` : ''}`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      yield put(
        customerActionCreators.getCustomerAddressSuccess({
          addressList: response.data.rows,
        }),
      );
      resolve(response.data.rows);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetBestRestaurantsRequest({ payload, resolve, reject }) {
  const { long, lat, dueTo } = payload;
  try {
    const response = yield call(restService, {
      api: `/customers/addresses/bestRestaurant?long=${long}&lat=${lat}${dueTo ? `&dueTo=${dueTo}` : ''}`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      resolve(response.data);
    } else if (response.status === 406) {
      reject(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncCheckValidateNitRequest({ payload, resolve, reject }) {
  let { nit } = payload;
  nit = nit.trim();
  try {
    const response = yield call(restService, {
      api: `/customers/validateNit/${nit}`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncGetPaymentMethodsRequest({ payload, resolve, reject }) {
  const { id, channel } = payload;
  try {
    const response = yield call(restService, {
      api: `/customers/${id}/paymentMethods`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      resolve(response.data);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject(response);
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncDeletePaymentMethodRequest({ payload, resolve, reject }) {
  const { id } = payload;
  try {
    const response = yield call(restService, {
      api: `/customers/paymentMethods/${id}`,
      method: 'DELETE',
      params: {},
    });
    if (response.status === 202) {
      resolve(response.data);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject(response);
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncCheckValidateGiftRequest({ payload, resolve, reject }) {
  const { giftCard } = payload;
  try {
    const response = yield call(restService, {
      api: `/customers/validateGiftCard`,
      method: 'POST',
      params: {
        giftCard,
        channel: 'CC',
      },
    });
    if (response.status === 200) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetCustomerTagsRequest({ payload, resolve, reject }) {
  const { offset, limit } = payload;
  try {
    const response = yield call(restService, {
      api: `/customers/tags?offset=${offset || 0}&limit=${limit || 1000}`,
      method: 'GET',
      params: {},
    });
    if (response.status === 200) {
      resolve(response.data.rows);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncCreateCustomerTagsRequest({ payload, resolve, reject }) {
  const { id, tag, note } = payload;
  try {
    const response = yield call(restService, {
      api: `/customers/${id}/tags`,
      method: 'POST',
      params: {
        tag,
        note,
      },
    });

    if (response.status === 202 || response.status === 200) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGenerateVisaLinkRequest({ payload, resolve, reject }) {
  const { customerId, amount } = payload;
  try {
    const response = yield call(restService, {
      api: `/customers/visa-link`,
      method: 'POST',
      params: {
        customerId,
        amount,
      },
    });

    if (response.status === 202 || response.status === 201 || response.status === 200) {
      resolve(response.data);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncVisaLinkCheckRequest({ payload, resolve, reject }) {
  const { id } = payload;
  console.log('kok');
  try {
    const response = yield call(restService, {
      api: `/customers/visa-link/${id}/details`,
      method: 'GET',
    });
    console.log(response);
    if (response.status === 202 || response.status === 201 || response.status === 200) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncUpdateCustomerTagsRequest({ payload, resolve, reject }) {
  const { id, tag, note } = payload;

  try {
    const response = yield call(restService, {
      api: `/customers/${id}/tags/${tag}`,
      method: 'PUT',
      params: {
        note,
      },
    });

    if (response.status === 202 || response.status === 200) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncCreateCustomerTypeRequest({ payload, resolve, reject }) {
  const { customerId, type, description } = payload;

  try {
    const response = yield call(restService, {
      api: `/customers/calls`,
      method: 'POST',
      params: {
        customerId,
        type,
        description,
      },
    });

    if (response.status === 201 || response.status === 200) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncDeleteCustomerTagsRequest({ payload, resolve, reject }) {
  const { id, tag } = payload;

  try {
    const response = yield call(restService, {
      api: `/customers/${id}/tags/${tag}`,
      method: 'DELETE',
    });

    if (response.status === 202 || response.status === 200) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response });
    }
  } catch (e) {
    reject(e);
  }
}
export function* watchCreateCustomerRequest() {
  while (true) {
    const action = yield take(CREATE_CUSTOMER_REQUEST);
    yield* asyncHandleCustomerRequest(action);
  }
}

export function* watchGenerateVisaLinkRequest() {
  while (true) {
    const action = yield take(GENERATE_VISA_LINK_REQUEST);
    yield* asyncGenerateVisaLinkRequest(action);
  }
}

export function* watchVisaLinkCheckRequest() {
  while (true) {
    const action = yield take(CUSTOMER_VISA_LINK_CHECK_REQUEST);
    yield* asyncVisaLinkCheckRequest(action);
  }
}

export function* watchUpdateCustomerRequest() {
  while (true) {
    const action = yield take(UPDATE_CUSTOMER_REQUEST);
    yield* asyncUpdateCustomerRequest(action);
  }
}

export function* watchCustomerListRequest() {
  while (true) {
    const action = yield take(GET_CUSTOMER_LIST_REQUEST);
    yield* asyncGetCustomerListRequest(action);
  }
}

export function* watchCreateCustomerCallRequest() {
  while (true) {
    const action = yield take(CREATE_CUSTOMER_CALL_REQUEST);
    yield* asyncCreateCustomerTypeRequest(action);
  }
}

export function* watchCustomerListWithUpdateTagsRequest() {
  while (true) {
    const action = yield take(GET_CUSTOMER_LIST_WITH_UPDATE_TAGS_REQUEST);
    yield* asyncGetCustomerListWithUpdateRequest(action);
  }
}

export function* watchCustomerAddressRequest() {
  while (true) {
    const action = yield take(GET_CUSTOMER_ADDRESS_REQUEST);
    yield* asyncGetCustomerAddressRequest(action);
  }
}
export function* watchGetBestRestaurantsRequest() {
  while (true) {
    const action = yield take(GET_BEST_RESTAURANT_REQUEST);
    yield* asyncGetBestRestaurantsRequest(action);
  }
}
export function* watchCheckValidateNitRequest() {
  while (true) {
    const action = yield take(CHECK_VALIDATE_NIT_REQUEST);
    yield* asyncCheckValidateNitRequest(action);
  }
}
export function* watchGetPaymentMethodsRequest() {
  while (true) {
    const action = yield take(GET_PAYMENT_METHODS_REQUEST);
    yield* asyncGetPaymentMethodsRequest(action);
  }
}
export function* watchDeletePaymentMethodRequest() {
  while (true) {
    const action = yield take(DELETE_PAYMENT_METHOD_REQUEST);
    yield* asyncDeletePaymentMethodRequest(action);
  }
}
export function* watchCheckValidateGiftRequest() {
  while (true) {
    const action = yield take(CHECK_VALIDATE_GIFT_REQUEST);
    yield* asyncCheckValidateGiftRequest(action);
  }
}
export function* watchCreateCustomerTagsRequest() {
  while (true) {
    const action = yield take(CREATE_CUSTOMER_TAGS_REQUEST);
    yield* asyncCreateCustomerTagsRequest(action);
  }
}
export function* watchDeleteCustomerTagsRequest() {
  while (true) {
    const action = yield take(DELETE_CUSTOMER_TAGS_REQUEST);
    yield* asyncDeleteCustomerTagsRequest(action);
  }
}
export function* watchGetCustomerTagsRequest() {
  while (true) {
    const action = yield take(GET_CUSTOMER_TAGS_REQUEST);
    yield* asyncGetCustomerTagsRequest(action);
  }
}
export function* watchUpdateCustomerTagsRequest() {
  while (true) {
    const action = yield take(UPDATE_CUSTOMER_TAGS_REQUEST);
    yield* asyncUpdateCustomerTagsRequest(action);
  }
}

export default function*() {
  yield all([
    fork(watchGenerateVisaLinkRequest),
    fork(watchDeleteCustomerTagsRequest),
    fork(watchCreateCustomerTagsRequest),
    fork(watchVisaLinkCheckRequest),
    fork(watchCreateCustomerRequest),
    fork(watchUpdateCustomerRequest),
    fork(watchCustomerListRequest),
    fork(watchCustomerListWithUpdateTagsRequest),
    fork(watchCustomerAddressRequest),
    fork(watchGetBestRestaurantsRequest),
    fork(watchCheckValidateNitRequest),
    fork(watchGetPaymentMethodsRequest),
    fork(watchDeletePaymentMethodRequest),
    fork(watchCheckValidateGiftRequest),
    fork(watchGetCustomerTagsRequest),
    fork(watchUpdateCustomerTagsRequest),
    fork(watchCreateCustomerCallRequest),
  ]);
}
