import React from 'react';

const PageNotFound = () => (
  <div className="">
    404 Error
    <br />
    Page Not Found
  </div>
);

export default PageNotFound;
