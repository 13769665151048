const auth = {
  agent: null,
};

const catalog = {
  catalogId: null,
  superCataloges: null,
  stocks: null,
  cataloges: null,
  products: null,
  prices: null,
  sizes: null,
  productGroups: null,
  optionGroups: null,
  options: null,
  recipes: null,
  cart: [],
};

const region = {
  departments: [],
  municipalities: [],
  zones: [],
  selectedRestaurantId: null,
  isRestaurant: false,
};

const address = {
  list: null,
  selectedAddress: {},
};

const order = {
  list: [],
  nitInfo: {},
  dueTo: '',
  note: '',
  specialOrders: [],
  partDay: '',
};

const customer = {
  list: [],
  addressList: [],
};

const restaurant = {
  list: {},
};

const version = '';

const settings = {
  list: [],
};

export const initialState = {
  auth,
  catalog,
  address,
  order,
  region,
  customer,
  restaurant,
  settings,
  version,
};
