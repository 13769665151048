import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { Button, Form, Icon, Input, Select, Spin } from 'antd';
import ReactPhoneInput from 'react-phone-input-2';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import 'react-phone-input-2/dist/style.css';
import { ArrowDropDown, ArrowForward, Close, Phone } from '@material-ui/icons';
import ClientSelect from 'components/Main/Claim/ClientSelect';
import AvailableAddress from 'components/Main/Claim/AvailableAddress';
import ExistedOrders from 'containers/Main/Order/ExistedOrders';
import LastOrder from 'components/Main/Order/LastOrder';
import {
  addressActionCreators,
  catalogActionCreators,
  connectAddress,
  connectCatalog,
  connectCustomer,
  connectOrder,
  connectRestaurant,
  customerActionCreators,
  orderActionCreators,
  restaurantActionCreators,
} from 'core';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { promisify } from 'utilities';
import toast from 'components/Basic/Toast';
import { getPhoneCode } from 'utilities/common';
import ModalDlg from '../../../components/Basic/ModalDlg';

const antIcon = <Icon spin style={{ fontSize: 24 }} type="loading" />;
const { Option } = Select;
const { TextArea } = Input;

class NewOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.TypesOfCalls = {
      AppConsultation: 'app_consultation',
      AppClaims: 'app_claims',
      PriceInquiries: 'price_inquiries',
      PromotionReachesMe: 'promotion_reaches_me',
      Short: 'short',
      Joke: 'joke',
      WrongCall: 'wrong_call',
      NoCoverage: 'no_coverage',
      RestaurantWithoutService: 'restaurant_without_service',
      TimeRestriction: 'time_restriction',
      Locations: 'locations',
      InquiriesGiftCertificates: 'inquiries_gift_certificates',
      InformationOnChildrenParties: 'information_on_children_parties',
      DeniedCard: 'denied_card',
      Inquiries3PO: 'inquiries_3po',
      Reintegros: 'reintegros',
      BlackList: 'black_list',
      ContributorQuery: 'contributor_query',
      SearchJob: 'search_job',
      OffersLand: 'offers_land',
      ComplaintAndOrClaim: 'complaint_claim',
      NoProduct: 'no_product',
      InvoicesReplenishment: 'invoices_replenishment',
      SpecificCampaignsMarketing: 'specific_campaigns_marketing',
      OpeningAndClosingHours: 'opening_and_closing_hours',
      MotoristComplaint: 'motorist_complaint',
      Others: 'others',
    };

    this.state = {
      callerId: null,
      isSearch: false,
      isExistPhone: true,
      isLoading: false,
      clientId: 0,
      customerAddressId: 0,
      isValidNumber: true,
      isAddNewUser: false,
      isGroupOrder: false,
      lastOrderInfo: null,
      isTypeOfCallModalVisible: false,
      selectedTypeCall: '',
      descriptionCall: '',
      tags: [],
      isSelectedOtherCustomer: false,
      phone: '',
    };
  }

  componentDidMount() {
    promisify(this.props.setInitialCatalog, { cart: [], catalogId: null });
    promisify(this.props.getRestaurantList, {})
      .then(res => {
        this.setState({ restaurants: res.data.rows });
      })
      .catch(e => {
        console.log('e', e);
      });

    const { form, customerList, match } = this.props;
    const { location } = this.props;
    if (
      customerList &&
      Array.isArray(customerList) &&
      customerList.length !== 0 &&
      location.state &&
      location.state.selectedAddress &&
      location.state.selectedAddress.id
    ) {
      if (customerList.filter(c => c.id === location.state.selectedAddress.customer).length !== 0) {
        const customerInfo = customerList.filter(c => c.id === location.state.selectedAddress.customer)[0] || {};
        const phoneUtil = PhoneNumberUtil.getInstance();
        const number = phoneUtil.parseAndKeepRawInput(customerInfo.phone);
        const countryCode = number.getCountryCode();
        const nationalNumber = number.getNationalNumber();
        form.setFieldsValue({ phone_code: countryCode });
        form.setFieldsValue({ caller_id: nationalNumber.toString() });
        this.setState({ callerId: nationalNumber });
        this.getCustomers(customerInfo.phone);
      }
    }
    if (match && match.params && match.params.phone) {
      if (this.validatePhoneNumber(match.params.phone)) {
        const phoneUtil = PhoneNumberUtil.getInstance();
        const number = phoneUtil.parseAndKeepRawInput(match.params.phone);
        const countryCode = number.getCountryCode();
        const nationalNumber = number.getNationalNumber();
        form.setFieldsValue({ phone_code: countryCode });
        form.setFieldsValue({ caller_id: nationalNumber.toString() });
        this.setState({ callerId: nationalNumber });
        this.getCustomers(match.params.phone);
      }
    }

    this.loadInitialData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      if (!this.props.location.state) {
        this.initForm();
      }
    }
  }

  initForm = () => {
    const { form } = this.props;
    form.setFieldsValue({ phone_code: getPhoneCode() });
    form.setFieldsValue({ caller_id: '' });
    this.setState({ callerId: '', isSearch: false, isExistPhone: true });
  };

  loadInitialData = async () => {
    Promise.all([
      promisify(this.props.getSuperCatalogs, {}),
      promisify(this.props.getCatalogs, {}),
      promisify(this.props.getPrices, {}),
      promisify(this.props.getItems, {}),
      promisify(this.props.getSizes, {}),
      promisify(this.props.getItemGroups, {}),
      promisify(this.props.getOptionGroups, {}),
      promisify(this.props.getOptions, {}),
      promisify(this.props.getRecipes, {}),
    ]);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.getCustomers(`+${form.getFieldValue('phone_code')}${values.caller_id}`);
        this.setState({ isSelectedOtherCustomer: true });
      } else if (err.caller_id) {
        toast.error({
          title: this.props.intl.formatMessage({ id: 'It seems that the phone number entered is invalid' }),
        });
      }
    });
  };

  setIsSelectedOtherCustomer = () => this.setState({ isSelectedOtherCustomer: false });

  getCustomers = async (phone, clickTag = false) => {
    const { clientId } = this.state;
    this.setState({ isLoading: true });
    if (!clickTag) {
      this.setState({ isSelectedOtherCustomer: true });
    }
    const phoneNumber = phone
      .replace(/-/g, '')
      .replace('(', '')
      .replace(')', '')
      .replace(/ /g, '');
    await promisify(this.props.getCustomerList, {
      filter: `filter= phone like %25${phoneNumber.slice(1)}%25`,
    })
      .then(res => {
        this.setState({ isLoading: false });
        if (res && res.length !== 0) {
          this.setState({ phone });
          this.setState({
            isSearch: true,
            isExistPhone: true,
          });
          if (res[0].state === 'A') {
            const id = clickTag === false ? res[0].id : clientId;
            this.handleClientSelect(id);
          }
        } else {
          this.setState({
            isSearch: false,
            isExistPhone: false,
          });
          this.handleClientSelect(res[0].id);
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isSearch: false,
          isLoading: false,
        });
      });
    await promisify(this.props.getCustomerTags, {}).then(res => {
      this.setState({ tags: res || [] });
    });
  };

  getCustomersWithUpdateTags = async phone => {
    try {
      const phoneNumber = phone
        .replace(/-/g, '')
        .replace('(', '')
        .replace(')', '')
        .replace(/ /g, '');
      await promisify(this.props.getCustomerListWithUpdateTags, {
        filter: `filter= phone like %25${phoneNumber.slice(1)}%25`,
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  handleSearch = e => {
    const { form } = this.props;
    if (e.keyCode === 13) {
      const callerId = form.getFieldValue('caller_id');
      this.setState({ callerId });
      if (callerId !== '' && this.validatePhoneNumber(`+${form.getFieldValue('phone_code')}${callerId}`)) {
        this.getCustomers(`+${form.getFieldValue('phone_code')}${callerId}`);
      }
    }
  };

  handleChangeCaller = value => {
    this.setState({
      callerId: value,
    });
  };

  handleBack = () => {
    this.props.history.push({
      pathname: '/ots/claim',
      state: { type: 'search' },
    });
  };

  handleSendCallType = async () => {
    const { selectedTypeCall, descriptionCall, clientId } = this.state;
    const { createCustomerCall } = this.props;
    try {
      await promisify(createCustomerCall, {
        customerId: clientId,
        type: selectedTypeCall,
        description: descriptionCall,
      });

      this.setState({
        selectedTypeCall: '',
        descriptionCall: '',
      });

      toast.success({ title: 'Enviado' });
    } catch (error) {
      toast.error({ title: this.props.intl.formatMessage({ id: `${error.data.type}` }) });
    }
    this.setState({ isTypeOfCallModalVisible: false });
  };

  validatePhoneNumber = phoneNumber => {
    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
      const countryCode = number.getCountryCode();
      if (countryCode === 1 || (countryCode >= 502 && countryCode <= 506)) {
        valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
      } else {
        return false;
      }
    } catch (e) {
      valid = false;
    }
    return valid;
  };

  checkPhoneNumber = (rule, value, callback) => {
    const { form } = this.props;
    if (!this.validatePhoneNumber(`+${form.getFieldValue('phone_code')}${value}`)) {
      this.setState({ isValidNumber: false });
      callback('  ');
    } else {
      this.setState({ isValidNumber: true });
      callback();
    }
  };

  checkPhoneCode = (rule, value, callback) => {
    const { form } = this.props;
    if (!this.validatePhoneNumber(`+${value}${form.getFieldValue('caller_id')}`)) {
      this.setState({ isValidNumber: false });
      // callback(this.props.intl.formatMessage({ id: 'Phone number is invalid!'}));
      callback('  ');
    } else {
      this.setState({ isValidNumber: true });
      callback();
    }
  };

  handleClientSelect = async id => {
    if (id && id !== -1) {
      this.setState({ clientId: id, isAddNewUser: false });
      await promisify(this.props.getCustomerAddress, {
        id,
        pendingOrders: true,
      });
      await promisify(this.props.getMyOrder, {
        offset: 0,
        limit: 1,
        filter: `&filter=customer eq ${id}`,
      })
        .then(res => {
          if (res && res.length !== 0) {
            this.setState({ lastOrderInfo: res[0] });
          } else {
            this.setState({ lastOrderInfo: null });
          }
        })
        .catch(error => {
          console.log(error);
          this.setState({ lastOrderInfo: null });
        });
    } else if (!id) {
      this.setState({ lastOrderInfo: null });
    } else {
      this.setState({ isAddNewUser: true, lastOrderInfo: null });
    }
  };

  handleBackClick = () => {
    this.setState({ isAddNewUser: false });
  };

  handleCreateNewCustomer = customerInfo => {
    const { form } = this.props;
    this.setState({ isLoading: true });
    promisify(this.props.createCustomer, {
      firstName: customerInfo.firstName,
      lastName: customerInfo.lastName || '',
      phone: `+${form.getFieldValue('phone_code')}${this.state.callerId}`,
    })
      .then(() => {
        this.getCustomers(`+${form.getFieldValue('phone_code')}${this.state.callerId}`);
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleUpdateCustomer = customerInfo => {
    const { form } = this.props;
    this.setState({ isLoading: true });
    promisify(this.props.updateCustomer, {
      id: customerInfo.id,
      firstName: customerInfo.firstName,
      lastName: customerInfo.lastName || '',
    })
      .then(() => {
        this.getCustomers(`+${form.getFieldValue('phone_code')}${this.state.callerId}`);
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleUpdateCustomerTags = async (user, tag) => {
    const { form } = this.props;
    this.setState({ isLoading: true });
    try {
      if (user.tags.filter(item => item.tag === tag).length === 0) {
        await promisify(this.props.createCustomerTags, {
          id: user.id,
          tag,
        }).then(() =>
          this.getCustomersWithUpdateTags(`+${form.getFieldValue('phone_code')}${this.state.callerId}`, true),
        );
      } else {
        const userTagId = user.tags.find(item => item.tag === tag).id;
        await promisify(this.props.deleteCustomerTags, {
          id: user.id,
          tag: userTagId,
        }).then(() =>
          this.getCustomersWithUpdateTags(`+${form.getFieldValue('phone_code')}${this.state.callerId}`, true),
        );
      }
    } catch (error) {
      console.log('error', error);
    }
    this.setState({ isLoading: false });
  };

  handleUpdateTagNote = async (user, tag, note) => {
    const { form } = this.props;
    const userTagId = user.tags.find(item => item.tag === tag).id;
    try {
      await promisify(this.props.updateCustomerTags, {
        id: user.id,
        tag: userTagId,
        note,
      });

      this.getCustomersWithUpdateTags(`+${form.getFieldValue('phone_code')}${this.state.callerId}`, true);

      return true;
    } catch (e) {
      toast.error({
        title: 'Error al agregar o actualizar el motivo',
      });

      return false;
    }
  };

  handleDeleteAddress = addressInfo => {
    promisify(this.props.deleteMyAddress, { id: addressInfo.id })
      .then(() => {
        promisify(this.props.getCustomerAddress, {
          id: this.state.clientId,
          pendingOrders: true,
        });
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  onCloseTypeOfCallsModal = () => {
    this.setState({ isTypeOfCallModalVisible: false, descriptionCall: '', selectedTypeCall: undefined });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isSearch,
      isExistPhone,
      isValidNumber,
      isAddNewUser,
      isGroupOrder,
      customerAddressId,
      lastOrderInfo,
      tags,
      restaurants,
      isSelectedOtherCustomer,
      isTypeOfCallModalVisible,
    } = this.state;
    const { customerList, addressList } = this.props;

    return (
      <div className="new_orderlayout scrollbar">
        <div className="main_content scrollbar">
          <div className="left_panel">
            <p className="title header_title">
              <FormattedMessage id="McDonald’s order taking interface" />
            </p>
            <div className="left_content_wrapper">
              <div className="caller_id_label">
                <p className="title">
                  <FormattedMessage id="Customer's phone number" />
                </p>
                <p className="description">
                  <FormattedMessage id="Customer recognition" />
                </p>
              </div>
              <Form className="caller_form" onSubmit={this.handleSubmit}>
                <div className="phone_number_wrapper">
                  <Form.Item className="phone_code_wrapper">
                    {getFieldDecorator('phone_code', {
                      rules: [
                        {
                          validator: this.checkPhoneCode,
                        },
                      ],
                      initialValue: getPhoneCode(),
                    })(
                      <Select className="phone_code" suffixIcon={<ArrowDropDown />}>
                        <Option value={1}>+1</Option>
                        <Option value={502}>+502</Option>
                        <Option value={503}>+503</Option>
                        <Option value={504}>+504</Option>
                        <Option value={505}>+505</Option>
                        <Option value={506}>+506</Option>
                      </Select>,
                    )}
                  </Form.Item>
                  <Form.Item className={`caller_id_input ${isValidNumber ? '' : 'invalid_phone'}`}>
                    {getFieldDecorator('caller_id', {
                      rules: [
                        {
                          required: true,
                          message: this.props.intl.formatMessage({ id: 'Enter a phone number' }),
                        },
                        {
                          validator: this.checkPhoneNumber,
                        },
                      ],
                    })(
                      <ReactPhoneInput
                        defaultCountry="gt"
                        disableCountryCode
                        onChange={this.handleChangeCaller}
                        onKeyDown={this.handleSearch}
                        onlyCountries={['us', 'gt', 'bz', 'sv', 'hn', 'ni', 'cr']}
                        placeholder={this.props.intl.formatMessage({ id: 'Enter a phone number' })}
                        value={this.state.callerId}
                      />,
                    )}
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button disabled={!this.state.callerId} htmlType="submit">
                    <p>
                      <FormattedMessage id="Load details" />
                    </p>
                    <ArrowForward />
                  </Button>
                </Form.Item>
              </Form>
            </div>
            {this.state.clientId !== 0 && (
              <div className="calls-button">
                <Button onClick={() => this.setState({ isTypeOfCallModalVisible: true })}>
                  <FormattedMessage id="Call typology" />
                  <Phone />
                </Button>
                <ModalDlg handleCancel={this.onCloseTypeOfCallsModal} isCenter visible={isTypeOfCallModalVisible}>
                  <div className="modal-calls-type">
                    <div className="header">
                      <p className="title">Tipo de llamada</p>
                      <Close onClick={this.onCloseTypeOfCallsModal} />
                    </div>
                    <Select
                      className="select-type"
                      onSelect={selectedTypeCall => this.setState({ selectedTypeCall })}
                      placeholder="Selecciona una opción"
                      suffixIcon={<ArrowDropDown />}
                      value={this.state.selectedTypeCall || undefined}
                    >
                      {Object.keys(this.TypesOfCalls).map((item, index) => (
                        <Option key={index} value={this.TypesOfCalls[item]}>
                          <FormattedMessage id={item} />
                        </Option>
                      ))}
                    </Select>
                    <TextArea
                      onChange={e => this.setState({ descriptionCall: e.target.value })}
                      placeholder="Ingresa los detalles"
                      value={this.state.descriptionCall}
                    />
                    <Button
                      disabled={!this.state.selectedTypeCall || !this.state.descriptionCall}
                      onClick={this.handleSendCallType}
                    >
                      <p>Reportar</p>
                      <ArrowForward />
                    </Button>
                  </div>
                </ModalDlg>
              </div>
            )}
            {lastOrderInfo && <LastOrder lastOrderInfo={lastOrderInfo} />}
          </div>
          <div className="right_panel" key={this.state.phone}>
            <Spin indicator={antIcon} spinning={this.state.isLoading}>
              {!isGroupOrder && !isSearch && isExistPhone && (
                <div className="empty_screen">
                  <div className="empty_wrapper">
                    <p className="title">
                      <FormattedMessage id="Customer details" />
                    </p>
                    <p className="subtitle">
                      <FormattedMessage id="Waiting for details" />
                    </p>
                  </div>
                </div>
              )}
              {!isGroupOrder && (isSearch || !isExistPhone) && (
                <>
                  <ClientSelect
                    customerList={customerList}
                    handleUpdateTagNote={this.handleUpdateTagNote}
                    isAddNewUser={isAddNewUser}
                    isExistPhone={isExistPhone}
                    isNewOrder
                    isSelectedOtherCustomer={isSelectedOtherCustomer}
                    onBack={this.handleBackClick}
                    onClientSelect={this.handleClientSelect}
                    onCreateNewCustomer={this.handleCreateNewCustomer}
                    onUpdateCustomer={this.handleUpdateCustomer}
                    onUpdateCustomerTag={this.handleUpdateCustomerTags}
                    setIsSelectedOtherCustomer={this.setIsSelectedOtherCustomer}
                    tags={tags}
                  />
                  {isExistPhone && !isAddNewUser && !isGroupOrder && restaurants && (
                    <AvailableAddress
                      addressList={addressList}
                      clientId={this.state.clientId}
                      customerList={customerList}
                      onContinue={() => this.setState({ isLoading: false })}
                      onDeleteAddress={this.handleDeleteAddress}
                      onError={() => this.setState({ isLoading: false })}
                      onGroupOrder={customer => this.setState({ isGroupOrder: true, customerAddressId: customer.id })}
                      onStartOrder={() => this.setState({ isLoading: true })}
                      restaurants={restaurants}
                    />
                  )}
                </>
              )}
              {isGroupOrder && (
                <ExistedOrders
                  customerAddressId={customerAddressId}
                  onBack={() => this.setState({ isGroupOrder: false })}
                />
              )}
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}

NewOrder.propTypes = {
  intl: intlShape.isRequired,
  form: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  customerList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  addressList: PropTypes.array,
  setInitialCatalog: PropTypes.func.isRequired,
  getSuperCatalogs: PropTypes.func.isRequired,
  getCatalogs: PropTypes.func.isRequired,
  getPrices: PropTypes.func.isRequired,
  getSizes: PropTypes.func.isRequired,
  getItems: PropTypes.func.isRequired,
  getItemGroups: PropTypes.func.isRequired,
  getOptionGroups: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  getRecipes: PropTypes.func.isRequired,
  createCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  getCustomerList: PropTypes.func.isRequired,
  getCustomerListWithUpdateTags: PropTypes.func.isRequired,
  getCustomerAddress: PropTypes.func.isRequired,
  getMyOrder: PropTypes.func.isRequired,
  deleteMyAddress: PropTypes.func.isRequired,
  getCustomerTags: PropTypes.func.isRequired,
  updateCustomerTags: PropTypes.func.isRequired,
  getRestaurantList: PropTypes.func.isRequired,
  createCustomerTags: PropTypes.func.isRequired,
  deleteCustomerTags: PropTypes.func.isRequired,
  createCustomerCall: PropTypes.func.isRequired,
};

NewOrder.defaultProps = {
  form: {},
  history: {},
  location: {},
  match: {},
  customerList: [],
  addressList: [],
};

const mapStateToProps = ({ catalog, customer }) => ({
  superCataloges: catalog.superCataloges,
  cataloges: catalog.cataloges,
  products: catalog.products,
  prices: catalog.prices,
  sizes: catalog.sizes,
  customerList: customer.list,
  addressList: customer.addressList,
});

const mapDispatchToProps = dispatch => {
  const {
    setInitialCatalog,
    getSuperCatalogs,
    getCatalogs,
    getPrices,
    getSizes,
    getItems,
    getItemGroups,
    getOptionGroups,
    getOptions,
    getRecipes,
  } = catalogActionCreators;
  const {
    getCustomerAddress,
    createCustomer,
    updateCustomer,
    getCustomerList,
    getCustomerListWithUpdateTags,
    getCustomerTags,
    updateCustomerTags,
    createCustomerTags,
    deleteCustomerTags,
    createCustomerCall,
  } = customerActionCreators;
  const { setInitialAddress, deleteMyAddress } = addressActionCreators;
  const { getMyOrder } = orderActionCreators;
  const { getRestaurantList } = restaurantActionCreators;

  return bindActionCreators(
    {
      createCustomerCall,
      deleteCustomerTags,
      createCustomerTags,
      setInitialCatalog,
      getSuperCatalogs,
      getCatalogs,
      getPrices,
      getSizes,
      getItems,
      getItemGroups,
      getOptionGroups,
      getOptions,
      getRecipes,
      getCustomerAddress,
      createCustomer,
      updateCustomer,
      getCustomerList,
      getCustomerListWithUpdateTags,
      setInitialAddress,
      deleteMyAddress,
      getMyOrder,
      getCustomerTags,
      updateCustomerTags,
      getRestaurantList,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  withRouter,
  connectCatalog(mapStateToProps, mapDispatchToProps),
  connectCustomer(mapStateToProps, mapDispatchToProps),
  connectAddress(mapStateToProps, mapDispatchToProps),
  connectOrder(mapStateToProps, mapDispatchToProps),
  connectRestaurant(mapStateToProps, mapDispatchToProps),
  Form.create({ name: 'caller_form' }),
)(NewOrder);
