import { createAction } from 'redux-actions';
import { createPromiseAction } from 'core/modules/utils';

/**
 * Action Types
 */
export const SET_INITIAL_REGION_VALUE = '@region/SET_INITIAL_REGION_VALUE';
export const SET_INITIAL_REGION_VALUE_SUCCESS = '@region/SET_INITIAL_REGION_VALUE_SUCCESS';
export const GET_DEPARTMENTS_REQUEST = '@region/GET_DEPARTMENTS_REQUEST';
export const GET_DEPARTMENTS_SUCCESS = '@region/GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAILURE = '@region/GET_DEPARTMENTS_FAILURE';
export const CREATE_DEPARTMENTS_REQUEST = '@region/CREATE_DEPARTMENTS_REQUEST';
export const DELETE_DEPARTMENT_REQUEST = '@region/DELETE_DEPARTMENT_REQUEST';
export const GET_MUNICIPALITIES_REQUEST = '@region/GET_MUNICIPALITIES_REQUEST';
export const GET_MUNICIPALITIES_SUCCESS = '@region/GET_MUNICIPALITIES_SUCCESS';
export const GET_MUNICIPALITIES_FAILURE = '@region/GET_MUNICIPALITIES_FAILURE';
export const CREATE_MUNICIPALITY_REQUEST = '@region/CREATE_MUNICIPALITY_REQUEST';
export const DELETE_MUNICIPALITY_REQUEST = '@region/DELETE_MUNICIPALITY_REQUEST';
export const GET_ZONES_REQUEST = '@region/GET_ZONES_REQUEST';
export const GET_ZONES_SUCCESS = '@region/GET_ZONES_SUCCESS';
export const GET_ZONES_FAILURE = '@region/GET_ZONES_FAILURE';
export const GET_ZONE_REQUEST = '@region/GET_ZONE_REQUEST';
export const CREATE_ZONE_REQUEST = '@region/CREATE_ZONE_REQUEST';
export const DELETE_ZONE_REQUEST = '@region/DELETE_ZONE_REQUEST';
export const ASSIGN_RESTAURANTS_REQUEST = '@region/ASSIGN_RESTAURANTS_REQUEST';
export const GET_RESTAURANTS_FROM_ZONE_REQUEST = '@region/GET_RESTAURANTS_FROM_ZONE_REQUEST';
export const CHECK_MY_ADDRESS_IN_RESTAURANT = '@address/CHECK_MY_ADDRESS_IN_RESTORANT';

/**
 * Action Creators
 */
export const regionActionCreators = {
  setInitialRegion: createPromiseAction(SET_INITIAL_REGION_VALUE),
  setInitialRegionSuccess: createAction(SET_INITIAL_REGION_VALUE_SUCCESS),
  getDepartments: createPromiseAction(GET_DEPARTMENTS_REQUEST),
  getDepartmentsSuccess: createAction(GET_DEPARTMENTS_SUCCESS),
  getDepartmentsFailure: createAction(GET_DEPARTMENTS_FAILURE),
  createDepartment: createPromiseAction(CREATE_DEPARTMENTS_REQUEST),
  deleteDepartment: createPromiseAction(DELETE_DEPARTMENT_REQUEST),
  getMunicipalities: createPromiseAction(GET_MUNICIPALITIES_REQUEST),
  getMunicipalitiesSuccess: createAction(GET_MUNICIPALITIES_SUCCESS),
  getMunicipalitiesFailure: createAction(GET_MUNICIPALITIES_FAILURE),
  createMunicipality: createPromiseAction(CREATE_MUNICIPALITY_REQUEST),
  deleteMunicipality: createPromiseAction(DELETE_MUNICIPALITY_REQUEST),
  getZones: createPromiseAction(GET_ZONES_REQUEST),
  getZonesSuccess: createAction(GET_ZONES_SUCCESS),
  getZonesFailure: createAction(GET_ZONES_FAILURE),
  getZone: createPromiseAction(GET_ZONE_REQUEST),
  createZone: createPromiseAction(CREATE_ZONE_REQUEST),
  deleteZone: createPromiseAction(DELETE_ZONE_REQUEST),
  assignRestaurants: createPromiseAction(ASSIGN_RESTAURANTS_REQUEST),
  getRestaurantsFromZone: createPromiseAction(GET_RESTAURANTS_FROM_ZONE_REQUEST),
  checkMyAddressInRestaurant: createPromiseAction(CHECK_MY_ADDRESS_IN_RESTAURANT),
};
