import {
  GET_CUSTOMER_ADDRESS_SUCCESS,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_LIST_WITH_UPDATE_TAGS_SUCCESS
} from 'core/modules/customer/actions';
import { initialState } from 'core/modules/initialState';

export default function customer(state = initialState.customer, action = {}) {
  const { type, payload } = action;

  switch (type) {
    //
    case GET_CUSTOMER_LIST_SUCCESS: {
      return {
        ...state,
        addressList: [],
        list: payload.list,
      };
    }
    case GET_CUSTOMER_LIST_WITH_UPDATE_TAGS_SUCCESS: {
      return {
        ...state,
        list: payload.list,
      };
    }
    case GET_CUSTOMER_ADDRESS_SUCCESS: {
      return {
        ...state,
        addressList: payload.addressList,
      };
    }
    default: {
      return state;
    }
  }
}
