/* eslint-disable no-unused-vars */
import { all, call, fork, put, take } from 'redux-saga/effects';
import { authActionCreators } from 'core/modules/auth/actions';
import {
  ASSIGN_RESTAURANTS_REQUEST,
  CREATE_DEPARTMENTS_REQUEST,
  CREATE_MUNICIPALITY_REQUEST,
  CREATE_ZONE_REQUEST,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_MUNICIPALITY_REQUEST,
  DELETE_ZONE_REQUEST,
  GET_DEPARTMENTS_REQUEST,
  GET_MUNICIPALITIES_REQUEST,
  GET_RESTAURANTS_FROM_ZONE_REQUEST,
  GET_ZONE_REQUEST,
  GET_ZONES_REQUEST,
  regionActionCreators,
  SET_INITIAL_REGION_VALUE,
  CHECK_MY_ADDRESS_IN_RESTAURANT,
} from 'core/modules/region/actions';
import { restService } from 'utilities';

export function* asyncSetInitialValueRequest({ payload, resolve, reject }) {
  try {
    yield put(regionActionCreators.setInitialRegionSuccess({ ...payload }));
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetDepartmentsRequest({ payload, resolve, reject }) {
  const { offset, limit, or, filter, sort } = payload;
  try {
    const response = yield call(restService, {
      api: `/region/departments?offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}${filter || ''}${
        sort ? `&sort=${sort} desc` : '&sort=createdAt desc'
      }`,
      method: 'GET',
      params: {},
    });

    if (response.status === 200) {
      yield put(
        regionActionCreators.getDepartmentsSuccess({
          departments: response.data.rows,
        }),
      );
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncCreateDepartmentRequest({ payload, resolve, reject }) {
  const { placeId, long, lat, description } = payload;
  try {
    const response = yield call(restService, {
      api: `/region/departments`,
      method: 'POST',
      params: {
        placeId,
        long,
        lat,
        description,
      },
    });

    if (response.status === 201) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncDeleteDepartmentRequest({ payload, resolve, reject }) {
  const { id } = payload;
  try {
    const response = yield call(restService, {
      api: `/region/departments/${id}`,
      method: 'DELETE',
      params: {},
    });

    if (response.status === 202) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetMunicipalitiesRequest({ payload, resolve, reject }) {
  const { offset, limit, or, filter, sort } = payload;

  try {
    const response = yield call(restService, {
      api: `/region/municipalities?offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}${filter || ''}${
        sort ? `&sort=${sort} desc` : '&sort=createdAt desc'
      }`,
      method: 'GET',
      params: {},
    });

    if (response.status === 200) {
      yield put(
        regionActionCreators.getMunicipalitiesSuccess({
          municipalities: response.data.rows,
          zones: [],
        }),
      );
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncCreateMunicipalityRequest({ payload, resolve, reject }) {
  const { placeId, long, lat, deptCode, description } = payload;

  try {
    const response = yield call(restService, {
      api: `/region/municipalities`,
      method: 'POST',
      params: {
        placeId,
        long,
        lat,
        deptCode,
        description,
      },
    });

    if (response.status === 201) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncDeleteMunicipalityRequest({ payload, resolve, reject }) {
  const { id } = payload;
  try {
    const response = yield call(restService, {
      api: `/region/municipalities/${id}`,
      method: 'DELETE',
      params: {},
    });

    if (response.status === 202) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncGetZonesRequest({ payload, resolve, reject }) {
  const { offset, limit, or, filter, sort } = payload;
  try {
    const response = yield call(restService, {
      api: `/region/zones?offset=${offset || 0}&limit=${limit || 5}${or ? `&or=${or}` : ''}${filter || ''}${
        sort ? `&sort=${sort} desc` : '&sort=createdAt desc'
      }`,
      method: 'GET',
      params: {},
    });

    if (response.status === 200) {
      yield put(
        regionActionCreators.getZonesSuccess({
          zones: response.data.rows,
        }),
      );
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncGetZoneRequest({ payload, resolve, reject }) {
  const { id } = payload;
  try {
    const response = yield call(restService, {
      api: `/region/zones/${id}`,
      method: 'GET',
      params: {},
    });

    if (response.status === 200) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncCreateZoneRequest({ payload, resolve, reject }) {
  const { placeId, long, lat, deptCode, municipalityCode, description } = payload;

  try {
    const response = yield call(restService, {
      api: `/region/zones`,
      method: 'POST',
      params: {
        placeId,
        long,
        lat,
        deptCode,
        municipalityCode,
        description,
      },
    });

    if (response.status === 201) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncDeleteZoneRequest({ payload, resolve, reject }) {
  const { id } = payload;
  try {
    const response = yield call(restService, {
      api: `/region/zones/${id}`,
      method: 'DELETE',
      params: {},
    });

    if (response.status === 202) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncAssignRestaurantsRequest({ payload, resolve, reject }) {
  const { id, restaurants } = payload;

  try {
    const response = yield call(restService, {
      api: `/region/zones/${id}/restaurants`,
      method: 'PUT',
      params: restaurants,
    });

    if (response.status === 202) {
      resolve(response);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}
export function* asyncGetRestaurantsFromZoneRequest({ payload, resolve, reject }) {
  const { id } = payload;

  try {
    const response = yield call(restService, {
      api: `/region/zones/${id}/restaurants?details=true`,
      method: 'GET',
      params: {},
    });

    if (response.status === 200) {
      resolve(response.data);
    } else {
      yield put(authActionCreators.handleAuthError({ response }));
      reject({ msg: response.data.type });
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncCheckAddressRequest({ payload, resolve, reject }){

  const { long, lat } = payload; 
  try{
    const response = yield call(restService, {
      api: `/customers/checkAreaFromMap?long=${long}&lat=${lat}`,
      method: 'GET',
    })
    
    if (response.status === 200) {
      yield put(regionActionCreators.checkMyAddressInRestaurant({ 
        isRestaurant: response.data.isFound 
      }));
      resolve(response);
    } else {
      reject(response);
    }

  } catch(e){
    reject(e);
  }
}

export function* watchSetInitialValueRequest() {
  while (true) {
    const action = yield take(SET_INITIAL_REGION_VALUE);
    yield* asyncSetInitialValueRequest(action);
  }
}
export function* watchDepartmentsRequest() {
  while (true) {
    const action = yield take(GET_DEPARTMENTS_REQUEST);
    yield* asyncGetDepartmentsRequest(action);
  }
}
export function* watchCreateDepartmentRequest() {
  while (true) {
    const action = yield take(CREATE_DEPARTMENTS_REQUEST);
    yield* asyncCreateDepartmentRequest(action);
  }
}
export function* watchDeleteDepartmentRequest() {
  while (true) {
    const action = yield take(DELETE_DEPARTMENT_REQUEST);
    yield* asyncDeleteDepartmentRequest(action);
  }
}
export function* watchMunicipalitiesRequest() {
  while (true) {
    const action = yield take(GET_MUNICIPALITIES_REQUEST);
    yield* asyncGetMunicipalitiesRequest(action);
  }
}
export function* watchCreateMunicipalityRequest() {
  while (true) {
    const action = yield take(CREATE_MUNICIPALITY_REQUEST);
    yield* asyncCreateMunicipalityRequest(action);
  }
}
export function* watchDeleteMunicipalityRequest() {
  while (true) {
    const action = yield take(DELETE_MUNICIPALITY_REQUEST);
    yield* asyncDeleteMunicipalityRequest(action);
  }
}
export function* watchZonesRequest() {
  while (true) {
    const action = yield take(GET_ZONES_REQUEST);
    yield* asyncGetZonesRequest(action);
  }
}
export function* watchZoneRequest() {
  while (true) {
    const action = yield take(GET_ZONE_REQUEST);
    yield* asyncGetZoneRequest(action);
  }
}
export function* watchCreateZoneRequest() {
  while (true) {
    const action = yield take(CREATE_ZONE_REQUEST);
    yield* asyncCreateZoneRequest(action);
  }
}
export function* watchDeleteZoneRequest() {
  while (true) {
    const action = yield take(DELETE_ZONE_REQUEST);
    yield* asyncDeleteZoneRequest(action);
  }
}
export function* watchAssignRestaurantsRequest() {
  while (true) {
    const action = yield take(ASSIGN_RESTAURANTS_REQUEST);
    yield* asyncAssignRestaurantsRequest(action);
  }
}
export function* watchGetRestaurantsFromZoneRequest() {
  while (true) {
    const action = yield take(GET_RESTAURANTS_FROM_ZONE_REQUEST);
    yield* asyncGetRestaurantsFromZoneRequest(action);
  }
}

export function* watchCheckAddressRequest() {
  while (true) {
    const action = yield take(CHECK_MY_ADDRESS_IN_RESTAURANT);
    yield* asyncCheckAddressRequest(action);
  }
}

export default function*() {
  yield all([
    fork(watchSetInitialValueRequest),
    fork(watchDepartmentsRequest),
    fork(watchCreateDepartmentRequest),
    fork(watchDeleteDepartmentRequest),
    fork(watchMunicipalitiesRequest),
    fork(watchCreateMunicipalityRequest),
    fork(watchDeleteMunicipalityRequest),
    fork(watchZonesRequest),
    fork(watchZoneRequest),
    fork(watchCreateZoneRequest),
    fork(watchDeleteZoneRequest),
    fork(watchAssignRestaurantsRequest),
    fork(watchGetRestaurantsFromZoneRequest),
    fork(watchCheckAddressRequest),
  ]);
}
