import React from 'react';
import { Button } from 'antd';
import { ArrowForward } from '@material-ui/icons';
import PropTypes from 'prop-types';
import ModalDlg from '../../Basic/ModalDlg';

const ErrorModal = ({ title, subtitle, isOpenModal, onCancel, onOk }) => {
  return (
    <ModalDlg className="nit_confirm_modal" handleCancel={onCancel} isCenter visible={isOpenModal} width={634}>
      <div className="modal_header">
        <h3>{title}</h3>
        <p className="description">{subtitle}</p>
      </div>
      <div className="modal_footer">
        <div className="btn_wrapper">
          <Button className="remove_btn" onClick={onOk}>
            OK
            <ArrowForward />
          </Button>
        </div>
      </div>
    </ModalDlg>
  );
};

ErrorModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isOpenModal: PropTypes.bool,
};

ErrorModal.defaultProps = {
  isOpenModal: false,
  title: '',
  subtitle: '',
};

export default ErrorModal;
