import { GET_VERSION_BACKEND_REJECT, GET_VERSION_BACKEND_SUCCESS } from 'core/modules/version/actions';
import { initialState } from 'core/modules/initialState';

export default function version(state = initialState.version, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case GET_VERSION_BACKEND_SUCCESS: {
      return {
        ...state,
        version: payload.version,
      };
    }
    case GET_VERSION_BACKEND_REJECT: {
      return {
        ...state,
        version: payload.version,
      };
    }

    default: {
      return state;
    }
  }
}
