import { connect } from 'react-redux';
import { customerActionCreators } from 'core/modules/customer/actions';

function mapStateToProps({ customer }) {
  return {
    customer,
  };
}

const mapDispatchToProps = customerActionCreators;

export function connectCustomer(configMapStateToProps = mapStateToProps) {
  return connect(configMapStateToProps, mapDispatchToProps);
}
