// @flow

import { all, fork } from 'redux-saga/effects';
import {
  addressSaga,
  authSaga,
  catalogSaga,
  customerSaga,
  orderSaga,
  regionSaga,
  restaurantSaga,
  settingsSaga,
  versionSaga,
} from 'core/modules';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(catalogSaga),
    fork(regionSaga),
    fork(addressSaga),
    fork(orderSaga),
    fork(customerSaga),
    fork(restaurantSaga),
    fork(settingsSaga),
    fork(versionSaga),
  ]);
}
