/* eslint-disable prefer-destructuring */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button, Icon, Input, Progress, Select } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { ArrowBack, ArrowDropDown, ArrowForward, Edit } from '@material-ui/icons';
import CustomerEditModal from 'components/Main/Order/CustomerEditModal';
import TagComponent from '../../Basic/Tag/TagComponent';

const { Option } = Select;

class ClientSelect extends PureComponent {
  constructor(props) {
    super(props);
    const { customerList } = props;
    let defaultId;
    let customer;
    let customerTags = [];
    if (customerList && customerList.length !== 0) {
      customer = customerList.find(({ state }) => state === 'A' || state === 'P');
      defaultId = customer ? customer.id : null;
      customerTags = customer ? customer.tags : [];
    }
    if (defaultId && customerList.filter(c => c.id === defaultId).length !== 0) {
      customerTags = customerList.filter(c => c.id === defaultId)[0].tags || [];
    }
    this.props.onClientSelect(customer ? customer.id : '-1');
    this.state = {
      firstName: '',
      lastName: '',
      clientId: customer ? customer.id : '',
      isEditModal: false,
      currentUser: {},
      customerTags,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.customerList !== this.props.customerList && prevProps.isAddNewUser) ||
      (this.props.customerList.length > 0 &&
        prevProps.customerList.length > 0 &&
        prevProps.customerList[0].id !== this.props.customerList[0].id)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ clientId: '' });
    }

    if (prevProps.isAddNewUser && !this.props.isAddNewUser) {
      this.props.onClientSelect(this.state.clientId);
    }

    if (this.props.isSelectedOtherCustomer) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ clientId: '' });
      const { setIsSelectedOtherCustomer } = this.props;
      setIsSelectedOtherCustomer();
    }
    this.handleClientSelect(this.state.clientId);
  }

  handleClientSelect = value => {
    const { customerList } = this.props;

    if (value !== -1) {
      this.setState({ clientId: value });
    }
    let customerTags = [];
    if (customerList && customerList.length !== 0) {
      const customer = customerList.find(({ state }) => state === 'A' || state === 'P');
      customerTags = customer ? customer.tags : [];
    }
    if (value && customerList.filter(c => c.id === value).length !== 0) {
      customerTags = customerList.filter(c => c.id === value)[0].tags || [];
    }
    this.setState({ customerTags });

    this.props.onClientSelect(value);
  };

  handleShowCustomerModal = () => {
    const { customerList } = this.props;
    const { clientId } = this.state;
    let user;
    if (customerList && customerList.length !== 0 && !clientId) {
      user = customerList[0];
    } else {
      user = customerList.find(c => c.id === clientId) || {};
    }
    this.setState({ isEditModal: true, currentUser: user || {} });
  };

  handleUpdateCustomer = values => {
    const { currentUser } = this.state;
    this.props.onUpdateCustomer({ id: currentUser.id, ...values });
  };

  handleUpdateCustomerTags = (event, { id: tagId }) => {
    const { customerList } = this.props;
    const { clientId } = this.state;
    let user;
    if (customerList && customerList.length !== 0 && !clientId) {
      user = customerList[0];
    } else {
      user = customerList.find(c => c.id === clientId) || {};
    }
    this.props.onUpdateCustomerTag(user, tagId);
  };

  handleOnCreateCustomer = () => {
    this.setState({ firstName: '', lastName: '' });
    this.props.onCreateNewCustomer({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
    });
  };

  render() {
    const { customerList, isExistPhone, isNewOrder, isAddNewUser, tags } = this.props;
    const { clientId, currentUser, customerTags } = this.state;
    let defaultId;
    if (customerList && customerList.length !== 0) {
      const customer = customerList.find(({ state }) => state === 'A' || state === 'P');
      defaultId = customer ? customer.id : null;
      if (!this.state.clientId) {
        this.setState({ clientId: defaultId });
      }
    }
    return (
      <div className="client_select_wrapper">
        {isNewOrder && (
          <div className="order_progress_wrapper">
            <Progress percent={20} showInfo={false} />
          </div>
        )}
        <div className="client_list_wrapper">
          {!isAddNewUser ? (
            <h3>
              <FormattedMessage id="Customer details" />
            </h3>
          ) : (
            <div className="back_btn_wrapper">
              <div className="back_btn" onClick={() => this.props.onBack()}>
                <ArrowBack />
              </div>
              <h3>
                <FormattedMessage id="Customer details" />
              </h3>
            </div>
          )}
          {isExistPhone && customerList && !isAddNewUser && (
            <div className="client_dropdown">
              <p className="p-medium">
                <FormattedMessage id="Client" />
              </p>
              <div className="client_list_wrapper">
                <Select
                  className="client_list"
                  onSelect={this.handleClientSelect}
                  placeholder={this.props.intl.formatMessage({ id: 'Select customer' })}
                  suffixIcon={<ArrowDropDown />}
                  value={clientId || defaultId}
                >
                  <Option className="new_user_option" key={-1} value={-1}>
                    <Icon type="plus" /> <FormattedMessage id="Create new user" />
                  </Option>
                  {customerList.map(item => (
                    <Option
                      key={Math.random()}
                      style={{
                        color: item.state === 'I' ? '#DA4444' : '#000000',
                      }}
                      value={item.id}
                      // disabled={item.state === 'I'}
                    >
                      {`${item.firstName} ${item.lastName}`}
                    </Option>
                  ))}
                </Select>
                <Edit className="edit_icon" onClick={this.handleShowCustomerModal} />
              </div>
              <div className="tags_list_wrapper" key={this.state.clientId}>
                {tags
                  .filter(t => t.state === 'A')
                  .map(tag => (
                    <TagComponent
                      isTagActive={customerTags.findIndex(c => c.tag === tag.id) !== -1}
                      note={(customerTags.find(c => c.tag === tag.id) || {}).note || ''}
                      tag={{
                        ...tag,
                        ...((customerList.find(c => c.id === (clientId || defaultId)) || {}).state === 'P' &&
                        tag.id === 2
                          ? { buttonColor: '#f5a623' }
                          : {}),
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
          {(!isExistPhone || isAddNewUser) && (
            <div className="new_customer_wrapper">
              <p className="title">
                <FormattedMessage id="New client" />
              </p>
              <div className="reference_name_wrapper">
                <div className="first_name">
                  <Input
                    onChange={e => this.setState({ firstName: e.target.value })}
                    placeholder={this.props.intl.formatMessage({ id: 'First name' })}
                    value={this.state.firstName}
                  />
                </div>
                <div className="last_name">
                  <Input
                    onChange={e => this.setState({ lastName: e.target.value })}
                    placeholder={this.props.intl.formatMessage({ id: 'Last name' })}
                    value={this.state.lastName}
                  />
                </div>
              </div>
              <div className="btn_wrapper">
                <Button
                  className="create_btn"
                  disabled={!this.state.firstName || !this.state.lastName}
                  onClick={this.handleOnCreateCustomer}
                >
                  <p>
                    <FormattedMessage id="Create new customer" />
                  </p>
                  <ArrowForward />
                </Button>
              </div>
            </div>
          )}
        </div>
        <CustomerEditModal
          firstName={currentUser.firstName || ''}
          isOpenModal={this.state.isEditModal}
          lastName={currentUser.lastName || ''}
          onCancel={() => this.setState({ isEditModal: false })}
          onOk={this.handleUpdateCustomer}
        />
      </div>
    );
  }
}

ClientSelect.propTypes = {
  intl: intlShape.isRequired,
  isNewOrder: PropTypes.bool,
  customerList: PropTypes.array,
  isExistPhone: PropTypes.bool,
  isAddNewUser: PropTypes.bool,
  isSelectedOtherCustomer: PropTypes.bool,
  tags: PropTypes.array,
  onClientSelect: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onCreateNewCustomer: PropTypes.func.isRequired,
  onUpdateCustomer: PropTypes.func.isRequired,
  onUpdateCustomerTag: PropTypes.func.isRequired,
  setIsSelectedOtherCustomer: PropTypes.func.isRequired,
};

ClientSelect.defaultProps = {
  isNewOrder: true,
  customerList: [],
  isExistPhone: true,
  isAddNewUser: false,
  isSelectedOtherCustomer: false,
  tags: [],
};

export default compose(injectIntl, withRouter)(ClientSelect);
