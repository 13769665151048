import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { compose } from 'recompose';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Apps, DateRange } from '@material-ui/icons';
import { DatePicker, Tooltip } from 'antd';
import { getCurrency } from 'utilities/common';

function OrdersTable({ intl, orderData, onSelectOrder, onDateChange }) {
  const orderStates = {
    RV: intl.formatMessage({ id: 'RV' }),
    IP: intl.formatMessage({ id: 'IP' }),
    AS: intl.formatMessage({ id: 'AS' }),
    OW: intl.formatMessage({ id: 'OW' }),
    AD: intl.formatMessage({ id: 'AD' }),
    CL: intl.formatMessage({ id: 'CL' }),
    RS: intl.formatMessage({ id: 'RS' }),
    DD: intl.formatMessage({ id: 'DD' }),
    CD: intl.formatMessage({ id: 'CD' }),
    ERR: intl.formatMessage({ id: 'ERR' }),
  };
  if (!orderData) {
    return null;
  }

  return (
    <div className="orders_table_layout">
      <div className="order_filter_wrapper">
        <p className="p-medium">
          <FormattedMessage id="Last orders placed" />
        </p>
        <DatePicker className="start_date" onChange={onDateChange} placeholder="Fecha" suffixIcon={<DateRange />} />
      </div>
      <div className="table_header">
        <div className="order">
          <Apps /> # <FormattedMessage id="Order" />
        </div>
        <div className="bought">
          <FormattedMessage id="Bought" />
        </div>
        <div className="delivery_address">
          <FormattedMessage id="Delivery address" />
        </div>
        <div className="date_time">
          <FormattedMessage id="Date and Time" />
        </div>
        <div className="total">
          <FormattedMessage id="Total" />
        </div>
      </div>
      <div className="table_content scrollbar">
        {orderData &&
          orderData.map(item => (
            <Tooltip
              placement="top"
              title={
                <div className="order_time_content">
                  <p className="status" style={{ textAlign: 'left', marginTop: 22 }}>
                    Pedido #{item.order || item.id}:{' '}
                    <span className={`order_text_${item.state}`}>{orderStates[item.state]}</span>
                  </p>
                  <p style={{ textAlign: 'left', fontSize: 11, color: '#B8B8B8' }}>
                    Colocado:{' '}
                    {moment(item.createdAt)
                      .tz('America/Guatemala')
                      .format('LL')}{' '}
                    -{' '}
                    {moment(item.createdAt)
                      .tz('America/Guatemala')
                      .format('HH:mm')}
                  </p>
                  {item.dueTo && (
                    <p style={{ textAlign: 'left', fontSize: 11, color: '#B8B8B8' }}>
                      Debe entregarse:{' '}
                      {moment(item.dueTo)
                        .tz('America/Guatemala')
                        .format('LL')}{' '}
                      -{' '}
                      {moment(item.dueTo)
                        .tz('America/Guatemala')
                        .format('HH:mm')}
                    </p>
                  )}
                  {item.productionAt && (
                    <p
                      style={{
                        textAlign: 'left',
                        fontSize: 11,
                        color:
                          orderStates[item.state] !== 'DD' && moment().diff(item.dueTo, 'seconds') >= 0
                            ? '#ff2527'
                            : '#FFFFFF',
                        marginBottom: 32,
                      }}
                    >
                      Tiempo transcurrido desde recibido:{' '}
                      <b>{moment(item.deliveredAt || new Date()).diff(item.productionAt, 'minutes')} minutos</b>
                    </p>
                  )}
                </div>
              }
            >
              <div className={`table_item order_${item.state}`} key={Math.random()} onClick={() => onSelectOrder(item)}>
                <div className="order">
                  <p className="code">
                    {item.order && <span className="order_number">{item.order || ''}</span>}
                    <span className="reference_id">(REF - {item.id})</span>
                  </p>
                  <p className="address">
                    {item.customer ? `${item.customer.firstName} ${item.customer.lastName}` : ''}
                  </p>
                  <p className="phone">{item.customer ? item.customer.phone : '-'}</p>
                  <p className="email">{item.customer ? item.customer.email : '-'}</p>
                </div>
                <div className="bought">
                  <p className="article">
                    {item.contents ? item.contents.length : 0} <FormattedMessage id="articles" />
                  </p>
                  <div className="device">{item.channel || ''}</div>
                </div>
                <div className="delivery_address">
                  <p className="address1">
                    {`${item.deliveryAddress.address || ''}, ${item.deliveryAddress.description || ''}`}
                  </p>
                  {item.restaurant &&
                    item.restaurant.zones &&
                    item.restaurant.zones.map((z, index) => (
                      <p className="address2" key={index}>
                        {z.description || ''}
                      </p>
                    ))}
                  <p className="product">{item.restaurant ? item.restaurant.description : ''}</p>
                </div>
                <div className="date_time">
                  <p className="time">
                    {item.dueTo
                      ? moment(item.dueTo)
                          .tz('America/Guatemala')
                          .format('LL')
                      : ''}
                  </p>
                  <p className="note">
                    {item.dueTo
                      ? `Debe ser entregado a las ${moment(item.dueTo)
                          .tz('America/Guatemala')
                          .format('HH:mm')} horas`
                      : ''}
                  </p>
                </div>
                <div className="total">
                  <p className="amount">
                    {getCurrency().symbol}
                    {parseFloat(item.paymentAmount / 100).toFixed(2) || ''}
                  </p>
                  <p className="note">
                    {item.paymentMethod.type === 'C' ? 'CC' : item.paymentMethod.type === 'VL' ? 'VL' : 'COD'}
                  </p>
                </div>
              </div>
            </Tooltip>
          ))}
      </div>
    </div>
  );
}

OrdersTable.propTypes = {
  intl: intlShape.isRequired,
  orderData: PropTypes.array,
  onSelectOrder: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
};

OrdersTable.defaultProps = {
  orderData: [],
};

export default compose(injectIntl)(OrdersTable);
