import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { catalogActionCreators, connectCatalog, connectOrder } from 'core';
import placeHolderImg from 'assets/img/placeholder.svg';
import {
  getImageLink
} from 'utilities/common';

class FoodMenu extends PureComponent {
  render() {
    const {
      selectedCatalogId,
      selectedSubCatalogId,
      subCatalog,
      superCataloges,
      breakfast,
      dinner,
      dayPart,
    } = this.props;

    return (
      <div className="food_menu_layout">
        <p className="title">{this.props.title}</p>
        <div className="food_day_part_wrapper">
          {breakfast.length !== 0 && (
            <div
              className={`${dayPart === 'breakfasts' ? 'active' : ''}`}
              onClick={() => {
                this.props.onSelectDayPart('breakfasts');
              }}
            >
              <FormattedMessage id="breakfasts" />
            </div>
          )}
          {dinner.length !== 0 && (
            <div
              className={`${dayPart === 'dinner' ? 'active' : ''}`}
              onClick={() => {
                this.props.onSelectDayPart('dinner');
              }}
            >
              <FormattedMessage id="lunches and dinners" />
            </div>
          )}
        </div>
        <div className="food_menu_wrapper">
          {superCataloges &&
            superCataloges.map(catalog => (
              <div
                className={`menu_item ${selectedCatalogId === catalog.id && 'active'}`}
                key={Math.random()}
                onClick={() => this.props.onChooseCatalog(catalog.id)}
              >
                <div>
                  <img
                    alt="food"
                    className="fade-in"
                    src={
                      catalog.customizedImage
                        ? getImageLink(selectedCatalogId === catalog.id ? catalog.customizedSelectedImage : catalog.customizedImage)
                        : placeHolderImg
                    }
                  />
                </div>
                <p dangerouslySetInnerHTML={{ __html: catalog.name }} />
              </div>
            ))}
        </div>
        <div className="food_submenu_wrapper scrollbar">
          {subCatalog &&
            subCatalog.map(sub => (
              <div
                className={`sub_menu_item fade-in ${selectedSubCatalogId === sub.id ? 'sub_active' : ''}`}
                key={Math.random()}
                onClick={() => {
                  this.props.onChooseSubCatalog(sub.id);
                }}
              >
                <p dangerouslySetInnerHTML={{ __html: sub.name }} />
              </div>
            ))}
        </div>
      </div>
    );
  }
}

FoodMenu.propTypes = {
  title: PropTypes.string,
  superCataloges: PropTypes.array,
  subCatalog: PropTypes.array,
  selectedCatalogId: PropTypes.number,
  selectedSubCatalogId: PropTypes.number,
  availableCatalogs: PropTypes.array,
  breakfast: PropTypes.array,
  dinner: PropTypes.array,
  dayPart: PropTypes.string,
  onChooseCatalog: PropTypes.func.isRequired,
  onChooseSubCatalog: PropTypes.func.isRequired,
  onSelectDayPart: PropTypes.func,
};

FoodMenu.defaultProps = {
  superCataloges: [],
  subCatalog: [],
  selectedCatalogId: undefined,
  selectedSubCatalogId: undefined,
  title: '',
  availableCatalogs: [],
  breakfast: [],
  dinner: [],
  dayPart: 'breakfasts',
  onSelectDayPart: () => {},
};

const mapStateToProps = ({ order }) => ({
  dueTo: order.dueTo,
});

const mapDispatchToProps = dispatch => {
  const { getScheduleSuperCatalogs } = catalogActionCreators;

  return bindActionCreators(
    {
      getScheduleSuperCatalogs,
    },
    dispatch,
  );
};

export default compose(
  connectCatalog(mapStateToProps, mapDispatchToProps),
  connectOrder(mapStateToProps, mapDispatchToProps),
)(FoodMenu);
