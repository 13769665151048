import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Button, Form, Input } from 'antd';
import { injectIntl, intlShape } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';
import { ArrowForward } from '@material-ui/icons';

function CustomerEditModal({ form, intl, firstName, lastName, isOpenModal, onCancel, onOk }) {
  const { getFieldDecorator } = form;

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onCancel();
        onOk(values);
        form.resetFields();
      }
    });
  };

  return (
    <ModalDlg className="customer_edit_modal" handleCancel={onCancel} isCenter visible={isOpenModal} width={634}>
      <div className="modal_header">
        <h3>Editar detalles del cliente seleccionado</h3>
      </div>
      <Form className="login-form" onSubmit={handleSubmit}>
        <Form.Item>
          {getFieldDecorator('firstName', {
            rules: [{ required: true, message: '¡campo requerido!' }],
            initialValue: firstName,
          })(<Input placeholder={intl.formatMessage({ id: 'First name' })} />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('lastName', {
            rules: [{ required: true, message: '¡campo requerido!' }],
            initialValue: lastName,
          })(<Input placeholder={intl.formatMessage({ id: 'Last name' })} />)}
        </Form.Item>
        <div className="modal_footer">
          <Button htmlType="submit">
            Actualizar
            <ArrowForward />
          </Button>
        </div>
      </Form>
    </ModalDlg>
  );
}

CustomerEditModal.propTypes = {
  form: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isOpenModal: PropTypes.bool,
  intl: intlShape.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

CustomerEditModal.defaultProps = {
  form: {},
  isOpenModal: false,
  firstName: '',
  lastName: '',
};

export default compose(injectIntl, Form.create({ name: 'customer-edit-form' }))(CustomerEditModal);
