import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Divider, Form, Icon, Input, Progress, Spin } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { connectCatalog, connectCustomer, connectOrder, customerActionCreators, orderActionCreators } from 'core';
import { promisify } from 'utilities';
import toast from 'components/Basic/Toast';
import NitConfirmModal from 'components/Main/Order/NitConfirmModal';
import { getCurrency } from 'utilities/common';
import passportImg from 'assets/img/passport.svg';
import nit from 'assets/img/nit.svg';
import dpiImg from 'assets/img/dpi.svg';

const antIcon = <Icon spin style={{ fontSize: 24 }} type="loading" />;

class CheckoutBilling extends PureComponent {
  constructor(props) {
    super(props);
    this.nitMaxSum = 2500;
    this.state = {
      nitNumber: '',
      nitInfo: {},
      isLoading: false,
      isNitModal: false,
      totalCost: 0,
      taxMethod: 'nit',
      dpi: '',
      dpiFullName: '',
      passport: '',
      passportFullName: '',
    };
  }

  componentDidMount() {
    this.setState({ nitInfo: {} });
  }

  handleChange = e => {
    this.setState({ nitNumber: e.target.value });
  };

  handleCheckNit = () => {
    const { nit_number } = this.props.form.getFieldsValue(['nit_number']);
    promisify(this.props.checkValidateNit, { nit: !nit_number || nit_number.length === 0 ? 'CF' : nit_number.trim() })
      .then(res => {
        if (res.data && res.data.validate !== 'Invalid') {
          this.setState({ nitInfo: res.data });
        } else {
          toast.error({
            title: this.props.intl.formatMessage({
              id:
                "Your NIT number is invalid, please try again or we will use 'Final Consumer' for your billing details.",
            }),
          });
        }
      })
      .catch(() => {
        this.setState({ nitInfo: {} });
        toast.error({
          title: this.props.intl.formatMessage({
            id:
              "Your NIT number is invalid, please try again or we will use 'Final Consumer' for your billing details.",
          }),
        });
      });
  };

  validateDPI = value => {
    const reg = new RegExp(/^[0-9a-zA-Z]{1,14}$/);
    return reg.test(value);
  };

  handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    const country = process.env.REACT_APP_COUNTRY || 'gt';
    this.setState({ isNitModal: false });
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (country === 'gt') {
          this.setState({ isLoading: true });
          if (this.state.taxMethod === 'dpi' || this.state.taxMethod === 'passport') {
            this.setState({ isLoading: false });
            this.props.onContinue({ ...values, taxMethod: this.state.taxMethod });
          }
          if (this.state.taxMethod === 'nit') {
            promisify(this.props.checkValidateNit, {
              nit: !values.nit_number || values.nit_number.length === 0 ? 'CF' : values.nit_number,
            })
              .then(res => {
                this.setState({ isLoading: false });
                promisify(this.props.setInitialOrder, { nitInfo: res.data });
                this.props.onContinue({ ...values, taxMethod: this.state.taxMethod });
              })
              .catch(error => {
                console.log(error);
                this.setState({ isLoading: false });
                toast.error({
                  title: this.props.intl.formatMessage({
                    id:
                      "Your NIT number is invalid, please try again or we will use 'Final Consumer' for your billing details.",
                  }),
                });
              });
          }
        } else {
          this.props.onContinue(values);
        }
      }
    });
  };

  handleShowNitConfirmModal = () => {
    const { nitInfo, totalCost, taxMethod, dpi, passportFullName, dpiFullName, passport } = this.state;
    if (taxMethod === 'nit') {
      if (!nitInfo.name && totalCost < this.nitMaxSum) {
        this.setState({ isNitModal: true });
      } else if (
        (nitInfo.validate !== 'Valid' && totalCost >= this.nitMaxSum) ||
        (nitInfo.name === 'Consumidor Final' && totalCost >= this.nitMaxSum)
      ) {
        toast.error({
          title: this.props.intl.formatMessage({
            id:
              'Due to the new approved laws, if your order is equal or greater to Q2,500.00 you must provide a valid NIT Number. Please provide a NIT number to continue placing your order. Thank you.',
          }),
        });
      } else {
        this.handleSubmit();
      }
    }
    if (taxMethod === 'dpi') {
      if (dpi === '' || !this.validateDPI(dpi)) {
        return toast.error({ title: this.props.intl.formatMessage({ id: 'PASSPORT_IS_NOT_VALID' }) });
      }
      if (dpiFullName === '') {
        return toast.error({ title: this.props.intl.formatMessage({ id: 'ValidateDPIError' }) });
      }
      this.handleSubmit();
    }

    if (taxMethod === 'passport') {
      if (passport === '' || !this.validateDPI(passport)) {
        return toast.error({ title: this.props.intl.formatMessage({ id: 'PASSPORT_IS_NOT_VALID' }) });
      }
      if (passportFullName === '') {
        return toast.error({ title: this.props.intl.formatMessage({ id: 'ValidatePassportError' }) });
      }
      this.handleSubmit();
    }
  };

  handleChangeDPI = e => {
    this.setState({ dpi: e.target.value });
  };

  handleChangePassport = e => {
    this.setState({ passport: e.target.value });
  };

  handleChangeDPIFullName = e => {
    this.setState({ dpiFullName: e.target.value });
  };

  handleChangePassportFullName = e => {
    this.setState({ passportFullName: e.target.value });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { nitNumber, nitInfo, isNitModal, passportFullName, dpi, dpiFullName, passport } = this.state;
    const { cart } = this.props;
    let totalAmount = 0;
    if (cart && cart.length !== 0) {
      cart.forEach(c => {
        totalAmount += (c.amount * c.totalCount) / 100;
        this.setState({ totalCost: totalAmount });
      });
    }
    const country = process.env.REACT_APP_COUNTRY || 'gt';

    return (
      <Spin indicator={antIcon} spinning={this.state.isLoading}>
        <div className="checkout_billing_layout step-fade-in">
          <div className="checkout_billing_wrapper">
            <div className="order_progress_wrapper">
              <Progress percent={60} showInfo={false} />
            </div>
            <div className="back_btn_wrapper">
              <div className="back_btn" onClick={this.props.onBack}>
                <ArrowBack />
              </div>
              <h3>
                <FormattedMessage id="Order details" />
              </h3>
            </div>
            <Form>
              <Divider orientation="left">
                <div>
                  <FormattedMessage id="Billing information" />
                </div>
              </Divider>
              <div className="checkout_billing_detail scrollbar">
                {country === 'gt' ? (
                  <Form.Item>
                    <div className="prefer_billing_type">
                      <div
                        className={this.state.taxMethod === 'nit' ? 'active' : ''}
                        onClick={() => this.setState({ taxMethod: 'nit' })}
                      >
                        <img alt="nit" src={nit} />
                        <p>
                          <FormattedMessage id="NIT Number" />
                        </p>
                      </div>
                      <div
                        className={this.state.taxMethod === 'dpi' ? 'active' : ''}
                        onClick={() => this.setState({ taxMethod: 'dpi' })}
                      >
                        <img alt="dpi" src={dpiImg} />
                        <p>
                          <FormattedMessage id="DPI" />
                        </p>
                      </div>
                      <div
                        className={this.state.taxMethod === 'passport' ? 'active' : ''}
                        onClick={() => this.setState({ taxMethod: 'passport' })}
                      >
                        <img alt="passport" src={passportImg} />
                        <p>
                          <FormattedMessage id="Passport" />
                        </p>
                      </div>
                    </div>
                  </Form.Item>
                ) : (
                  <></>
                )}
                {this.state.taxMethod === 'passport' ? (
                  <>
                    <div className="passport">
                      <Form.Item>
                        <div>
                          <p className="description">
                            <FormattedMessage id="BILLING_FULL_NAME_DESCRIPTION" />
                          </p>
                          <div className="passport_wrapper">
                            {getFieldDecorator('passport_full_name', {
                              rules: [],
                            })(
                              <Input
                                maxLength={100}
                                onChange={this.handleChangePassportFullName}
                                placeholder={this.props.intl.formatMessage({
                                  id: 'BILLING_FULL_NAME_PLACEHOLDER',
                                })}
                                value={passportFullName}
                              />,
                            )}
                          </div>
                        </div>
                      </Form.Item>
                      <Form.Item>
                        <div>
                          <p className="description">
                            <FormattedMessage id="BILLING_PASSPORT_DESCRIPTION" />
                          </p>
                          <div className="dpi_wrapper">
                            {getFieldDecorator('passport', {
                              rules: [],
                            })(
                              <Input
                                maxLength={14}
                                onChange={this.handleChangePassport}
                                placeholder={this.props.intl.formatMessage({
                                  id: 'BILLING_PASSPORT_PLACEHOLDER',
                                })}
                                value={passport}
                              />,
                            )}
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {this.state.taxMethod === 'dpi' ? (
                  <>
                    <div className="passport">
                      <Form.Item>
                        <div>
                          <p className="description">
                            <FormattedMessage id="BILLING_FULL_NAME_DESCRIPTION" />
                          </p>
                          <div className="passport_wrapper">
                            {getFieldDecorator('dpi_full_name', {
                              rules: [],
                            })(
                              <Input
                                maxLength={100}
                                onChange={this.handleChangeDPIFullName}
                                placeholder={this.props.intl.formatMessage({
                                  id: 'BILLING_FULL_NAME_PLACEHOLDER',
                                })}
                                value={dpiFullName}
                              />,
                            )}
                          </div>
                        </div>
                      </Form.Item>
                      <Form.Item>
                        <div>
                          <p className="description">
                            <FormattedMessage id="BILLING_DPI_DESCRIPTION" />
                          </p>
                          <div className="dpi_wrapper">
                            {getFieldDecorator('dpi', {
                              rules: [],
                            })(
                              <Input
                                maxLength={14}
                                onChange={this.handleChangeDPI}
                                placeholder={this.props.intl.formatMessage({
                                  id: 'BILLING_DPI_PLACEHOLDER',
                                })}
                                value={dpi}
                              />,
                            )}
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {this.state.taxMethod === 'nit' ? (
                  <>
                    {country === 'gt' && (
                      <Form.Item>
                        <p className="p-medium nit_number">
                          <FormattedMessage id="NIT number" />
                        </p>
                        <div className="nit_number_wrapper">
                          {getFieldDecorator('nit_number', {
                            rules: [],
                          })(
                            <Input
                              onChange={this.handleChange}
                              placeholder={this.props.intl.formatMessage({ id: 'Enter the NIT number' })}
                              value={nitNumber}
                            />,
                          )}
                          <Button onClick={this.handleCheckNit}>
                            {this.props.intl.formatMessage({ id: 'Validate' })}
                          </Button>
                        </div>
                      </Form.Item>
                    )}
                    {country === 'gt' && nitInfo.name && (
                      <div className="bill_name">
                        <p className="title">
                          <FormattedMessage id="Billing name" />
                        </p>
                        <p className="description">
                          {nitInfo.name || this.props.intl.formatMessage({ id: 'Final consumer' })}
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {/* <div className="bill_address">
                  <p className="title">
                    <FormattedMessage id="Billing Address" />
                  </p>
                  <p className="description">
                    <FormattedMessage id="city" />
                  </p>
                </div> */}
                {country !== 'gt' && country !== 'ni' && country !== 'ni' && (
                  <Form.Item>
                    <div className="billing_name_wraper">
                      {country === 'hn' && (
                        <div className="rnt_number">
                          <p>RTN</p>
                          <div className="nit_number_wrapper">
                            {getFieldDecorator('rnt_number', {
                              rules: [],
                            })(<Input placeholder={this.props.intl.formatMessage({ id: 'Enter your NIT number' })} />)}
                          </div>
                        </div>
                      )}
                      <div>
                        <div className="billing_name">
                          <p className="bill_title">
                            <FormattedMessage id="Billing name" />
                          </p>
                        </div>
                        {getFieldDecorator('billing_name', {
                          rules: [],
                        })(<Input placeholder="Nombre de facturación" />)}
                      </div>
                    </div>
                  </Form.Item>
                )}
              </div>
              <div className="total_wrapper">
                <p className="p-large">
                  <FormattedMessage id="Total" />: {getCurrency().symbol}
                  {parseFloat(totalAmount).toFixed(2)}
                </p>
                <Button onClick={this.handleShowNitConfirmModal}>
                  <div>
                    <p>
                      <FormattedMessage id="Continue" />
                    </p>
                  </div>
                  <ArrowForward />
                </Button>
              </div>
              <NitConfirmModal
                isOpenModal={isNitModal}
                onCancel={() => this.setState({ isNitModal: false })}
                onOk={this.handleSubmit}
                subtitle="Has ingresado que el usuario no ha proorcionado o no necesita detalles de facturación, su factura se generará como “Consumidor Final”."
                title="NIT sin detalles"
              />
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}

CheckoutBilling.propTypes = {
  form: PropTypes.object,
  cart: PropTypes.array,
  intl: intlShape.isRequired,
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  setInitialOrder: PropTypes.func.isRequired,
  checkValidateNit: PropTypes.func.isRequired,
};

CheckoutBilling.defaultProps = {
  cart: [],
  form: {},
};

const mapStateToProps = ({ catalog }) => ({
  cart: catalog.cart,
});

const mapDispatchToProps = dispatch => {
  const { checkValidateNit } = customerActionCreators;
  const { setInitialOrder } = orderActionCreators;
  return bindActionCreators(
    {
      setInitialOrder,
      checkValidateNit,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  Form.create({ name: 'billing_form' }),
  connectCatalog(mapStateToProps, undefined),
  connectCustomer(undefined, mapDispatchToProps),
  connectOrder(undefined, mapDispatchToProps),
  withRouter,
)(CheckoutBilling);
