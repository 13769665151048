import { connect } from 'react-redux';
import { addressActionCreators } from 'core/modules/address/actions';

function mapStateToProps({ myAddress }) {
  return {
    myAddress,
  };
}

const mapDispatchToProps = addressActionCreators;

export function connectAddress(configMapStateToProps = mapStateToProps) {
  return connect(configMapStateToProps, mapDispatchToProps);
}
