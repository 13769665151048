import { createAction } from 'redux-actions';
import { createPromiseAction } from 'core/modules/utils';

/**
 * Action Types
 */
export const CREATE_CUSTOMER_REQUEST = '@customer/CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = '@customer/CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = '@customer/CREATE_CUSTOMER_FAILURE';
export const UPDATE_CUSTOMER_REQUEST = '@customer/UPDATE_CUSTOMER_REQUEST';
export const GET_CUSTOMER_LIST_REQUEST = '@customer/GET_CUSTOMER_LIST_REQUEST';
export const GET_CUSTOMER_LIST_SUCCESS = '@customer/GET_CUSTOMER_LIST_SUCCESS';
export const GET_CUSTOMER_LIST_FAILURE = '@customer/GET_CUSTOMER_LIST_FAILURE';
export const GET_CUSTOMER_ADDRESS_REQUEST = '@customer/GET_CUSTOMER_ADDRESS_REQUEST';
export const GET_CUSTOMER_ADDRESS_SUCCESS = '@customer/GET_CUSTOMER_ADDRESS_SUCCESS';
export const GET_CUSTOMER_ADDRESS_FAILURE = '@customer/GET_CUSTOMER_ADDRESS_FAILURE';
export const GET_BEST_RESTAURANT_REQUEST = '@customer/GET_BEST_RESTAURANT_REQUEST';
export const CHECK_VALIDATE_NIT_REQUEST = '@customer/CHECK_VALIDATE_NIT_REQUEST';
export const GET_PAYMENT_METHODS_REQUEST = '@customer/GET_PAYMENT_METHODS_REQUEST';
export const DELETE_PAYMENT_METHOD_REQUEST = '@customer/DELETE_PAYMENT_METHOD_REQUEST';
export const CHECK_VALIDATE_GIFT_REQUEST = '@customer/CHECK_VALIDATE_GIFT_REQUEST';
export const GET_CUSTOMER_TAGS_REQUEST = '@customer/GET_CUSTOMER_TAGS_REQUEST';
export const CREATE_CUSTOMER_CALL_REQUEST = '@customer/CREATE_CUSTOMER_CALL_REQUEST';
export const CREATE_CUSTOMER_TAGS_REQUEST = '@customer/CREATE_CUSTOMER_TAGS_REQUEST';
export const GENERATE_VISA_LINK_REQUEST = '@customer/GENERATE_VISA_LINK_REQUEST';
export const DELETE_CUSTOMER_TAGS_REQUEST = '@customer/DELETE_CUSTOMER_TAGS_REQUEST';
export const UPDATE_CUSTOMER_TAGS_REQUEST = '@customer/UPDATE_CUSTOMER_TAGS_REQUEST';
export const GET_CUSTOMER_LIST_WITH_UPDATE_TAGS_REQUEST = '@customer/GET_CUSTOMER_LIST_WITH_UPDATE_TAGS_REQUEST';
export const GET_CUSTOMER_LIST_WITH_UPDATE_TAGS_SUCCESS = '@customer/GET_CUSTOMER_LIST_WITH_UPDATE_TAGS_SUCCESS';
export const CUSTOMER_VISA_LINK_CHECK_REQUEST = '@customer/CUSTOMER_VISA_LINK_CHECK_REQUEST';

/**
 * Action Creators
 */
export const customerActionCreators = {
  createCustomer: createPromiseAction(CREATE_CUSTOMER_REQUEST),
  createCustomerSuccess: createPromiseAction(CREATE_CUSTOMER_SUCCESS),
  createCustomerFailure: createPromiseAction(CREATE_CUSTOMER_FAILURE),
  updateCustomer: createPromiseAction(UPDATE_CUSTOMER_REQUEST),
  getCustomerList: createPromiseAction(GET_CUSTOMER_LIST_REQUEST),
  getCustomerListSuccess: createAction(GET_CUSTOMER_LIST_SUCCESS),
  getCustomerListFailure: createAction(GET_CUSTOMER_LIST_FAILURE),
  getCustomerAddress: createPromiseAction(GET_CUSTOMER_ADDRESS_REQUEST),
  getCustomerAddressSuccess: createAction(GET_CUSTOMER_ADDRESS_SUCCESS),
  getCustomerAddressFailure: createAction(GET_CUSTOMER_ADDRESS_FAILURE),
  getBestRestaurants: createPromiseAction(GET_BEST_RESTAURANT_REQUEST),
  checkValidateNit: createPromiseAction(CHECK_VALIDATE_NIT_REQUEST),
  getPaymentMethods: createPromiseAction(GET_PAYMENT_METHODS_REQUEST),
  deletePaymentMethod: createPromiseAction(DELETE_PAYMENT_METHOD_REQUEST),
  checkValidateGiftCard: createPromiseAction(CHECK_VALIDATE_GIFT_REQUEST),
  generateVisaLink: createPromiseAction(GENERATE_VISA_LINK_REQUEST),
  getCustomerTags: createPromiseAction(GET_CUSTOMER_TAGS_REQUEST),
  createCustomerTags: createPromiseAction(CREATE_CUSTOMER_TAGS_REQUEST),
  createCustomerCall: createPromiseAction(CREATE_CUSTOMER_CALL_REQUEST),
  deleteCustomerTags: createPromiseAction(DELETE_CUSTOMER_TAGS_REQUEST),
  updateCustomerTags: createPromiseAction(UPDATE_CUSTOMER_TAGS_REQUEST),
  getCustomerListWithUpdateTags: createPromiseAction(GET_CUSTOMER_LIST_WITH_UPDATE_TAGS_REQUEST),
  getCustomerListWithUpdateTagsSuccess: createAction(GET_CUSTOMER_LIST_WITH_UPDATE_TAGS_SUCCESS),
  customerVisaLinkCheck: createPromiseAction(CUSTOMER_VISA_LINK_CHECK_REQUEST),
};
